import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Flex } from '@chakra-ui/react';
// import { SidePanel } from './sidepanel/SidePanel';
import { Stage } from './stage/Stage';

const VideoCreator = observer(() => (
  <Box
    width="100vw"
    height="100vh"
    overflow="hidden"
    userSelect="none"
    display="flex"
    flexDirection="column"
  >
    <Flex flex="1" display="flex">
      <Stage />
      {/* <SidePanel /> */}
    </Flex>
    {/* <Timeline /> */}
  </Box>
));

export default VideoCreator;
