import axios from 'axios';
import { getCollectionFirebase } from '../Api/firebaseApi';
import _axios from '../Api/AxiosBarrier';

export const getAnalytic = async (globalState) => {
  console.log(globalState, 'ini globalState');
  const conditions = [
    {
      field: 'users',
      operator: 'array-contains',
      value: globalState.uid,
    },
    {
      field: 'projectId',
      operator: '==',
      value: globalState.currentProject,
    },
    {
      field: 'type',
      operator: '==',
      value: 'social-media',
    },
  ];
  try {
    const socialMediaKey = await getCollectionFirebase('files', conditions);
    console.log(socialMediaKey, 'ini social media key');

    if (socialMediaKey.length > 0) {
      globalState.setSocialData(socialMediaKey[0]);
      globalState.setSocialKey(socialMediaKey[0]?.ayrshare_account?.profileKey);
      await new Promise((resolve) => setTimeout(resolve, 100));

      const profileKey = socialMediaKey[0]?.ayrshare_account?.profileKey;
      const platforms = socialMediaKey[0]?.activeSocialAccounts;

      console.log(profileKey, 'ini profilekey');
      console.log(platforms, 'ini platforms');

      _axios
        .post('ayrshare-analyticsSocial', {
          platforms,
          profileKey,
        })
        .then((res) => {
          console.log(res, 'ini res');
          globalState.setAnalyticsData(res);

          if (res?.data?.status !== null) {
            const obj = res?.data;
            const { ...analyticsData } = obj;
            const filteredData = {};
            for (const platform in analyticsData) {
              if (obj[platform]?.status === 'error') {
                let description = obj[platform]?.message;

                if (platform === 'linkedin') {
                  description = 'Your LinkedIn is not a company profile';
                }
                // console.log(description);
              } else {
                filteredData[platform] = obj[platform];
              }
            }
            console.log(analyticsData, 'ini analytics data');

            // globalState.setDataAnalytics(filteredData);
          } else {
            console.log(res.data[platforms]?.message);
          }
        })
        .catch((error) => {
          console.error('Error fetching analytics data:', error);
        });
    } else {
      globalState?.setAnalyticsData({});
    }
  } catch (error) {
    console.error('Error fetching social media key:', error);
    // return error;
  }
};
