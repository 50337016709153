/* eslint-disable no-prototype-builtins */
import { AspectRatio, Box, Center, Divider, Grid, Heading, HStack, Image, Select, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../Buttons/DynamicButton';

function StoriesTab() {
  const globalState = useUserStore();

  const [data, setData] = useState('');
  const [dataPost, setDataPost] = useState([]);
  const mediaTypeRef = useRef('');
  const availableMediaTypesRef = useRef([]);

  const getDataPost = async () => {
    globalState.setIsLoading(true);
    const data = {
      profileKey: globalState?.socialKey,
      query: `/${globalState?.socialActive?.platform}`,
    };
    try {
      const res = await _axios.post('/ayrshare-getAllPosts', data);
      if (res.data.status === 'success') {
        setData(res.data);
        if (res.data.posts.length > 0) {
          let dataFix = [];
          const dataPosting = res.data.posts;
          const platformFilters = {
            instagram: (x) => x.mediaProductType === mediaTypeRef.current,
            tiktok: (x) => x.mediaType === mediaTypeRef.current,
            twitter: (x) => x.hasOwnProperty(mediaTypeRef.current),
            facebook: (x) => x.hasOwnProperty(mediaTypeRef.current),
            linkedin: (x) => x.mediaType === mediaTypeRef.current,
            youtube: (x) => x.mediaType === mediaTypeRef.current,
            // Tambahkan platform lain dengan aturan filter yang sesuai jika diperlukan
            // misalnya: twitter: (x) => x.someProperty === someValue
          };
          const activePlatform = globalState?.socialActive?.platform.toLowerCase();

          const filterFunction = platformFilters[activePlatform];
          if (filterFunction) {
            dataFix = dataPosting.filter(filterFunction);
          } else {
            // Jika platform tidak memiliki filter khusus, tampilkan semua data
            dataFix = [...dataPosting];
          }
          setDataPost(dataFix);
        }
      }
    } catch (error) {
      console.log(error, 'ini error');
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const getDataAvailableFilter = () => {
    if (globalState?.socialActive?.platform === 'instagram') {
      availableMediaTypesRef.current = ['STORY'];
      availableMediaTypesRef.current = ['REELS'];
    }
  };

  useEffect(() => {
    if (availableMediaTypesRef.current.length > 0) {
      mediaTypeRef.current = availableMediaTypesRef.current[0]; // Mengat
    }

    getDataAvailableFilter();
    getDataPost(); // Panggil getDataPost setelah perubahan platform atau mediaType
  }, [globalState.socialKey, globalState?.socialActive?.platform]);

  return (
    <Stack>
      <HStack>
        <Text
          fontSize={'xl'}
          fontWeight='bold'
          color={'gray.600'}
          textTransform={'capitalize'}>
          Stories {globalState?.socialActive?.platform}
        </Text>
        <Spacer />
        <Box>
          <Select
            value={mediaTypeRef.current}
            onChange={(e) => {
              mediaTypeRef.current = e?.target.value;
              getDataPost(); // Panggil getDataPost setiap kali mediaType berubah
            }}
            placeholder='Select option'>
            {availableMediaTypesRef.current.map((type) => (
              <option
                key={type}
                value={type}>
                {type}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>

      {dataPost?.length > 0 ? (
        <SimpleGrid
          columns={[2, 3, 4]}
          gap={3}>
          {dataPost.map((x, index) => {
            return (
              <Stack
                key={index}
                borderRadius='lg'
                shadow='md'
                borderTopWidth={5}
                borderColor='blue.500'
                p={5}>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  gap={5}>
                  {x.mediaType === 'VIDEO' ? (
                    <AspectRatio
                      maxW='auto'
                      ratio={9 / 16}>
                      <iframe
                        title='halo'
                        src={x.mediaUrl}
                        allowFullScreen
                      />
                    </AspectRatio>
                  ) : (
                    <AspectRatio
                      maxW='auto'
                      ratio={1}>
                      <Image src={x.mediaUrl} />
                    </AspectRatio>
                  )}
                  <Stack
                    alignItems={'flex-end'}
                    justifyContent='center'>
                    <DynamicButton
                      size={'sm'}
                      variant={'solid'}
                      action={'read'}
                      onClick={() => window.open(x.postUrl, '_blank')}
                    />
                  </Stack>
                </Grid>
                <Divider />
                <Stack>
                  <HStack spacing={2}>
                    <Stack
                      alignItems={'center'}
                      justifyContent='center'>
                      <Text
                        fontSize={'xs'}
                        color='gray.600'>
                        Comments
                      </Text>
                      <Heading size='md'>{x.commentsCount}</Heading>
                    </Stack>
                    <Stack
                      alignItems={'center'}
                      justifyContent='center'>
                      <Text
                        fontSize={'xs'}
                        color='gray.600'>
                        Like
                      </Text>
                      <Heading size='md'>{x.likeCount}</Heading>
                    </Stack>
                    <Spacer />
                    <Stack>
                      <Text
                        fontSize={'xs'}
                        color='gray.600'>
                        Share via {globalState?.socialActive?.platform} on {moment(x.created).format('LLLL')}
                      </Text>
                    </Stack>
                  </HStack>
                </Stack>
              </Stack>
            );
          })}
        </SimpleGrid>
      ) : (
        <Center h={'30vh'}>
          <Stack align={'center'}>
            <Text
              color={'gray.500'}
              fontWeight={'semibold'}>
              No Stories Posted
            </Text>
            <Text
              color={'gray.500'}
              fontWeight={'semibold'}>
              Please create in Queue Tab
            </Text>
          </Stack>
        </Center>
      )}
    </Stack>
  );
}

export default StoriesTab;
