import { Center, Divider, Heading, HStack, Image, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import { getPlatformIcon } from '../../Utils/PlatformIcon';
import InstagramAnalyticsPost from '../Analytics/InstagramAnalyticsPost';
import TiktokAnalyticsPost from '../Analytics/TiktokAnalyticsPost';
import YoutubeAnalyticsPost from '../Analytics/YoutubeAnalyticsPost';
import LinkedinAnalyticsPost from '../Analytics/linkedinAnalyticsPost';

function HistoryAnalyticsTab() {
  const globalState = useUserStore();
  const toast = useToast();
  const lastRecords = 0;
  const lastDays = 0;
  const [historyList, setHistoryList] = useState([]);

  const getHistory = async () => {
    if (globalState?.socialKey) {
      globalState.setIsLoading(true);
      try {
        const res = await _axios.post('ayrshare-history', {
          lastRecords: lastRecords || 0,
          lastDays: lastDays || 0,
          profileKey: globalState?.socialKey,
        });
        const filterData = res?.data?.filter((x) => x?.status === 'success');
        if (res?.data?.status === 'error') {
          toast({
            title: 'Deoapp.com',
            description: res?.data?.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }

        const updatedFilterData = await Promise.all(
          filterData?.map(async (iterator) => {
            const res = await _axios.post('ayrshare-analyticsPost', {
              id: iterator?.id,
              platforms: iterator?.platforms,
              profileKey: globalState?.socialKey,
            });
            const obj = res?.data;
            const { ...analytics } = obj;
            if (analytics?.code !== 186) {
              return { ...iterator, analytics };
            } else {
              return iterator;
            }
          })
        );

        const filteredHistoryList = updatedFilterData?.filter((item) => item?.analytics && item?.analytics?.code !== 186);

        setHistoryList(filteredHistoryList);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error?.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        globalState.setIsLoading(false);
      }
    } else {
      return toast({
        title: 'Deoapp.com',
        description: 'Please set up your social account',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getHistory();

    return () => {};
  }, [globalState?.socialKey]);

  const handleErrorMessage = (message) => {
    toast({
      title: 'Deoapp.com',
      description: message,
      status: 'error',
    });
  };
  console.log(historyList, 'ini historylist');

  return (
    <Stack>
      <HStack>
        <Text
          fontSize={'xl'}
          fontWeight='bold'
          color={'gray.600'}>
          History Insight
        </Text>
        <Text
          fontSize={'md'}
          color='gray.500'>
          ( {historyList?.length} most recent )
        </Text>
      </HStack>
      <Stack>
        {historyList?.length > 0 ? (
          <>
            {historyList?.map((x, index) => {
              return (
                <Stack
                  borderRadius='lg'
                  key={index}
                  shadow='md'
                  // bgColor={'white'}
                  borderTopWidth={5}
                  borderColor='blue.500'
                  p={5}>
                  <HStack
                    alignItems='flex-start'
                    justifyContent={'flex-start'}>
                    <Stack spacing={3}>
                      <Stack>
                        <Text fontSize={'xs'}>{moment(x?.created).format('LLLL')}</Text>
                      </Stack>
                      <Spacer />
                      <Stack>
                        <Text
                          fontSize={'xs'}
                          color='gray.600'>
                          {x?.post}
                        </Text>
                      </Stack>

                      <Spacer />

                      <Stack spacing={5}>
                        {Object.entries(x?.analytics).map(([key, value]) => {
                          // Check if the key is not 'id', 'status', or 'code'
                          if (key !== 'id' && key !== 'status' && key !== 'code') {
                            return (
                              <Stack key={key}>
                                <Text
                                  fontSize={'sm'}
                                  fontWeight='bold'
                                  textTransform={'capitalize'}>
                                  {key}
                                </Text>
                                <Divider />
                                {key === 'instagram' ? (
                                  <InstagramAnalyticsPost analyticsData={value?.analytics} />
                                ) : key === 'youtube' ? (
                                  <YoutubeAnalyticsPost analyticsData={value?.analytics} />
                                ) : key === 'tiktok' ? (
                                  <TiktokAnalyticsPost analyticsData={value?.analytics} />
                                ) : (
                                  <TiktokAnalyticsPost analyticsData={value?.analytics} />
                                )}
                              </Stack>
                            );
                          }
                          // Return null if the key is 'id', 'status', or 'code'
                          return null;
                        })}
                      </Stack>
                    </Stack>

                    <Spacer />
                    <Stack
                      alignItems={'flex-end'}
                      spacing={3}
                      justifyContent='flex-end'>
                      <Stack>
                        <HStack
                          gap={2}
                          maxW='300px'
                          overflowX={'scroll'}
                          alignItems='flex-end'
                          justifyContent={'flex-end'}>
                          {x?.mediaUrls?.length > 0 &&
                            x?.mediaUrls?.map((y, index) => {
                              return (
                                <Stack
                                  key={index}
                                  width='150px'
                                  height='auto'>
                                  {y?.endsWith('.MOV') || y?.endsWith('.mp4') ? (
                                    <video controls>
                                      <source src={y} />
                                      Sorry, your browser doesnt support embedded videos.
                                    </video>
                                  ) : (
                                    <Stack>
                                      <Image
                                        borderRadius={'lg'}
                                        w={'150px'}
                                        shadow={'md'}
                                        src={y}
                                        alt={y}
                                      />
                                    </Stack>
                                  )}
                                </Stack>
                              );
                            })}
                        </HStack>
                      </Stack>

                      <Stack>
                        <Text
                          color={'gray.500'}
                          fontSize='sm'>
                          {x?.errors && x?.postIds ? 'Response Active' : 'Response Inactive'}
                        </Text>
                      </Stack>

                      <HStack>
                        <HStack spacing={2}>
                          {x?.postIds &&
                            x?.postIds.map((z, index) => {
                              const resIcon = getPlatformIcon(z?.platform);

                              return (
                                <a
                                  href={z?.postUrl}
                                  key={index}
                                  target='_blank'
                                  rel='noopener noreferrer'>
                                  <Stack
                                    color={'green'}
                                    key={z.id}
                                    cursor='pointer'
                                    // onClick={() => console.log(z?.postUrl)}
                                  >
                                    {resIcon}
                                  </Stack>
                                </a>
                              );
                            })}
                        </HStack>

                        <HStack spacing={2}>
                          {x?.errors &&
                            x?.errors.map((z, index) => {
                              const resIcon = getPlatformIcon(z?.platform);

                              return (
                                <Stack
                                  key={index}
                                  color='red'
                                  cursor='pointer'
                                  onClick={() => handleErrorMessage(z?.message)}>
                                  {resIcon}
                                </Stack>
                              );
                            })}
                        </HStack>

                        {x?.errors && x?.postIds === undefined && (
                          <HStack spacing={2}>
                            {x?.platforms &&
                              x?.platforms?.map((z, index) => {
                                const resIcon = getPlatformIcon(z?.platform);

                                return (
                                  <Stack
                                    key={index}
                                    cursor='pointer'
                                    color={'gray'}
                                    // onClick={() => console.log(z?.message)}
                                  >
                                    {resIcon}
                                  </Stack>
                                );
                              })}
                          </HStack>
                        )}
                      </HStack>
                    </Stack>
                  </HStack>
                </Stack>
              );
            })}
          </>
        ) : (
          <Center h={300}>
            <Heading
              size={'md'}
              color='gray.600'
              alignSelf={'center'}>
              No History
            </Heading>
          </Center>
        )}
      </Stack>
    </Stack>
  );
}

export default HistoryAnalyticsTab;
