/* eslint-disable react/prop-types */
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import {
  getCollectionFirebase, getSingleDocumentFirebase, addDocumentFirebase,
  setDocumentFirebase
} from '../../Api/firebaseApi';
import determineUserRoles from '../../Hooks/Plugins/RoleGlobal';

function SelectCompanyProject({ globalStateProps, moduleProps }) {

  const [listProject, setListProject] = useState([]);
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false);


  const modalAddProject = useDisclosure();


  const [dataProject, setDataProject] = useState({
    name: '',
    description: '',
    modules: [moduleProps],
  });


  useEffect(() => {
    fetchProjects(globalStateProps.currentCompany);

    return () => { };
  }, [globalStateProps.currentCompany]);

  const fetchProjects = async (id) => {

    const conditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: globalStateProps.uid,
      },
      {
        field: 'companyId',
        operator: '==',
        value: id,
      },
    ];

    const sortBy = { field: 'name', direction: 'asc' };


    const projects = await getCollectionFirebase('projects', conditions, sortBy);

    const fetchProjectId = localStorage.getItem('currentProject');

    globalStateProps.setProjects(projects);

    if (!fetchProjectId) {
      if (projects?.length > 0) {
        globalStateProps.setCurrentProject(projects[0].id);
        localStorage.setItem('currentProject', projects[0].id);
      } else {
        globalStateProps.setCurrentProject(null);
        localStorage.removeItem('currentProject');
      }
    } else {
      globalStateProps.setCurrentProject(fetchProjectId);
    }
    setListProject(projects);
  };

  const handleUsersDisplay = async (companies, currentCompany) => {
    try {
      const findCompany = companies.find((x) => x?.id === currentCompany);

      if (!findCompany) {
        toast({
          title: 'Deoapp.com',
          description: 'company not found',
          status: 'error',
          position: 'top-right',
          isClosable: true,
          duration: 3000
        });
        return;
      }

      globalStateProps.setExpired(
        findCompany?.expired_at?.seconds >= moment().unix() && findCompany?.module?.includes('socialmedia') ? false : true
      );



      const allUsers = [...new Set([...findCompany.users, ...findCompany.owner])];
      const dataUsersDisplay = [];

      await Promise.all(
        allUsers.map(async (userId) => {
          try {
            const result = await getSingleDocumentFirebase('users', userId);

            const user = {
              id: userId || '',
              name: result?.name || result?.displayName || '',
              email: result?.email || '',
              phoneNumber: result?.phoneNumber || result?.phone || '',
              image: result?.image || '',
            };

            dataUsersDisplay.push(user);
          } catch (error) {

            toast({
              title: 'Deoapp.com',
              description: error.message,
              status: 'error',
              position: 'top-right',
              isClosable: true,
              duration: 3000
            });
          }
        })
      );

      globalStateProps.setUsers(dataUsersDisplay);
    } catch (error) {

      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  };

  const handleCompanySelect = async (e) => {
    globalStateProps.setIsLoading(true);
    const dataCompany = globalStateProps.companies;
    const findCompany = dataCompany?.find((x) => x.id === e);
    const companyId = findCompany?.id || e;

    localStorage.setItem('currentCompany', companyId);

    await handleUsersDisplay(dataCompany, companyId);
    globalStateProps.setCurrentCompany(companyId);
    globalStateProps.setCurrentXenditId(findCompany?.xenditId);

    const userRoles = determineUserRoles(findCompany, globalStateProps.uid);
    globalStateProps.setRoleCompany(userRoles);

    if (companyId) {
      const conditions = [
        {
          field: 'users',
          operator: 'array-contains',
          value: globalStateProps.uid,
        },
        {
          field: 'companyId',
          operator: '==',
          value: companyId,
        },
      ];

      const sortBy = { field: 'name', direction: 'asc' };


      try {


        const projects = await getCollectionFirebase('projects', conditions, sortBy);
        globalStateProps.setProjects(projects);

        const currentProjectId = getCurrentProjectId(projects, companyId);

        if (currentProjectId) {
          globalStateProps.setCurrentProject(currentProjectId);
          localStorage.setItem('currentProject', currentProjectId);

          const project = projects.find(
            (project) => project.id === currentProjectId
          );
          const projectRoles = determineUserRoles(project, globalStateProps.uid);
          globalStateProps.setRoleProject(projectRoles);
        } else if (projects.length > 0) {
          const defaultProjectId = projects[0].id;
          globalStateProps.setCurrentProject(defaultProjectId);
          localStorage.setItem('currentProject', defaultProjectId);

          const defaultProject = projects.find(
            (project) => project.id === defaultProjectId
          );
          const defaultProjectRoles = determineUserRoles(
            defaultProject,
            globalStateProps.uid
          );

          globalStateProps.setRoleProject(defaultProjectRoles);
        } else {
          globalStateProps.setCurrentProject(null);
          localStorage.removeItem('currentProject');
        }
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
          duration: 3000
        });
      }
    }

    globalStateProps.setIsLoading(false);
  };

  const getCurrentProjectId = (projects, companyId) => {
    const currentProjectId = localStorage.getItem('currentProject');
    if (currentProjectId) {
      const project = projects.find(
        (project) => project.companyId === companyId
      );
      return project ? project.id : null;
    }
    return null;
  };


  const handleProjectSelect = async (e) => {
    if (e === 'add project') {
      modalAddProject.onOpen();
    } else {
      globalStateProps.setIsLoading(true);
      const dataProject = listProject;

      const findProject = dataProject.find((x) => x.id === e);
      localStorage.setItem('currentProject', findProject.id || e);
      globalStateProps.setCurrentProject(findProject.id || e);

      const projectRole = determineUserRoles(findProject, globalStateProps.uid);
      globalStateProps.setRoleProject(projectRole);


      globalStateProps.setIsLoading(false);
    }
  };

  const handleAddProject = async () => {
    setIsLoading(true);

    if (globalStateProps?.roleCompany !== 'owner') {
      return toast({
        title: 'Alert!',
        description: 'You dont have access to edit this project.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }

    try {
      if (dataProject?.name === '' || dataProject?.description === '') {
        return toast({
          title: 'Deoapp CRM',
          description: 'Please fill the form',
          status: 'error',
          duration: 3000,
        });
      } else {
        const data = {
          ...dataProject,
          owner: [globalStateProps?.uid],
          users: [globalStateProps?.uid],
        };

        const res = await addDocumentFirebase('projects', data, globalStateProps?.currentCompany);

        const userData = {
          name: globalStateProps?.name,
          email: globalStateProps?.email,
          createdAt: new Date(),
          id: globalStateProps?.uid,
        };

        await setDocumentFirebase(`projects/${res}/users`, globalStateProps?.uid, userData);

        localStorage.setItem('currentProject', res);
        globalStateProps.setCurrentProject(res);

        toast({
          title: 'Deoapp CRM',
          description: 'Project Created!',
          status: 'success',
          duration: 3000,
        });

        // Jika ingin mereload halaman, gunakan window.location.reload()
        // window.open('/crm') akan membuka halaman baru, bukan mereload halaman saat ini
        window.location.reload();
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Stack>
      <Stack alignItems={'center'}>
        <Text fontWeight={500} fontSize="xs">
          Company :
        </Text>

        <Select
          w={['90%', '100%', '80%']}
          size={'sm'}
          borderColor={
            !globalStateProps?.currentCompany ? 'red.600' : 'blue.600'
          }
          borderWidth={2}
          placeholder={
            globalStateProps?.companies?.length === 0 && 'select Company'
          }
          value={globalStateProps?.currentCompany}
          onChange={(e) => {
            handleCompanySelect(e.target.value);
          }}
        >
          {globalStateProps?.companies?.map((select, i) => {
            return (
              <option key={i} value={select?.id}>
                <Text textTransform={'capitalize'}>{select?.name}</Text>
              </option>
            )
          })}
        </Select>
      </Stack>

      <Stack alignItems={'center'}>
        <Select
          w={['90%', '100%', '80%']}
          size={'sm'}
          borderColor={
            !globalStateProps?.currentProject ? 'red.600' : 'blue.600'
          }
          borderWidth={2}
          placeholder={listProject?.length === 0 && 'select project'}
          value={globalStateProps?.currentProject}
          onChange={(e) => {
            handleProjectSelect(e.target.value);
          }}
        >
          {listProject?.map((select, i) => {
            return (
              <option key={i} value={select?.id}>
                <Text textTransform={'capitalize'}>{select?.name}</Text>
              </option>
            )
          })}
          <option value={'add project'} style={{ padding: '10px 0' }}>
            + New Project / Team
          </option>
        </Select>
      </Stack>
      <Modal
        isOpen={modalAddProject.isOpen}
        onClose={modalAddProject.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Fill the form correctly</Text>

            <FormControl>
              <FormLabel fontSize={14} my={3}>
                Project Name
                <Text as={'span'} color={'gray.400'} fontStyle={'italic'}>
                  {'  '}
                  {'('}your business name{')'}
                </Text>
              </FormLabel>
              <Input
                onChange={(e) =>
                  setDataProject({ ...dataProject, name: e.target.value })
                }
                placeholder={'Enter project/business name'}
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize={14} my={3}>
                Project Description
              </FormLabel>
              <Input
                onChange={(e) =>
                  setDataProject({
                    ...dataProject,
                    description: e.target.value,
                  })
                }
                placeholder={'Enter project/business description'}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isLoading}
              onClick={handleAddProject}
              colorScheme="green"
            >
              Add New Project
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default SelectCompanyProject