import { Avatar, AvatarBadge, Box, Divider, HStack, SimpleGrid, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import _axios from '../../Api/AxiosBarrier';
import { getCollectionFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { platformArr } from '../../Pages/SocialAccounts/PlatformArr';
import DynamicButton from '../Buttons/DynamicButton';

function SocialAccountComponents() {
  const globalState = useUserStore();

  const [userData, setUserData] = useState('');
  const [socialAccountList, setSocialAccountList] = useState([]);
  const toast = useToast();

  const getData = async () => {
    const conditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: globalState?.uid,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
      {
        field: 'type',
        operator: '==',
        value: 'social-media',
      },
    ];

    try {
      const socialMediaKey = await getCollectionFirebase('files', conditions);

      if (socialMediaKey.length > 0) {
        setUserData(socialMediaKey[0]);
        setSocialAccountList(socialMediaKey[0].displayNames);
        globalState.setSocialData(socialMediaKey[0]);
        globalState.setSocialKey(socialMediaKey[0]?.ayrshare_account?.profileKey);
        globalState.setSocialActive(socialMediaKey[0]?.displayNames[0]);
      } else {
        // setUserData('')
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  //   console.log(socialAccountList, 'ini display names');
  //   console.log(globalState.socialData, 'ini global state social data');

  const handleActiveSocial = (data) => {
    globalState.setSocialActive(data);
    toast({
      title: 'Deoapp.com',
      description: `Now, you're active in ${data?.displayName}`,
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });
  };

  const handleWindowConnected = async (key) => {
    if (key) {
      globalState.setIsLoading(true);
      const res = await _axios.post('/ayrshare-generateJWT', {
        domain: 'id-ou7lt',
        profileKey: key,
      });
      const url = res.data.url + '&logout=true';
      window.open(url, '_blank', 'noreferrer');
      globalState.setIsLoading(false);
    }
  };

  const handleRefreshProfile = async (key) => {
    const collectionName = 'files';
    const docName = userData.id;
    if (key) {
      try {
        const res = await _axios.post('/ayrshare-user', {
          profileKey: key,
        });

        const result = await updateDocumentFirebase(collectionName, docName, res.data);

        if (result) {
          toast({
            title: 'Deoapp.com',
            description: 'Success update profile',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
          getData();
        }
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }
  };

  // console.log(socialAccountList, 'ini list social account ');

  useEffect(() => {
    getData();

    return () => {};
  }, [globalState?.currentProject, globalState?.currentCompany]);

  return (
    <Stack spacing={3}>
      <HStack>
        <Text
          fontWeight={'bold'}
          fontSize='sm'
          color={'gray.600'}>
          Channels
        </Text>
        <Spacer />

        <DynamicButton
          action={'read'}
          variant='solid'
          size={'xs'}
          onClick={() => handleRefreshProfile(userData?.ayrshare_account?.profileKey)}
        />
      </HStack>
      <Divider />
      <Stack>
        {socialAccountList?.length > 0 ? (
          <>
            <SimpleGrid
              columns={[1]}
              mt={3}
              spacing={3}>
              {socialAccountList?.map((x, i) => (
                <Box key={i}>
                  <HStack
                    shadow={'md'}
                    alignItems={'center'}
                    _hover={{
                      transform: 'scale(1.03)',
                      shadow: 'xl',
                    }}
                    transition={'0.2s ease-in-out'}
                    justifyContent='center'
                    borderRadius='lg'
                    key={i}
                    borderTopWidth={2}
                    borderColor={globalState?.socialActive?.title === x?.title ? 'blue.500' : 'transparent'}
                    px={5}
                    py={2}
                    cursor='pointer'>
                    <Stack>
                      <Avatar
                        src={x?.userImage}
                        alt={x?.displayName}
                        size='sm'
                        onClick={() => window.open(x.profileUrl, '_blank')}>
                        {globalState?.socialActive?.title === x.title && (
                          <AvatarBadge
                            boxSize='1.5em'
                            bg='red.400'>
                            {' '}
                            {platformArr.map((platform, i) => (platform?.name === x.platform ? <Stack key={i}>{platform?.icon}</Stack> : null))}
                          </AvatarBadge>
                        )}
                      </Avatar>
                    </Stack>
                    <Spacer />
                    <Stack
                      spacing={0}
                      onClick={() => handleActiveSocial(x)}>
                      <Text
                        fontSize='xs'
                        noOfLines={1}
                        w='150px'>
                        {x?.displayName}
                      </Text>
                      <Text
                        fontSize='xs'
                        noOfLines={1}>
                        {x?.username && `@${x.username}`}
                      </Text>
                      {globalState?.socialActive?.id === x.id && (
                        <Stack>
                          <Text
                            fontSize={'xs'}
                            fontWeight={500}
                            color='green'>
                            active
                          </Text>
                        </Stack>
                      )}
                    </Stack>
                  </HStack>
                </Box>
              ))}
              <DynamicButton
                action={'create'}
                title='New Channel'
                size={'sm'}
                onClick={() => handleWindowConnected(userData?.ayrshare_account?.profileKey)}
              />
            </SimpleGrid>
          </>
        ) : (
          <Box
            align={'center'}
            my={5}>
            <Text
              textAlign={'center'}
              fontSize='sm'
              fontStyle={'italic'}
              color={'gray.600'}>
              No social media linked to this account
            </Text>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

export default SocialAccountComponents;
