import React, { useEffect, useState } from 'react';
import { Box, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../../Api/firebaseApi';
import BackButtons from '../../../Components/Buttons/BackButtons';
import { clientTypessense } from '../../../Api/Typesense';
import SearchBar from './SearchBarFYP';
import { TiktokFYPCard } from './TiktokFYPCard';
import useUserStore from '../../../Hooks/Zustand/Store';

function SocialMediaResearchTiktokPage() {
  const [data, setData] = useState();
  const itemsPerPage = 30;
  const [startIndex, setStartIndex] = useState(0);
  const [inputSearch, setInputSearch] = useState('');
  const [sortingBy, setSortingBy] = useState('create_time');
  const globalState = useUserStore();

  console.log(globalState, 'ini global state');

  const usernameUrl = (link) => {
    const regex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@([^/]+)/i;
    const match = link.match(regex);

    if (match && match[3]) {
      return `https://www.tiktok.com/@${match[3]}`;
    } else {
      return null;
    }
  };

  const sortingBySearch = (e) => {
    setSortingBy(e.target.value);
  };

  const getDataFyp = async () => {
    globalState.setIsLoading(true);
    try {
      const conditions = [];
      const sortBy = { field: sortingBy, direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      let res = [];

      res = await getCollectionFirebase('social-media-fyp', conditions, sortBy, limitValue, startIndex);

      setData(res);
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      throw new Error(error, 'An error occured');
    }
  };

  const handleTypesenseSearch = (q) => {
    try {
      const searchParameters = {
        q: q,
        query_by: 'desc',
        filter_by: '',
        per_page: 30,
      };

      if (q) {
        globalState.setIsLoading(true);
        clientTypessense
          .collections('social_media_fyp')
          .documents()
          .search(searchParameters)
          .then((x) => {
            const newData = x.hits.map((y) => {
              return { ...y.document };
            });

            const arrContentId = newData.map((z) => {
              return z.id;
            });

            setInputSearch(q);
            console.log(arrContentId, 'ini content id');

            Promise.all(
              arrContentId.map(async (id) => {
                try {
                  return await getSingleDocumentFirebase('social-media-fyp', id);
                } catch (error) {
                  console.log(error.message);
                  return null;
                }
              })
            ).then((results) => {
              const filteredResults = results.filter((result) => result !== null);

              const mergedResult = [].concat.apply([], filteredResults);

              console.log(mergedResult);

              setData(mergedResult);
            });
          })
          .catch((error) => {
            console.log(error, 'errorrr');
          });
      } else {
        setInputSearch(q);
        // getDataFyp();
      }
    } catch (error) {
      console.log(error.message);
    }
    globalState.setIsLoading(false);
  };

  const handleOpenLink = (value) => {
    window.open(value?.shareUrl, '_blank');
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  console.log(inputSearch, 'ini input search');

  useEffect(() => {
    if (inputSearch === '') {
      getDataFyp();
    }
  }, [startIndex, inputSearch, sortingBy]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading
          ml={3}
          onClick={() => {
            throw new Error('tes error timothy');
          }}>
          Tiktok FYP research
        </Heading>
        <Spacer />
        <SearchBar
          data={data}
          handleSearch={handleTypesenseSearch}
          sortBySearch={sortingBySearch}
          sortBy={sortingBy}
        />
      </HStack>
      {data ? (
        <TiktokFYPCard
          dataHasil={data}
          handleOpenLink={handleOpenLink}
          handleLoadMore={handleLoadMore}
          usernameUrl={usernameUrl}
        />
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  );
}

export default SocialMediaResearchTiktokPage;
