function DeepClone(value) {
  if (typeof value !== 'object' || value === null) {
    return value;
  }

  const target = Array.isArray(value) ? [] : {};

  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      target[key] = DeepClone(value[key]);
    }
  }

  return target;
}

export default {
  DeepClone,
};
