/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';
import { Preview } from '@creatomate/preview';
import groupBy from '../../Utils/groupBy.js';
import DeepFind from '../../Utils/deepFind.js';
import deepClone from '../../Utils/deepClone.js';

class VideoCreatorStore {
  preview = undefined;
  state = undefined;
  tracks = undefined;
  activeCompositionId = undefined;
  activeElementIds = [];
  isLoading = true;
  isPlaying = false;
  time = 0;
  timelineScale = 100;
  isScrubbing = false;

  constructor() {
    makeAutoObservable(this);
  }

  initializeVideoPlayer(htmlElement) {
    if (this.preview) {
      this.preview.dispose();
      this.preview = undefined;
    }

    const preview = new Preview(htmlElement, 'interactive', process.env.NEXT_PUBLIC_CREATOMATE_PUBLIC_TOKEN);

    this.preview = preview;

    preview.onReady = async () => {
      await preview.setSource(this.getDefaultSource());
    };

    preview.onLoad = async () => {
      runInAction(() => {
        this.isLoading = true;
      });
    };

    preview.onLoadComplete = () => {
      runInAction(() => {
        this.isLoading = false;
      });
    };

    preview.onPlay = () => {
      runInAction(() => {
        this.isPlaying = true;
      });
    };

    preview.onPause = () => {
      runInAction(() => {
        this.isPlaying = false;
      });
    };

    preview.onTimeChange = (time) => {
      if (!this.isScrubbing) {
        runInAction(() => {
          this.time = time;
        });
      }
    };

    preview.onActiveCompositionChange = (elementId) => {
      runInAction(() => {
        this.activeCompositionId = elementId ?? undefined;
      });
      this.updateTracks();
    };

    preview.onActiveElementsChange = (elementIds) => {
      runInAction(() => {
        this.activeElementIds = elementIds;
      });
    };

    preview.onStateChange = (state) => {
      runInAction(() => {
        this.state = state;
      });
      this.updateTracks();
    };
  }

  async setTime(time) {
    this.time = time;
    await this.preview?.setTime(time);
  }

  async setActiveElements(...elementIds) {
    this.activeElementIds = elementIds;
    await this.preview?.setActiveElements(elementIds);
  }

  getActiveElement() {
    if (!this.preview || this.activeElementIds.length === 0) {
      return undefined;
    }

    const id = this.activeElementIds[0];
    return this.preview.findElement((element) => element.source.id === id);
  }

  getActiveComposition() {
    if (!this.preview) {
      return undefined;
    } else if (this.activeCompositionId) {
      return this.preview.findElement((element) => element.source.id === this.activeCompositionId);
    } else {
      return this.preview.state;
    }
  }

  getActiveCompositionElements() {
    return this.getActiveComposition()?.elements ?? [];
  }

  getActiveCompositionSource() {
    if (!this.preview || !this.preview.state) {
      return {};
    }

    if (this.activeCompositionId) {
      const activeComposition = this.preview.findElement((element) => element.source.id === this.activeCompositionId);
      return this.preview.getSource(activeComposition);
    } else {
      return this.preview.getSource(this.preview.state);
    }
  }

  async setActiveCompositionSource(source) {
    const activeCompositionId = this.activeCompositionId;
    if (activeCompositionId) {
      const fullSource = deepClone(this.preview.getSource());
      const activeComposition = DeepFind((element) => element.id === activeCompositionId, fullSource);
      if (activeComposition) {
        Object.keys(activeComposition).forEach((key) => delete activeComposition[key]);
        Object.assign(activeComposition, source);
      }

      await this.preview.setSource(fullSource, true);
    } else {
      await this.preview?.setSource(source, true);
    }
  }

  async createElement(elementSource) {
    console.log(this.preview, 'ini preview');
    console.log(elementSource, 'ini element source');
    if (!this.preview || !this.preview.state) {
      return;
    }

    const elements = this.getActiveCompositionElements();
    const newTrackNumber = Math.max(...elements.map((element) => element.track)) + 1;

    const source = deepClone(this.getActiveCompositionSource());
    const id = uuid();

    source.elements.push({
      id,
      track: newTrackNumber,
      ...elementSource,
    });

    await this.setActiveCompositionSource(source);

    await this.setActiveElements(id);
  }

  async deleteElement(elementId) {
    if (!this.preview || !this.preview.state) {
      return;
    }

    const source = deepClone(this.getActiveCompositionSource());
    source.elements = source.elements.filter((element) => element.id !== elementId);

    await this.setActiveCompositionSource(source);
  }

  async rearrangeTracks(track, direction) {
    if (!this.preview || !this.preview.state) {
      return;
    }

    const targetTrack = direction === 'up' ? track + 1 : track - 1;
    if (targetTrack < 1) {
      return;
    }

    const source = deepClone(this.getActiveCompositionSource());

    for (const element of this.getActiveCompositionElements()) {
      const elementSource = source.elements.find((elemSource) => elemSource.id === element.source.id);

      if (elementSource) {
        if (element.track === track) {
          elementSource.track = targetTrack;
        } else if (element.track === targetTrack) {
          elementSource.track = track;
        }
      }
    }

    await this.setActiveCompositionSource(source);
  }

  async finishVideo() {
    if (!this.preview) {
      return;
    }

    const response = await fetch('/api/videos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source: this.preview.getSource(),
      }),
    });

    return await response.json();
  }

  updateTracks() {
    this.tracks = groupBy(this.getActiveCompositionElements(), (element) => element.track);
  }

  getDefaultSource() {
    return {};
  }
}

export const videoCreator = new VideoCreatorStore();

const VideoPlayerComponent = observer(() => {
  const videoElementRef = useRef(null);

  useEffect(() => {
    videoCreator.initializeVideoPlayer(videoElementRef.current);
    return () => {
      if (videoCreator.preview) {
        videoCreator.preview.dispose();
      }
    };
  }, []);

  return <div ref={videoElementRef} />;
});

export default VideoPlayerComponent;
