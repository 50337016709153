import { Avatar, AvatarBadge, Box, Button, Divider, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text, useToast, Icon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdCancel } from 'react-icons/md';

// import logodeo from "../assets/1.png";
import moment from 'moment';
import { TbPresentationAnalytics } from 'react-icons/tb';
import { auth } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { addDocumentFirebase, getCollectionFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';
import { platformArr } from './PlatformArr';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import ModalInviteUser from '../../Components/Modals/ModalInviteUser';
import _axios from '../../Api/AxiosBarrier';
import { FaLock } from 'react-icons/fa';

function SocialAccountPage() {
  const height = window.innerHeight;

  const globalState = useUserStore();

  const [isLoading, setIsLoading] = useState(false);

  const [socialAccountModal, setSocialAccountModal] = useState(false);
  const [titleAccount, setTitleAccount] = useState('');
  const [userData, setUserData] = useState('');
  const [socialAccountList, setSocialAccountList] = useState([]);
  const toast = useToast();
  const [usersDisplay, setUsersDisplay] = useState([]);
  const [users, setUsers] = useState([]);
  const [isDisable, setIsDisable] = useState(false);

  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [modalProjectUser, setModalProjectUser] = useState(false);

  // console.log(globalState, 'ini global state');

  const getListSocialMedia = async () => {
    const conditions = [
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
      {
        field: 'type',
        operator: '==',
        value: 'social-media',
      },
    ];
    try {
      const socialMediaKey = await getCollectionFirebase('files', conditions);
      if (socialMediaKey.length > 0) {
        if (socialMediaKey[0].users?.includes(globalState.uid)) {
          setIsDisable(true);
          setUserData(socialMediaKey[0]);
          setUsersDisplay(socialMediaKey[0].usersDisplay || []);
          setUsers(socialMediaKey[0].users || []);
          setSocialAccountList(socialMediaKey[0].displayNames);
        } else {
          setIsDisable(false);
          setUserData(socialMediaKey[0]);
          setUsersDisplay(socialMediaKey[0].usersDisplay || []);
          setUsers(socialMediaKey[0].users || []);
          setSocialAccountList(socialMediaKey[0].displayNames);
          toast({
            title: 'Deoapp',
            description: 'Your social media project has been created but you haven\'t invited to social media project',
            duration: 3000,
            status: 'warning',
          });
        }
      } else {
        setUserData('');
        setIsDisable(false);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleOpenModalCreate = async () => {
    setSocialAccountModal(true);
  };

  const handleSubmitAccount = async () => {
    try {
      setIsLoading(true);
      const res = await _axios.post('ayrshare-createProfile', {
        title: titleAccount,
      });
      if (res.data.status === 'success') {
        //add to socialMedia
        try {
          const data = {
            title: titleAccount,
            type: 'social-media',
            companyId: globalState.currentCompany,
            projectId: globalState.currentProject,
            lastUpdated: new Date(),
            users: [globalState.uid],
            usersDisplay: [
              {
                id: auth?.currentUser?.uid,
                image: auth.currentUser?.photoURL,
                name: auth?.currentUser?.displayName,
                email: auth?.currentUser?.email,
              },
            ],
            ayrshare_account: res.data,
          };
          addDocumentFirebase('files', data, globalState.currentCompany).then(() => {
            toast({
              title: 'Deoapp',
              description: 'Account Update',
              duration: 3000,
              status: 'success',
            });
            setIsLoading(false);
          });
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
        getListSocialMedia();
        setSocialAccountModal(false);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnected = async (data) => {
    try {
      const res = await _axios.post('ayrshare-unlinkSocial', {
        platform: data.platform,
        profileKey: userData?.ayrshare_account?.profileKey,
      });
      if (res.data.status === 'success') {
        toast({
          title: 'Deoapp.com',
          description: 'Succes to disconnect from social accounts',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });

        getListSocialMedia();
        // update data linked social media
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleWindowConnected = async (key) => {
    if (key) {
      const res = await _axios.post('/ayrshare-generateJWT', {
        domain: 'id-ou7lt',
        profileKey: key,
      });
      // console.log(url, 'ini url');
      // console.log(res, 'ini res');
      const url = res.data.url + '&logout=true';
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const handleOpenModalProject = () => {
    setModalProjectUser(true);
  };

  const handleRemoveUser = (index) => {
    if (index < 0 || index >= usersDisplay.length) {
      return; // Pastikan index valid
    }

    const updatedUsersDisplay = [...usersDisplay];
    updatedUsersDisplay.splice(index, 1);

    const userToRemove = usersDisplay[index];
    const updatedUsers = users.filter((uid) => uid !== userToRemove.id);
    setUsersDisplay(updatedUsersDisplay);
    setUsers(updatedUsers);

    // setData({
    //   ...data,
    //   usersDisplay: updatedUsersDisplay,
    //   users: updatedUsers,
    // });
  };

  const handleRefreshProfile = async (key) => {
    const collectionName = 'files';
    const docName = userData.id;
    if (key) {
      try {
        const res = await _axios.post('/ayrshare-user', {
          profileKey: key,
        });

        const result = await updateDocumentFirebase(collectionName, docName, res.data);

        if (result) {
          toast({
            title: 'Deoapp.com',
            description: 'Success update profile',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
          getListSocialMedia();
        }
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    getListSocialMedia();
    return () => {};
    // }, [globalState.uid]);
  }, [globalState.currentProject]);

  return (
    <Stack p={[1, 1, 5]}>
      <HStack>
        <BackButtons />
        <Stack>
          <Text
            fontSize={'xl'}
            fontWeight='bold'
            color={'gray.600'}>
            Social Accounts
          </Text>
        </Stack>
        <Spacer />
        {userData === '' && (
          <Stack>
            <DynamicButton
              title={'Social Account'}
              action='create'
              variant={'solid'}
              size='sm'
              onClick={handleOpenModalCreate}
            />
          </Stack>
        )}
      </HStack>

      <Stack
        minH={height}
        spacing={3}
        w='full'>
        <Stack
          position={'relative'}
          transition={'0.2s ease-in-out'}
          spacing={5}>
          {!isDisable && (
            <Stack
              p={1}
              w={'full'}
              h='700px'
              position={'absolute'}
              zIndex={100}
              opacity={0.5}
              alignItems='center'
              justifyContent={'center'}
              bgColor='gray.300'>
              <FaLock size={50} />
              <Text
                fontSize={'xl'}
                fontWeight={500}>
                You dont have access
              </Text>
            </Stack>
          )}

          <Stack
            shadow={'md'}
            justifyContent='center'
            borderRadius='lg'
            borderTopWidth={5}
            borderColor='blue.500'
            p={5}
            spacing={3}>
            <Text
              fontSize={'sm'}
              color='gray.600'>
              {moment(userData?.createdAt?.seconds * 1000).format('LLLL')}
            </Text>
            <HStack>
              <Text
                fontSize={'xs'}
                color='gray.600'>
                Title
              </Text>
              <Spacer />
              <Text
                fontSize={'xs'}
                color='gray.900'
                fontWeight={'bold'}>
                {userData?.title}
              </Text>
            </HStack>
            <HStack>
              <Text
                fontSize={'xs'}
                color='gray.600'>
                Profile Key
              </Text>
              <Spacer />
              <Stack>
                <Text
                  fontSize={'xs'}
                  color='gray.900'
                  fontWeight={'bold'}>
                  {userData?.ayrshare_account?.profileKey}
                </Text>
              </Stack>
            </HStack>

            <HStack>
              <Text
                fontSize={'xs'}
                color='gray.600'>
                Team
              </Text>
              <Spacer />
              <Stack>
                <Text
                  fontSize={'xs'}
                  color='gray.900'
                  fontWeight={'bold'}>
                  {userData?.users?.length}
                </Text>
              </Stack>
            </HStack>

            <Stack>
              <Text fontWeight='bold'>Team</Text>
              <Box
                shadow='base'
                // bg="white"
                padding={3}
                border={'0.5px'}
                borderColor={'gray.300'}
                borderRadius={'sm'}>
                <HStack
                  spacing={3}
                  p={2}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}>
                  <HStack>
                    <Stack
                      borderRadius={'sm'}
                      pos={'relative'}
                      borderColor={'gray.300'}>
                      <DynamicButton
                        action={'create'}
                        variant='solid'
                        size={'sm'}
                        onClick={handleOpenModalProject}
                      />
                    </Stack>
                    {usersDisplay?.length > 0 ? (
                      <HStack
                        w={'900px'}
                        overflowX={'scroll'}
                        py='2'
                        gap={3}>
                        {usersDisplay?.map((x, i) => (
                          <Stack
                            key={i}
                            p={2}
                            border={'1px'}
                            borderRadius={'sm'}
                            shadow={'base'}
                            pos={'relative'}
                            borderColor={'gray.300'}
                            alignItems='center'
                            minW={'100px'}>
                            <Avatar
                              cursor={'pointer'}
                              size='md'
                              src={x.image || x.name}
                              name={x.name}
                              alt={x}>
                              <AvatarBadge
                                boxSize='1.2em'
                                bg='green.500'
                              />
                            </Avatar>
                            <HStack
                              textTransform={'capitalize'}
                              overflow='hidden'>
                              <Text
                                noOfLines={1}
                                fontSize={'2xs'}
                                fontWeight={500}
                                textTransform={'capitalize'}>
                                {x.name}
                              </Text>
                              <Icon
                                onClick={() => handleRemoveUser(i)}
                                cursor='pointer'
                                pos={'absolute'}
                                color={'red.300'}
                                top={-1}
                                right={-1}
                                as={MdCancel}
                                boxSize={4}
                              />
                            </HStack>
                          </Stack>
                        ))}
                      </HStack>
                    ) : (
                      <Text>No Team data</Text>
                    )}
                  </HStack>
                </HStack>
              </Box>
            </Stack>
          </Stack>

          <Stack spacing={5}>
            <HStack>
              <Text mb={2}>User Accounts</Text>
              <Spacer />
              <DynamicButton
                title={'Connect More Account'}
                size='sm'
                onClick={() => handleWindowConnected(userData?.ayrshare_account?.profileKey)}
                variant='solid'
                action={'create'}
              />

              <DynamicButton
                title={'Reload'}
                size='sm'
                onClick={() => handleRefreshProfile(userData?.ayrshare_account?.profileKey)}
                variant='solid'
                action={'read'}
              />
            </HStack>

            <Divider />
            <Box borderRadius={'md'}>
              <Box mt={5}>
                {socialAccountList?.length > 0 ? (
                  <>
                    <SimpleGrid
                      columns={[1, 2, 3]}
                      mt={3}
                      spacing={3}>
                      {socialAccountList?.map((x, i) => (
                        <Box key={i}>
                          <Stack
                            shadow={'md'}
                            alignItems={'center'}
                            _hover={{
                              transform: 'scale(1.03)',
                              shadow: 'xl',
                            }}
                            transition={'0.2s ease-in-out'}
                            justifyContent='center'
                            borderRadius='lg'
                            key={i}
                            borderTopWidth={5}
                            borderColor='blue.500'
                            p={5}>
                            <Stack>
                              <Avatar
                                src={x.userImage}
                                alt={x.displayName}>
                                <AvatarBadge
                                  boxSize='1.25em'
                                  bg='green.500'>
                                  {' '}
                                  {platformArr.map((platform) => (platform.name === x.platform ? platform.icon : null))}
                                </AvatarBadge>
                              </Avatar>
                            </Stack>
                            <Spacer />
                            <Stack>
                              <Text
                                textAlign={'center'}
                                fontSize='xs'
                                color={'gray.600'}>
                                {x.displayName}
                              </Text>
                            </Stack>

                            <Stack>
                              <SimpleGrid
                                columns={[1]}
                                gap={2}>
                                <Stack
                                  alignItems={'center'}
                                  justifyContent='center'>
                                  <a
                                    href={x.profileUrl}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <Button
                                      size={'sm'}
                                      colorScheme='twitter'>
                                      <Text fontSize={'xs'}>Go to website</Text>
                                    </Button>
                                  </a>
                                </Stack>
                                <Stack
                                  alignItems={'center'}
                                  justifyContent='center'>
                                  <Button
                                    size={'sm'}
                                    colorScheme='red'
                                    onClick={
                                      () => handleDisconnected(x)
                                      // console.log(x)
                                    }>
                                    <Text fontSize={'xs'}>Disconnect</Text>
                                  </Button>
                                </Stack>
                              </SimpleGrid>
                            </Stack>
                          </Stack>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </>
                ) : (
                  <Box
                    align={'center'}
                    my={5}>
                    <Text
                      textAlign={'center'}
                      fontSize='sm'
                      fontStyle={'italic'}
                      color={'gray.600'}>
                      No social media linked to this account
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Modal
        isOpen={socialAccountModal}
        onClose={() => setSocialAccountModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <TbPresentationAnalytics size={20} />
              <Text fontSize={'md'}>New Social Account</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Text>Title</Text>
              <Input
                size={'sm'}
                placeholder='New Title'
                value={titleAccount}
                onChange={(e) => setTitleAccount(e.target.value)}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              size={'sm'}
              colorScheme='blackAlpha'
              mr={3}
              onClick={() => setSocialAccountModal(false)}>
              Close
            </Button>

            <Button
              isLoading={isLoading}
              size={'sm'}
              colorScheme='twitter'
              mr={3}
              onClick={() => handleSubmitAccount()}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalInviteUser
        setSearchResult={setSearchResult}
        setSelectedUserProjectIds={setSelectedUserProjectIds}
        setIsAddingTeam={setIsAddingTeam}
        searchResult={searchResult}
        isAddingTeam={isAddingTeam}
        selectedUserProjectIds={selectedUserProjectIds}
        dataProps={userData}
        setModalProjectUser={setModalProjectUser}
        modalProjectUser={modalProjectUser}
      />
    </Stack>
  );
}

export default SocialAccountPage;
