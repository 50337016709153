/* eslint-disable indent */
/* eslint-disable react/prop-types */
import { Box, Flex, Heading, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { HiOutlineSquares2X2, HiOutlineUser } from 'react-icons/hi2';
import { MdLocationCity, MdOutlineHandyman, MdSupervisorAccount } from 'react-icons/md';
import { TbPresentationAnalytics, TbSpeakerphone } from 'react-icons/tb';
import { NumberAcronym } from '../../Utils/NumberUtils';

function AnalyticsData({ data, platform }) {
  // console.log(data,'ini data didalam')
  let followersCount, engagementCount, brandAwarenessCount, impressionCount, reachCount, tweetCount;
  let viewersByCityArray = [];
  let viewersByAge = [];

  switch (platform) {
    case 'twitter':
      // console.log(data, 'ini data twiter');
      followersCount = data.followersCount ?? 0;
      engagementCount = (data.favoritesCount ?? 0) + (data.tweetCount ?? 0);
      brandAwarenessCount = followersCount * engagementCount;
      impressionCount = followersCount * 2.5;
      tweetCount = data.tweetCount;

      break;

    case 'youtube':
      // console.log(data, 'ini data youtube');
      followersCount = data.subscriberCount;
      engagementCount = data.likes + data.dislikes + data.comments + data.shares;
      brandAwarenessCount = data.views;
      impressionCount = data.viewCount * data.averageViewPercentage;

      break;

    case 'facebook':
      // console.log(data, 'ini data facebook');
      followersCount = data.followersCount;
      engagementCount = data.reactions.total + data.pagePostEngagements;
      brandAwarenessCount = followersCount * engagementCount;
      impressionCount = data.pageImpressions;
      break;

    case 'tiktok':
      followersCount = data.followerCount;
      engagementCount = data.likeCountTotal + data.commentCountTotal + data.shareCountTotal;
      brandAwarenessCount = data.viewCountTotal;
      impressionCount = data.viewCountTotal * 2.5;
      viewersByAge = data.audienceGenders;

      break;

    case 'instagram':
      // console.log(data, 'ini data instagram');
      followersCount = data.followersCount;
      engagementCount = data.likeCount + data.commentsCount;
      brandAwarenessCount = followersCount * engagementCount;
      impressionCount = data.impressionsCount;
      reachCount = data.reachCount;
      viewersByCityArray = Object.entries(data.audienceCity).map(([city, count]) => {
        const [cityName, provinceName] = city.split(',').map((item) => item.trim());
        return { city: cityName, province: provinceName, count: count };
      });
      viewersByAge = Object.entries(data.audienceGenderAge).map(([gander, count]) => {
        const [ganderName, ganderAge] = gander.split('.').map((item) => item.trim());
        return { gander: ganderName, age: ganderAge, count: count };
      });
      // console.log(viewersByAge, 'ini AGe yang di mau');
      break;

    default:
      followersCount = 0;
      engagementCount = 0;
      brandAwarenessCount = 0;
      impressionCount = 0;
  }
  // console.log(platform);

  return (
    <Stack>
      <Stack>
        <HStack>
          <Text
            fontSize={'xl'}
            fontWeight='bold'
            color={'gray.600'}>
            Performance
          </Text>
          <Text
            fontSize={'md'}
            color='gray.500'
            textTransform={'capitalize'}>
            ( {platform} most recent )
          </Text>
        </HStack>
      </Stack>
      <Stack
        alignItems={'center'}
        justifyContent='center'>
        <SimpleGrid
          columns={[1, 3, 5]}
          gap={5}
          maxW='100%'
          alignItems='stretch'>
          <Stack>
            <Stack
              shadow={3}
              borderWidth='1px'
              p={5}
              h={'200px'}
              spacing={5}
              justifyContent='center'
              borderRadius='xl'>
              <HStack>
                <Stack
                  bgColor={'blue.400'}
                  borderRadius='full'
                  alignItems={'center'}
                  justifyContent='center'
                  p={1}>
                  <MdSupervisorAccount
                    size={'20px'}
                    color='white'
                  />
                </Stack>
                <Text color={'gray.500'}>Followers</Text>
              </HStack>
              <Stack>
                <Heading>{NumberAcronym(followersCount)}</Heading>
              </Stack>

              <Stack
                alignItems={'flex-end'}
                justifyContent='flex-end'>
                <Text
                  fontSize={'sm'}
                  color='blue.400'>
                  See Section
                </Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              shadow={3}
              borderWidth='1px'
              p={5}
              h={'200px'}
              spacing={5}
              justifyContent='center'
              borderRadius='xl'>
              <HStack>
                <Stack
                  bgColor={'blue.400'}
                  borderRadius='full'
                  alignItems={'center'}
                  justifyContent='center'
                  p={1}>
                  <TbPresentationAnalytics
                    size={'20px'}
                    color='white'
                  />
                </Stack>
                <Text color={'gray.500'}>Engagement</Text>
              </HStack>
              <Stack>
                <Heading>{NumberAcronym(engagementCount)}</Heading>
              </Stack>

              <Stack
                alignItems={'flex-end'}
                justifyContent='flex-end'>
                <Text
                  fontSize={'sm'}
                  color='blue.400'>
                  See Section
                </Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              shadow={3}
              borderWidth='1px'
              p={5}
              h={'200px'}
              spacing={5}
              justifyContent='center'
              borderRadius='xl'>
              <HStack>
                <Stack
                  bgColor={'blue.400'}
                  borderRadius='full'
                  alignItems={'center'}
                  justifyContent='center'
                  p={1}>
                  <HiOutlineSquares2X2
                    size={'20px'}
                    color='white'
                  />
                </Stack>
                <Text color={'gray.500'}>Brand Awarness</Text>
              </HStack>
              <Stack>
                <Heading>{NumberAcronym(brandAwarenessCount)}</Heading>
              </Stack>

              <Stack
                alignItems={'flex-end'}
                justifyContent='flex-end'>
                <Text
                  fontSize={'sm'}
                  color='blue.400'>
                  See Section
                </Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              shadow={3}
              borderWidth='1px'
              p={5}
              h={'200px'}
              spacing={5}
              justifyContent='center'
              borderRadius='xl'>
              <HStack>
                <Stack
                  bgColor={'blue.400'}
                  borderRadius='full'
                  alignItems={'center'}
                  justifyContent='center'
                  p={1}>
                  <TbSpeakerphone
                    size={'20px'}
                    color='white'
                  />
                </Stack>
                <Text color={'gray.500'}>Impression</Text>
              </HStack>
              <Stack>
                <Heading>{NumberAcronym(impressionCount)}</Heading>
              </Stack>

              <Stack
                alignItems={'flex-end'}
                justifyContent='flex-end'>
                <Text
                  fontSize={'sm'}
                  color='blue.400'>
                  See Section
                </Text>
              </Stack>
            </Stack>
          </Stack>
          {platform === 'instagram' && (
            <Stack>
              <Stack
                shadow={3}
                borderWidth='1px'
                p={5}
                h={'200px'}
                spacing={5}
                justifyContent='center'
                borderRadius='xl'>
                <HStack>
                  <Stack
                    bgColor={'blue.400'}
                    borderRadius='full'
                    alignItems={'center'}
                    justifyContent='center'
                    p={1}>
                    <HiOutlineSquares2X2
                      size={'20px'}
                      color='white'
                    />
                  </Stack>
                  <Text color={'gray.500'}>Reach</Text>
                </HStack>
                <Stack>
                  <Heading>{NumberAcronym(reachCount)}</Heading>
                </Stack>

                <Stack
                  alignItems={'flex-end'}
                  justifyContent='flex-end'>
                  <Text
                    fontSize={'sm'}
                    color='blue.400'>
                    See Section
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          )}
          {platform === 'twitter' && (
            <Stack>
              <Stack
                shadow={3}
                borderWidth='1px'
                p={5}
                h={'200px'}
                spacing={5}
                justifyContent='center'
                borderRadius='xl'>
                <HStack>
                  <Stack
                    bgColor={'blue.400'}
                    borderRadius='full'
                    alignItems={'center'}
                    justifyContent='center'
                    p={1}>
                    <HiOutlineSquares2X2
                      size={'20px'}
                      color='white'
                    />
                  </Stack>
                  <Text color={'gray.500'}>Total Tweet</Text>
                </HStack>
                <Stack>
                  <Heading>{NumberAcronym(tweetCount)}</Heading>
                </Stack>

                <Stack
                  alignItems={'flex-end'}
                  justifyContent='flex-end'>
                  <Text
                    fontSize={'sm'}
                    color='blue.400'>
                    See Section
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          )}
        </SimpleGrid>
        {platform === 'instagram' && (
          <Stack maxW='100%'>
            <Stack
              shadow={3}
              borderWidth='1px'
              p={5}
              h={'200px'}
              maxW='100%'
              spacing={5}
              justifyContent='center'
              borderRadius='xl'
              maxH={300}
              overflowX='auto'>
              <HStack maxW={'full'}>
                <Stack
                  bgColor={'blue.400'}
                  borderRadius='full'
                  alignItems={'center'}
                  justifyContent='center'
                  p={1}>
                  <MdLocationCity
                    size={'20px'}
                    color='white'
                  />
                </Stack>
                <Text color={'gray.500'}>Audience By City</Text>
              </HStack>
              <Flex
                wrap='nowrap'
                overflowX='auto'
                css={{ '&::-webkit-scrollbar': { display: 'none' } }}
                maxW='100%'>
                {viewersByCityArray.map((viewer, index) => (
                  <Stack
                    key={index}
                    spacing={3}
                    me={2}
                    borderWidth='1px'
                    borderColor='blue.400'
                    minW='300px'
                    borderRadius='lg'
                    p={3}
                    flex='0 0 auto'
                    direction='row'
                    align='center'>
                    <HStack>
                      {' '}
                      <Stack
                        borderRadius='full'
                        alignItems={'center'}
                        justifyContent='center'
                        p={1}>
                        <HiOutlineUser size={'40px'} />
                      </Stack>
                      <Heading>{viewer.count}</Heading>
                    </HStack>
                    <Stack spacing={0}>
                      <Text color={'gray.500'}>Province: {viewer.province}</Text>
                      <Text color={'gray.500'}>City: {viewer.city}</Text>
                    </Stack>
                  </Stack>
                ))}
              </Flex>
            </Stack>
          </Stack>
        )}

        {platform === 'instagram' && (
          <Stack maxW='100%'>
            <Stack
              shadow={3}
              borderWidth='1px'
              p={5}
              h={'200px'}
              maxW='100%'
              spacing={5}
              justifyContent='center'
              borderRadius='xl'
              maxH={300}
              overflowX='auto'>
              <HStack maxW={'full'}>
                <Stack
                  bgColor={'blue.400'}
                  borderRadius='full'
                  alignItems={'center'}
                  justifyContent='center'
                  p={1}>
                  <MdLocationCity
                    size={'20px'}
                    color='white'
                  />
                </Stack>
                <Text color={'gray.500'}>Audience By Age</Text>
              </HStack>
              <Flex
                wrap='nowrap'
                overflowX='auto'
                css={{ '&::-webkit-scrollbar': { display: 'none' } }}
                maxW='55vw'>
                {viewersByAge.map((viewer, index) => (
                  <Stack
                    key={index}
                    spacing={3}
                    me={2}
                    borderWidth='1px'
                    borderColor='blue.400'
                    minW='300px'
                    borderRadius='lg'
                    p={3}
                    flex='0 0 auto'
                    direction='row'
                    align='center'>
                    <HStack>
                      <Stack
                        borderRadius='full'
                        alignItems={'center'}
                        justifyContent='center'
                        p={1}>
                        <HiOutlineUser size={'40px'} />
                      </Stack>
                      <Heading>{viewer.count}</Heading>
                    </HStack>
                    <Stack>
                      <Text>Age: {viewer.age}</Text>
                      <Text>Sex: {viewer.gander === 'F' ? 'Female' : viewer.gander === 'M' ? 'Male' : viewer.gander === 'U' ? 'Other' : viewer.gander}</Text>
                    </Stack>
                  </Stack>
                ))}
              </Flex>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default AnalyticsData;
