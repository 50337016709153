import { Avatar, Badge, Box, Button, Center, Flex, HStack, IconButton, Image, Select, SimpleGrid, Spacer, Stack, Text, useColorMode } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { FaBox, FaEye, FaPlay, FaTiktok } from 'react-icons/fa';
import moment from 'moment';
import PropTypes from 'prop-types';
import { NumberAcronym } from '../../Utils/NumberUtils';

export const SavedCard = ({ dataHasil, handleLoadMore, handleOpenLink, usernameUrl }) => {
  const [showDetails, setShowDetails] = useState({});
  const { colorMode } = useColorMode();

  const toggleDetails = (index) => {
    setShowDetails({ ...showDetails, [index]: !showDetails[index] });
  };

  return (
    <Box>
      <SimpleGrid
        columns={6}
        spacing={4}
        p={5}
        my={5}
        borderRadius={'md'}
        // bgGradient='linear(to-r, gray.200, gray.500)'
        boxShadow='xl'>
        {dataHasil.map((x, i) => (
          <Stack
            key={i}
            p='3'
            borderRadius={'md'}
            // bg={'white'}
            shadow={'base'}
            border={'1px'}
            borderColor={'gray.50'}>
            {/* <HStack
              mt={2}
              ms={2}
              justifyContent={'space-between'}>
              <HStack spacing={1}>
                <Avatar
                  size='sm'
                  me={2}
                  src={x?.dynamic_cover}
                  name='Nama Account'
                />
                <a
                  href={usernameUrl(x?.shareUrl)}
                  target='_blank'
                  rel='noreferrer'>
                  <Text
                    fontWeight={500}
                    fontSize={20}>
                    Nama Account
                  </Text>
                  <Text fontSize={12}>Saved: {moment.unix(x?.create_time).fromNow()}</Text>
                </a>
              </HStack>
              <Spacer />
              <IconButton
                icon={<FaEye />}
                aria-label='View'
                variant='ghost'
                size='md'
                onClick={() => toggleDetails(i)}
              />
            </HStack> */}
            {/* <motion.div
              initial={{ rotateY: 0 }}
              animate={{ rotateY: showDetails[i] ? 360 : 0 }}
              exit={{ rotateY: 0 }}
              transition={{ duration: 0.5 }}>
              {showDetails[i] ? (
                <Stack h={400}>
                  <SimpleGrid
                    columns={2}
                    pos={'relative'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Box
                      align={'center'}
                      p={1}
                      border={'1px'}
                      borderColor={'gray.100'}
                      borderRadius={'md'}
                      margin={3}>
                      <Text>Comment</Text>
                      <Text fontWeight={'semibold'}>{NumberAcronym(x?.comment_count)}</Text>
                    </Box>
                    <Box
                      align={'center'}
                      p={1}
                      border={'1px'}
                      borderColor={'gray.100'}
                      borderRadius={'md'}
                      margin={3}>
                      <Text>Play</Text>
                      <Text fontWeight={'semibold'}>{NumberAcronym(x?.play_count)}</Text>
                    </Box>
                    <Box
                      align={'center'}
                      p={1}
                      border={'1px'}
                      borderColor={'gray.100'}
                      borderRadius={'md'}
                      margin={3}>
                      <Text>Like</Text>
                      <Text fontWeight={'semibold'}>{NumberAcronym(x?.digg_count)}</Text>
                    </Box>
                    <Box
                      align={'center'}
                      p={1}
                      border={'1px'}
                      borderColor={'gray.100'}
                      borderRadius={'md'}
                      margin={3}>
                      <Text>Download</Text>
                      <Text fontWeight={'semibold'}>{NumberAcronym(x?.download_count)}</Text>
                    </Box>
                    <Box
                      align={'center'}
                      p={1}
                      border={'1px'}
                      borderColor={'gray.100'}
                      borderRadius={'md'}
                      margin={3}>
                      <Text>Share</Text>
                      <Text fontWeight={'semibold'}>{NumberAcronym(x?.share_count)}</Text>
                    </Box>
                    <Box
                      align={'center'}
                      p={1}
                      border={'1px'}
                      borderColor={'gray.100'}
                      borderRadius={'md'}
                      margin={3}>
                      <Text>WA Share</Text>
                      <Text fontWeight={'semibold'}>{NumberAcronym(x?.whatsapp_share_count)}</Text>
                    </Box>
                  </SimpleGrid>
                  <Text fontWeight={'bold'}>Hastags</Text>
                  <Flex
                    overflowY='auto'
                    sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
                    {x.hashtags?.map((z, index) => (
                      <Badge
                        key={index}
                        colorScheme='blue'
                        m='1'
                        textColor='black'
                        borderRadius={'md'}
                        p='2'
                        bg='blue.200'
                        boxShadow='md'
                        _hover={{ bg: 'blue.300' }}
                        cursor='pointer'
                        transition='background-color 0.3s ease-in-out'>
                        {z}
                      </Badge>
                    ))}
                  </Flex>
                  <Text fontWeight={'bold'}>Video Description</Text>
                  <Text>{x?.desc}</Text>
                  <Text fontWeight={'bold'}>Video Music</Text>
                  <Text>{x?.music}</Text>
                </Stack>
              ) : ( */}
            <Box
              pos={'relative'}
              h={400}
              bg='blue'
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}>
              <Image
                _hover={{ opacity: 0.3 }}
                transition='opacity 0.2s ease-in-out'
                h='full'
                w='full'
                src={x?.video}
                alt={'video'}
                objectFit='cover'
              />
              <Center
                pos={'absolute'}
                w={'full'}
                top={0}
                opacity={0}
                h={400}
                bg={'black'}
                _hover={{ opacity: 0.8 }}
                transition='opacity 0.2s ease-in-out'>
                <Button
                  colorScheme={'gray'}
                  size={'md'}
                  borderRadius={'full'} // Membuat tombol menjadi bulat
                  onClick={() => handleOpenLink(x)}>
                  <FaPlay />
                </Button>
              </Center>
            </Box>
            {/* )}
            </motion.div> */}
            <Stack
              h={150}
              overflowY='auto'
              sx={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              <Text fontWeight='bold'>Video Description</Text>
              <Text>{x?.desc}</Text>
            </Stack>
          </Stack>
        ))}
      </SimpleGrid>
      <Center my={5}>
        <Button
          colorScheme='blue'
          onClick={handleLoadMore}>
          Load More
        </Button>
      </Center>
    </Box>
  );
};

SavedCard.propTypes = {
  dataHasil: PropTypes.any.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleOpenLink: PropTypes.func.isRequired,
  usernameUrl: PropTypes.func.isRequired,
};
