/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Text, ScaleFade } from '@chakra-ui/react';

function AlertConnection() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000); // Menghilangkan alert setelah 2 detik
    };
    const handleOffline = () => {
      setIsOnline(false);
      setShowAlert(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <ScaleFade in={showAlert} initialScale={0.9}>
      {isOnline ? (
        <Alert
          display={showAlert ? 'flex' : 'none'}
          status="success"
          h={['30px', '30px', '40px']}
          overflowX="auto"
        >
          <AlertIcon />
          <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
            Connection! 📶
          </AlertTitle>
          <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
            <Text>
              Koneksi tersambung.
            </Text>
          </AlertDescription>
        </Alert>
      ) : (
        <Alert
          status="error"
          h={['30px', '30px', '40px']}
          overflowX="auto"
        >
          <AlertIcon />
          <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
            Connection! 📢
          </AlertTitle>
          <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
            <Text>
              Koneksi internet tidak stabil. Mohon periksa kembali koneksi Anda.
            </Text>
          </AlertDescription>
        </Alert>
      )}
    </ScaleFade>
  );
}

export default AlertConnection;
