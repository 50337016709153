import { Box, Center, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CalendarPage from '../../Pages/Calendar/CalendarPage';
import DynamicButton from '../Buttons/DynamicButton';
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaPinterest, FaTelegram, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';
import useUserStore from '../../Hooks/Zustand/Store';
import _axios from '../../Api/AxiosBarrier';
import InstagramInput from '../Inputs/InstagramInput';
import ScheduleInput from '../Inputs/ScheduleInput';
import TiktokInput from '../Inputs/TiktokInput';
import FacebookInput from '../Inputs/FacebookInput';
import UploadDropboxCard from '../DropBox/UploadDropboxCard';
import YoutubeInput from '../Inputs/YoutubeInput';
import { addDocumentFirebase } from '../../Api/firebaseApi';

function QueueTab() {
  const [modalPublish, setModalPublish] = useState(false);
  const [shortenLinks, setShotenLinks] = useState(false);
  const [schedulePosting, setSchedulePosting] = useState();

  const [files, setFiles] = useState([]);
  const [platformActive, setPlatformActive] = useState([]);
  const [postTypes, setPostTypes] = useState('post');

  const toast = useToast();
  const globalState = useUserStore();

  const platformIcons = {
    twitter: FaTwitter,
    youtube: FaYoutube,
    tiktok: FaTiktok,
    instagram: FaInstagram,
    linkedin: FaLinkedin,
    telegram: FaTelegram,
    facebook: FaFacebook,
    google: FaGoogle,
    pinterest: FaPinterest,
  };

  const socialMediaKey = globalState?.socialData?.displayNames;

  const [data, setData] = useState({
    post: '',
    platforms: [''],
    shortenLinks: shortenLinks,
    profileKey: globalState?.socialKey,
  });
  // console.log(globalState?.socialKey, 'ini social key');
  console.log(data, 'ini data awal ');

  const handleOpenModal = () => {
    setModalPublish(true);
  };

  const handleCloseModal = () => {
    setModalPublish(false);
    setFiles([]);
  };

  const handleAddPlatform = (media) => {
    if (globalState?.socialData?.activeSocialAccounts?.filter((x) => x?.includes(media)).length > 0) {
      setPlatformActive([media]);
      if (!platformActive.includes(media)) {
        setPlatformActive([...platformActive, media]);
        setData({ ...data, platforms: [...platformActive, media] });
      } else {
        setPlatformActive(platformActive.filter((platform) => platform !== media));
        setData({
          ...data,
          platforms: platformActive.filter((platform) => platform !== media),
        });
      }
    } else {
      toast({
        title: 'Deoapp.com',
        description: `${media} need to enabled. please go to Social Accounts page to set up`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handlePublish = async () => {
    if (data.profileKey === null) {
      return toast({
        title: 'Deoapp.com',
        description: 'You need to set up social account',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }

    globalState.setIsLoading(true);

    try {
      if (files.length > 0) {
        const uploadPromises = files.map(async (x) => {
          if ((x.ratioWidth / x.ratioHeight < 0.56 || x.ratioWidth / x.ratioHeight > 0.62) && (data.instagramOptions?.reels === true || data.instagramOptions?.stories === true)) {
            toast({
              title: 'Deoapp.com',
              description: 'Media file aspect ratio should be 9:16',
              status: 'warning',
              position: 'top-right',
              isClosable: true,
            });
            return;
          }

          if (data.youtubeOptions?.short === true && (x.size > 30 || x.ratioWidth / x.ratioHeight < 0.56 || x.ratioWidth / x.ratioHeight > 0.62)) {
            toast({
              title: 'Deoapp.com',
              description: 'For short YouTube videos, the aspect ratio should be 9:16 and duration should be maximum 30 mb',
              status: 'warning',
              position: 'top-right',
              isClosable: true,
            });
            return;
          }

          return x.category === 'video' ? `${x.link}&type=.mp4` : `${x.link}`;
        });
        data.mediaUrls = (await Promise.all(uploadPromises)).filter((url) => url);
      }
      // console.log(data, 'ini data masuk ');
      // console.log(globalState?.socialKey, 'ini socialkey');

      // const res = await _axios.post('ayrshare-post', data);
      // console.log(res, 'ini res terjadi kesalahan');
      // if (res.data.status !== 'error') {
      const postData = {
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        dataPost: data,
        status: 'pending',
        filesId: globalState?.socialData?.id,
        assignee: globalState?.uid,
        approvedBy: '',
        approvedAt: '',
      };

      const collectionName = 'social-media-post';

      try {
        await addDocumentFirebase(collectionName, postData, globalState?.currentCompany);
        toast({
          title: 'Deoapp.com',
          description: 'Successfully added new document to Firebase',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      // } else {
      //   toast({
      //     title: 'Deoapp.com',
      //     description: 'terjadi kesalahan',
      //     status: 'error',
      //     position: 'top-right',
      //     isClosable: true,
      //   });
      // }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
      setFiles([]);
      setPlatformActive([]);
      setShotenLinks(false);
      setSchedulePosting('');
      handleCloseModal();
    }
  };

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      profileKey: globalState?.socialKey,
    }));
  }, [globalState?.socialKey]);

  return (
    <Stack>
      <HStack>
        <Text
          fontWeight={'bold'}
          fontSize='xl'>
          Publish
        </Text>
        <Spacer />
        {socialMediaKey && socialMediaKey.length > 0 ? (
          <DynamicButton
            variant={'solid'}
            action={'create'}
            title='Create Post'
            size={'sm'}
            onClick={handleOpenModal}
          />
        ) : null}
      </HStack>

      <CalendarPage />

      <Modal
        size={'3xl'}
        isOpen={modalPublish}
        onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'md'}>Create Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              alignItems='center'
              justifyContent={'center'}
              spacing={5}
              p={5}
              borderRadius={'md'}>
              <Stack>
                <Text
                  color={'gray.500'}
                  fontSize='sm'
                  fontWeight={'semibold'}>
                  Choose Platform To Post
                </Text>
              </Stack>
              <SimpleGrid
                gap={5}
                columns={[3, 6, 9]}>
                {Object.keys(platformIcons).map((platform, index) => (
                  <Center
                    key={index}
                    _hover={{
                      bg: 'gray.100',
                      transform: 'scale(1.02)',
                      transition: '0.3s',
                    }}
                    cursor={'pointer'}
                    border={'1px'}
                    borderColor={'gray.50'}
                    borderRadius={'md'}
                    p={1}
                    boxShadow={'sm'}
                    onClick={() => handleAddPlatform(platform)}>
                    {React.createElement(platformIcons[platform], {
                      size: 25,
                      cursor: 'pointer',
                      color: platformActive.includes(platform) ? 'green' : 'gray',
                    })}
                  </Center>
                ))}
              </SimpleGrid>
            </Stack>
            <Stack>
              {(platformActive.includes('instagram') || platformActive.includes('facebook')) && (
                <Stack>
                  <Text
                    fontSize={'sm'}
                    color='gray.500'>
                    Select Type Post
                  </Text>
                  <Select
                    size={'sm'}
                    placeholder='Select type post'
                    onChange={(e) => {
                      setPostTypes(e.target.value);
                    }}>
                    <option value='post'>Post</option>
                    <option value='reels'>Reels</option>
                    <option value='stories'>Stories</option>
                  </Select>
                </Stack>
              )}
              <Text
                fontSize={'sm'}
                color='gray.500'>
                Type Your Description Video
              </Text>
              <Textarea
                placeholder='Type Here ...'
                fontSize={'sm'}
                onChange={(e) => setData({ ...data, post: e.target.value })}
              />
              <UploadDropboxCard
                globalState={globalState}
                files={files}
                setFiles={setFiles}
              />
              {/* <MediaLargeComponents
                profileKeyData={globalState?.socialKey}
                setImageHeight={setImageHeight}
                setImageWidth={setImageWidth}
                setFiles={setFiles}
                files={files}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                data={data}
              /> */}

              {platformActive.includes('instagram') ? (
                <Box py={2}>
                  <InstagramInput
                    data={data}
                    setData={setData}
                    postTypes={postTypes}
                    setPostTypes={setPostTypes}
                  />
                </Box>
              ) : null}

              {platformActive.includes('tiktok') ? (
                <Box py={2}>
                  <TiktokInput
                    data={data}
                    setData={setData}
                  />
                </Box>
              ) : null}

              {platformActive.includes('youtube') ? (
                <Box py={2}>
                  <YoutubeInput
                    data={data}
                    setData={setData}
                    // postTypes={postTypes}
                  />
                </Box>
              ) : null}

              {platformActive.includes('facebook') ? (
                <Box py={2}>
                  <FacebookInput
                    data={data}
                    setData={setData}
                    postTypes={postTypes}
                    setPostTypes={setPostTypes}
                  />
                </Box>
              ) : null}

              <ScheduleInput
                setSchedulePosting={setSchedulePosting}
                schedulePosting={schedulePosting}
                setData={setData}
                data={data}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <DynamicButton
                action={'delete'}
                variant='solid'
                onClick={handleCloseModal}
                size='sm'
                title='Close'
              />
              <DynamicButton
                action={'create'}
                variant='solid'
                onClick={handlePublish}
                size='sm'
                title={'Publish'}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default QueueTab;
