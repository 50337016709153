/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

import { Container, Flex } from '@chakra-ui/react'
// import FooterComponent from "../Components/Footers/FooterComponent";
import HeaderComponent from '../Components/Header/HeaderComponents'
import SidebarComponentV3 from '../Components/Sidebar/SidebarComponentV3'
import themeConfig from '../Config/themeConfig'
import { useLocation } from 'react-router-dom'

function Layout ({ children }) {
  const contentWidth =
    themeConfig.contentWidth === 'full' ? 'full' : 'container.xl'

  const location = useLocation()

  const isFunnelEditPage = location.pathname.includes('/lp-builder')

  return (
    <>
      <Flex height="100vh" w={'full'}>
        {/* <SidebarComponentV2 layout={themeConfig.layout} /> */}

        {!isFunnelEditPage && (
          <SidebarComponentV3 layout={themeConfig.layout} />
        )}

        <Container maxW={'full'} overflowY={'scroll'} position='relative' >

          <HeaderComponent layout={themeConfig.layout} />

          <Container minH={'95vh'} maxW={contentWidth}>
            {children}
          </Container>

          {/* <FooterComponent /> */}
        </Container>
      </Flex>
    </>
  )
}

export default Layout
