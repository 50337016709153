import { Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import AnalyticsData from '../Analytics/AnalyticsData';
// import ChartsBarMatrics from '../Charts/ChartsBarMatrics';
import { FaLock } from 'react-icons/fa';

function OverviewTab() {
  const globalState = useUserStore();
  const toast = useToast();

  const [dataAnalytics, setDataAnalytics] = useState({});

  const handleAnalytics = () => {
    globalState.setIsLoading(true);
    // console.log(globalState?.analyticsData, 'ini overview analytics');
    const profileKey = globalState?.socialKey;
    if (profileKey) {
      const res = globalState?.analyticsData;
      
      // console.log(res, 'ini res di overvirw');
      if (res?.data?.status !== null) {
        // console.log(res?.data, 'ini obj');
        const obj = res?.data;
        const { ...analyticsData } = obj;
        const filteredData = {};
        for (const platform in analyticsData) {
          if (obj[platform]?.status === 'error') {
            let description = obj[platform]?.message;
            // Tambahkan kondisi khusus untuk LinkedIn
            if (platform === 'linkedin') {
              description = 'Your LinkedIn is not a company profile';
            }
            toast({
              title: 'Deoapp.com',
              description,
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          } else {
            filteredData[platform] = obj[platform];
          }
        }
        setDataAnalytics(filteredData);
      } else {
        toast({
          title: 'Deoapp.com',
          description: 'Unknown error occurred',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    } else {
      globalState.setIsLoading(false);
      toast({
        title: 'Deoapp.com',
        description: 'Please set up your social account',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }
    globalState.setIsLoading(false);
  };

  useEffect(() => {
    if (globalState?.socialData !== null && globalState?.analyticsData?.data !== undefined) {
      handleAnalytics();
    }

    return () => {};
  }, [globalState?.socialData, globalState?.analyticsData]);

  return (
    <Stack spacing={5}>
      {Object.keys(dataAnalytics).length === 0 ? (
        <Stack
          p={1}
          w='65vw'
          h='700px'
          position={'absolute'}
          zIndex={100}
          opacity={0.5}
          alignItems='center'
          justifyContent={'center'}
          bgColor='gray.300'>
          <FaLock size={50} />
          <Text
            fontSize={'xl'}
            fontWeight={500}>
            No Account
          </Text>
        </Stack>
      ) : (
        <>
          <Stack>
            {dataAnalytics &&
              Object.entries(dataAnalytics)
                .filter(([key]) => key !== 'status' && key !== 'code')
                .map(([key, value]) => (
                  <Stack key={key}>
                    <AnalyticsData
                      data={value?.analytics}
                      platform={key}
                    />
                  </Stack>
                ))}
          </Stack>

          {/* <Stack>
            <Text
              fontSize={'xl'}
              fontWeight='bold'
              color={'gray.600'}>
              Metrics insights for total followers
            </Text>
            <Stack>
              <ChartsBarMatrics />
            </Stack>
          </Stack> */}
        </>
      )}
    </Stack>
  );
}

export default OverviewTab;
