/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Avatar, AvatarBadge, Box, Grid, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, useToast } from '@chakra-ui/react';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import useUserStore from '../../Hooks/Zustand/Store';
import { getPlatformIcon } from '../../Utils/PlatformIcon';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { TbCalendarUser } from 'react-icons/tb';
import { getUserByUid } from '../../Utils/getUser';

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null); // State untuk menyimpan event yang dipilih
  const toast = useToast();
  const globalState = useUserStore();

  const socialMediaKey = globalState?.socialData?.displayNames;

  const fetchEvents = async () => {
    const conditions = [{ field: 'filesId', operator: '==', value: globalState?.socialData?.id }];

    const sortBy = { field: 'createdAt', direction: 'asc' };
    const limitValue = null;

    try {
      globalState.setIsLoading(true);
      const result = await getCollectionFirebase('social-media-post', conditions, sortBy, limitValue);
      const data = result;
      console.log(data, 'ini data Calendar User');
      setEvents(
        data?.map((item) => ({
          title: item?.dataPost?.post,
          start: moment(item?.dataPost?.scheduleDate).toDate(),
          end: moment(item?.dataPost?.scheduleDate).toDate(),
          imageUrl: item?.dataPost?.mediaUrls || 'No Media',
          platform: item?.dataPost?.platforms,
          post: item?.dataPost?.post,
          status: item?.status,
          approvedBy: item?.approvedBy || ' not yet approved',
          requestedBy: item?.createdBy || '',
        }))
      );
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };
  // const fetchEvents = async () => {
  //   try {
  //     const result = await getSingleDocumentFirebase('schedules', globalState.currentProject);
  //     const data = result?.data;
  //     console.log(data, 'ini data Calendar User');
  //     setEvents(
  //       data?.map((item) => ({
  //         title: item?.dataPost,
  //         start: moment(item?.startDate?.seconds * 1000).toDate(),
  //         end: moment(item?.endDate?.seconds * 1000).toDate(),
  //         imageUrl: item?.image,
  //         platform: item?.platform,
  //         post: item?.post,
  //         status: item?.status,
  //       }))
  //     );
  //   } catch (error) {
  //     toast({
  //       title: 'Deoapp.com',
  //       description: error.message,
  //       status: 'error',
  //       position: 'top-right',
  //       isClosable: true,
  //     });
  //   }
  // };
  console.log(events, 'ini events');

  const handleMonthChange = (date) => {
    setCurrentDate(date);
  };

  useEffect(() => {
    if (socialMediaKey && socialMediaKey.length > 0) {
      fetchEvents();
    }
  }, [socialMediaKey, globalState?.currentProject, globalState?.currentCompany]);

  const eventStyleGetter = (event) => {
    const backgroundColor = '#3174ad';
    let textColor = 'white';

    switch (event.status) {
    case 'requested':
      textColor = 'yellow';
      break;
    case 'posted':
      textColor = 'green';
      break;
    case 'rejected':
      textColor = 'red';
      break;
    case 'pending':
      textColor = 'yellow';
      break;
    default:
      textColor = '#3174ad';
    }

    const style = {
      backgroundColor,
      textColor,
      borderRadius: '20px',
      opacity: 0.8,
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  const Event = ({ event }) => {
    const resIcon = getPlatformIcon(event?.platform);

    const handleClick = () => {
      setSelectedEvent(event);
      console.log(event, 'ini event');
    };

    return (
      <HStack
        alignItems={'center'}
        p={1}
        spacing={3}
        onClick={handleClick}
        cursor='pointer'>
        <Avatar
          cursor={'pointer'}
          size='xs'
          src={getUserByUid(globalState?.users, event?.requestedBy)?.image || 'none'}
          alt={getUserByUid(globalState?.users, event?.requestedBy)?.image || 'none'}>
          <AvatarBadge boxSize='1.5em'>{resIcon && resIcon}</AvatarBadge>
        </Avatar>

        <Stack spacing={0}>
          <HStack>
            {event?.platform &&
              event?.platform.map((platform, index) => (
                <HStack key={index}>
                  <Text
                    noOfLines={1}
                    fontSize={'xx-small'}>
                    {platform}
                  </Text>
                  {event?.status && (
                    <Text
                      noOfLines={1}
                      fontSize={'xx-small'}
                      color='gray.300'>
                      ( {event?.status} )
                    </Text>
                  )}
                </HStack>
              ))}
            {event?.status && (
              <Text
                noOfLines={1}
                fontSize={'xx-small'}
                color='gray.300'>
                ( {event?.status} )
              </Text>
            )}
          </HStack>
          <Text
            noOfLines={1}
            fontSize={'xx-small'}>
            {event?.post}
          </Text>
        </Stack>
      </HStack>
    );
  };

  return (
    <Stack>
      <Stack
        w={'100%'}
        transition={'0.2s ease-in-out'}
        minH={'100vh'}>
        <HStack
          justifyContent='center'
          alignItems='center'
          spacing={5}>
          <Box
            onClick={() => handleMonthChange(moment(currentDate || 1).subtract(1, 'months'))}
            cursor='pointer'>
            <MdNavigateBefore />
          </Box>
          <Text
            fontSize={'lg'}
            fontWeight={500}>
            {moment(currentDate || 1).format('MMMM YYYY')}
          </Text>
          <Box
            onClick={() => handleMonthChange(moment(currentDate || 1).add(1, 'months'))}
            cursor='pointer'>
            <MdNavigateNext />
          </Box>
        </HStack>

        {currentDate && (
          <Calendar
            localizer={localizer}
            events={events || []}
            startAccessor='start'
            endAccessor='end'
            style={{
              height: 'calc(100vh - 50px)',
              padding: 10,
              borderRadius: '10px',
            }}
            eventPropGetter={eventStyleGetter}
            components={{
              event: Event,
            }}
            date={currentDate}
          />
        )}
      </Stack>

      {/* Modal */}
      <Modal
        isOpen={selectedEvent !== null}
        onClose={() => setSelectedEvent(null)}
        centerContent>
        <ModalOverlay />
        <ModalContent
          borderRadius='md'
          boxShadow='lg'
          padding={8}>
          <ModalHeader
            fontSize='xl'
            fontWeight='bold'
            textAlign='center'>
            Your Post Information
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gap={4}>
              <Stack spacing={4}>
                <Box
                  border='1px solid #CBD5E0'
                  borderRadius='md'
                  padding={3}>
                  <Text
                    fontWeight='bold'
                    fontSize='lg'>
                    Posting Status
                  </Text>
                  <Text fontSize='lg'>{selectedEvent?.status}</Text>
                </Box>
                <Box
                  border='1px solid #CBD5E0'
                  borderRadius='md'
                  padding={3}>
                  <Text
                    fontWeight='bold'
                    fontSize='lg'>
                    Platform :
                  </Text>
                  {selectedEvent?.platform &&
                    selectedEvent?.platform.map((platform, index) => (
                      <HStack key={index}>
                        <Text fontSize='lg'>{platform}</Text>
                      </HStack>
                    ))}
                </Box>
                <Box
                  border='1px solid #CBD5E0'
                  borderRadius='md'
                  padding={3}>
                  <Text
                    fontWeight='bold'
                    fontSize='lg'>
                    Your Post :
                  </Text>
                  <Text fontSize='lg'>{selectedEvent?.post}</Text>
                </Box>
                <Box
                  border='1px solid #CBD5E0'
                  borderRadius='md'
                  padding={3}>
                  <Text
                    fontWeight='bold'
                    fontSize='lg'>
                    Approved By :
                  </Text>
                  <Text fontSize='lg'>{getUserByUid(globalState?.users, selectedEvent?.approvedBy)?.name || 'none'}</Text>
                </Box>
                <Box
                  border='1px solid #CBD5E0'
                  borderRadius='md'
                  padding={3}>
                  <Text
                    fontWeight='bold'
                    fontSize='lg'>
                    Requested By :
                  </Text>
                  <Text fontSize='lg'>{getUserByUid(globalState?.users, selectedEvent?.requestedBy)?.name || 'none'}</Text>
                </Box>
              </Stack>
              {Array.isArray(selectedEvent?.imageUrl) &&
                selectedEvent?.imageUrl?.length > 0 &&
                selectedEvent?.imageUrl?.map((y, index) => {
                  return (
                    <Stack key={index}>
                      {typeof y === 'string' && (y.includes('.MOV') || y.includes('.mp4') || y.includes('.mov')) ? (
                        <video
                          controls
                          width='220'
                          height='140'>
                          <source src={y} />
                          Sorry, your browser doesn't support embedded videos.
                        </video>
                      ) : (
                        typeof y === 'string' && (
                          <Image
                            borderRadius={'lg'}
                            w={'150px'}
                            shadow={'md'}
                            src={y}
                            alt={y}
                          />
                        )
                      )}
                    </Stack>
                  );
                })}
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default CalendarPage;
