import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Input, InputGroup, InputLeftElement, IconButton } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

function SearchBar({ comments, setFilteredComments }) {
  const [query, setQuery] = useState('');
  // console.log(comments, 'ini comment di dalam search bar');

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    if (query.trim() === '') {
      setFilteredComments(comments);
    } else {
      const filtered = comments.filter((comment) => {
        if (comment.platform === 'youtube') {
          return comment.comment.toLowerCase().includes(query.toLowerCase()) || comment.userName.toLowerCase().includes(query.toLowerCase());
        } else if (comment.platform === 'instagram ') {
          return comment.comment.toLowerCase().includes(query.toLowerCase()) || comment.username.toLowerCase().includes(query.toLowerCase());
        } else {
          return comment.comment.toLowerCase().includes(query.toLowerCase()) || comment.username.toLowerCase().includes(query.toLowerCase());
        }
      });
      setFilteredComments(filtered);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [query, comments]);

  return (
    <Box
      width='100%'
      maxWidth='400px'>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon color='gray.300' />
        </InputLeftElement>
        <Input
          type='text'
          placeholder='Search...'
          value={query}
          onChange={handleInputChange}
        />
        <IconButton
          aria-label='Search database'
          icon={<SearchIcon />}
          onClick={handleSearch}
        />
      </InputGroup>
    </Box>
  );
}

SearchBar.propTypes = {
  comments: PropTypes.array.isRequired,
  setFilteredComments: PropTypes.func.isRequired,
};

export default SearchBar;
