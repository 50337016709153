import React, { useEffect, useState } from 'react';
import { Box, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react';
import { addDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase } from '../../../Api/firebaseApi';
import BackButtons from '../../../Components/Buttons/BackButtons';
import { clientTypessense } from '../../../Api/Typesense';

import useUserStore from '../../../Hooks/Zustand/Store';
// import { SavedCard } from '../../Discovery/SavedContentCard';
import SearchBar from '../ResearchFYP/SearchBarFYP';
import { MediaCard } from './MediaVideo';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import UploadDropboxCard from '../../../Components/DropBox/UploadDropboxCard';

function SocialMediaStrategistMedia() {
  const [data, setData] = useState();
  const itemsPerPage = 30;
  const [startIndex, setStartIndex] = useState(0);
  const [inputSearch, setInputSearch] = useState('');
  const [sortingBy, setSortingBy] = useState('create_time');
  const globalState = useUserStore();
  const [modalPublish, setModalPublish] = useState(false);
  const [files, setFiles] = useState([]);
  const [shortenLinks, setShotenLinks] = useState(false);
  const toast = useToast();
  const [description, setDescription] = useState();
  const [linkVideo, setLinkVideo] = useState();

  console.log(linkVideo, 'ini link video');

  const handlePublish = async () => {
    if (files?.length > 0) {
      const uploadPromises = files.map(async (x) => {
        return x.category === 'video' ? `${x.link}&type=.mp4` : `${x.link}`;
      });
      const videoLinks = (await Promise.all(uploadPromises)).filter((url) => url);
      setLinkVideo(videoLinks);
    }

    globalState.setIsLoading(true);

    try {
      const postData = {
        companyId: globalState?.currentCompany,
        createdAt: new Date(),
        createdBy: globalState.uid,
        description: description,
        lastUpdated: new Date(),
        video: linkVideo,
      };

      console.log(postData, 'ini post data');

      const collectionName = 'content_strategies';

      try {
        await addDocumentFirebase(collectionName, postData, globalState?.currentCompany);
        toast({
          title: 'Deoapp.com',
          description: 'Successfully added new document to Firebase',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: 'Uploading video is not ready, try again in few second',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
      setFiles([]);
      handleCloseModal();
    }
  };

  //   console.log(globalState, 'ini global state');

  const usernameUrl = (link) => {
    const regex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@([^/]+)/i;
    const match = link.match(regex);

    if (match && match[3]) {
      return `https://www.tiktok.com/@${match[3]}`;
    } else {
      return null;
    }
  };
  console.log(globalState?.currentCompany, 'ini current company');
  const isCorrectCompany = globalState?.currentCompany === '8NCG4Qw0xVbNR6JCcJw1';

  const sortingBySearch = (e) => {
    setSortingBy(e.target.value);
  };

  const getDataFyp = async () => {
    globalState.setIsLoading(true);
    try {
      const conditions = [];

      let res = [];

      res = await getCollectionFirebase('content_strategies', conditions, startIndex);
      console.log(res, 'ini res nya mas');

      setData(res);
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      throw new Error(error, 'An error occured');
    }
  };

  const handleTypesenseSearch = (q) => {
    try {
      const searchParameters = {
        q: q,
        query_by: 'desc',
        filter_by: '',
        per_page: 30,
      };

      if (q) {
        globalState.setIsLoading(true);
        clientTypessense
          .collections('content_strategies')
          .documents()
          .search(searchParameters)
          .then((x) => {
            const newData = x.hits.map((y) => {
              return { ...y.document };
            });

            const arrContentId = newData.map((z) => {
              return z.id;
            });

            setInputSearch(q);
            console.log(arrContentId, 'ini content id');

            Promise.all(
              arrContentId.map(async (id) => {
                try {
                  return await getSingleDocumentFirebase('content_strategies', id);
                } catch (error) {
                  console.log(error.message);
                  return null;
                }
              })
            ).then((results) => {
              const filteredResults = results.filter((result) => result !== null);

              const mergedResult = [].concat.apply([], filteredResults);

              //   console.log(mergedResult);

              setData(mergedResult);
            });
          })
          .catch((error) => {
            console.log(error, 'errorrr');
          });
      } else {
        setInputSearch(q);
        // getDataFyp();
      }
    } catch (error) {
      console.log(error.message);
    }
    globalState.setIsLoading(false);
  };

  const handleOpenLink = (value) => {
    window.open(value?.shareUrl, '_blank');
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const handleOpenModal = () => {
    setModalPublish(true);
  };

  const handleCloseModal = () => {
    setModalPublish(false);
    setFiles([]);
  };

  //   console.log(inputSearch, 'ini input search');

  useEffect(() => {
    if (inputSearch === '') {
      getDataFyp();
    }
  }, [startIndex, inputSearch, sortingBy]);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading ml={3}>Media Strategist</Heading>
          <Spacer />
          {isCorrectCompany && (
            <DynamicButton
              variant={'solid'}
              action={'create'}
              title='Post Video'
              size={'sm'}
              onClick={handleOpenModal}
            />
          )}

          <SearchBar
            data={data}
            handleSearch={handleTypesenseSearch}
            sortBySearch={sortingBySearch}
            sortBy={sortingBy}
          />
        </HStack>
        {data ? (
          <MediaCard
            dataHasil={data}
            handleOpenLink={handleOpenLink}
            handleLoadMore={handleLoadMore}
            usernameUrl={usernameUrl}
          />
        ) : (
          <Text>Loading...</Text>
        )}
      </Box>
      <Modal
        size={'3xl'}
        isOpen={modalPublish}
        onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'md'}>Create Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Text
                fontSize={'sm'}
                color='gray.500'>
                Type Your Description Video
              </Text>
              <Textarea
                placeholder='Type Here ...'
                fontSize={'sm'}
                onChange={(e) => setDescription(e.target.value)}
              />
              <UploadDropboxCard
                globalState={globalState}
                files={files}
                setFiles={setFiles}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <DynamicButton
                action={'delete'}
                variant='solid'
                onClick={handleCloseModal}
                size='sm'
                title='Close'
              />
              <DynamicButton
                action={'create'}
                variant='solid'
                onClick={handlePublish}
                size='sm'
                title={'Publish'}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SocialMediaStrategistMedia;
