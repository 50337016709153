import { Box, Container, Divider, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import SocialAccountComponents from '../../Components/Social/SocialAccountComponents';
import ApprovedTab from '../../Components/SocialMediaTab/ApprovedTab';
import HistoryTab from '../../Components/SocialMediaTab/HistoryTab';
import QueueTab from '../../Components/SocialMediaTab/QueueTab';
import SentTab from '../../Components/SocialMediaTab/SentTab';
import StoriesTab from '../../Components/SocialMediaTab/StoriesTab';
import MediaTab from '../../Components/SocialMediaTab/MediaTab';

function SocialPublishPage() {
  const [navigateState, setNavigateState] = useState('queue');

  const handleNavigate = (param) => {
    setNavigateState(param);
  };

  const height = window.innerHeight;

  return (
    <Flex
      direction={['column', 'row', 'row']}
      gap={3}>
      <Stack
        w='300px'
        height='auto'
        overflowY='auto'
        borderWidth='1px'
        position='sticky'
        top={0}
        borderRadius='md'
        shadow={'md'}
        h={height}
        p={[1, 1, 5]}>
        <SocialAccountComponents />
      </Stack>

      <Container maxW='100%'>
        <Stack
          overflowY='auto'
          spacing={5}
          w='full'>
          <BackButtons />
          <HStack spacing={5}>
            <Box
              cursor={'pointer'}
              borderBottomWidth={1}
              borderColor={navigateState === 'media' ? 'blue.400' : 'transparent'}
              onClick={() => handleNavigate('media')}>
              <Text
                fontSize={'sm'}
                fontWeight='bold'
                color={navigateState === 'media' ? 'blue.400' : 'gray.600'}>
                Media
              </Text>
            </Box>
            <Box
              cursor={'pointer'}
              borderBottomWidth={1}
              borderColor={navigateState === 'queue' ? 'blue.400' : 'transparent'}
              onClick={() => handleNavigate('queue')}>
              <Text
                fontSize={'sm'}
                fontWeight='bold'
                color={navigateState === 'queue' ? 'blue.400' : 'gray.600'}>
                Queue
              </Text>
            </Box>
            <Box
              cursor={'pointer'}
              borderBottomWidth={1}
              borderColor={navigateState === 'stories' ? 'blue.400' : 'transparent'}
              onClick={() => handleNavigate('stories')}>
              <Text
                fontSize={'sm'}
                fontWeight='bold'
                color={navigateState === 'stories' ? 'blue.400' : 'gray.600'}>
                Stories
              </Text>
            </Box>
            <Box
              cursor={'pointer'}
              borderBottomWidth={1}
              borderColor={navigateState === 'posting' ? 'blue.400' : 'transparent'}
              onClick={() => handleNavigate('posting')}>
              <Text
                fontSize={'sm'}
                fontWeight='bold'
                color={navigateState === 'posting' ? 'blue.400' : 'gray.600'}>
                Posting
              </Text>
            </Box>
            <Box
              cursor={'pointer'}
              borderBottomWidth={1}
              borderColor={navigateState === 'approvals' ? 'blue.400' : 'transparent'}
              onClick={() => handleNavigate('approvals')}>
              <Text
                fontSize={'sm'}
                fontWeight='bold'
                color={navigateState === 'approvals' ? 'blue.400' : 'gray.600'}>
                Approvals
              </Text>
            </Box>

            <Box
              cursor={'pointer'}
              borderBottomWidth={1}
              borderColor={navigateState === 'history' ? 'blue.400' : 'transparent'}
              onClick={() => handleNavigate('history')}>
              <Text
                fontSize={'sm'}
                fontWeight='bold'
                color={navigateState === 'history' ? 'blue.400' : 'gray.600'}>
                History
              </Text>
            </Box>
          </HStack>

          <Divider />
          <Stack>
            {navigateState === 'queue' ? (
              <QueueTab />
            ) : navigateState === 'stories' ? (
              <StoriesTab />
            ) : navigateState === 'posting' ? (
              <SentTab />
            ) : navigateState === 'approvals' ? (
              <ApprovedTab />
            ) : navigateState === 'history' ? (
              <HistoryTab />
            ) : navigateState === 'media' ? (
              <MediaTab />
            ) : null}
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
}

export default SocialPublishPage;
