import React from 'react';
import SocialBuildPage from '../Pages/Posts/SocialBuildPage';
import CommentsPage from '../Pages/Comments/CommentsPage';
import ReportsPage from '../Pages/Reports/ReportsPage';
import CalendarPage from '../Pages/Calendar/CalendarPage';
import SocialAccountPage from '../Pages/SocialAccounts/SocialAccountPage';
import SocialPublishPage from '../Pages/Posts/SocialPublishPage';
import SocialAnalyticsPage from '../Pages/Posts/SocialAnalyticsPage';
import SocialEngagementPage from '../Pages/Posts/SocialEngagementPage';
import MediaTabDetail from '../Components/SocialMediaTab/MediaTabDetail';

const SocialmediaRouter = [
  {
    path: '/social-media/post',
    element: <SocialBuildPage />,
  },
  {
    path: '/social-media/publish',
    element: <SocialPublishPage />,
  },

  {
    path: '/social-media/publish/:view/:id/:title',
    element: <MediaTabDetail />,
  },

  {
    path: '/social-media/analytics',
    element: <SocialAnalyticsPage />,
  },
  {
    path: '/social-media/comments',
    element: <CommentsPage />,
  },

  {
    path: '/social-media/engagement',
    element: <SocialEngagementPage />,
  },

  {
    path: '/social-media/reports',
    element: <ReportsPage />,
  },

  {
    path: '/social-media/schedule',
    element: <CalendarPage />,
  },
  {
    path: '/social-media/account',
    element: <SocialAccountPage />,
  },
];

export default SocialmediaRouter;
