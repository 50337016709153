/* eslint-disable react/prop-types */
import { Checkbox,Input,Stack,Text } from '@chakra-ui/react';
import React,{ useEffect } from 'react'

function FacebookInput({ data,setData,postTypes }) {

  useEffect(() => {
    if (postTypes === 'reels') {
      setData({ ...data,facebookOptions: { ...data.facebookOptions,reels: true } })
    } else {
      setData({ ...data,facebookOptions: { ...data.facebookOptions,reels: false } })
    }

    if (postTypes === 'stories') {
      setData({ ...data,facebookOptions: { ...data.facebookOptions,stories: true } })
    } else {
      setData({ ...data,facebookOptions: { ...data.facebookOptions,stories: false } })
    }


    return () => {

    }
  },[postTypes])

  return (
    <Stack>
      <Text
        fontSize={'sm'}
        color="gray.500"
        fontWeight={'semibold'}
      >
                Details Post for Facebook
      </Text>


      <Stack>
        {postTypes === 'reels' ? (
          <>
            <Checkbox
              colorScheme="blue"
              onChange={(e) =>
                setData({
                  ...data,
                  facebookOptions: {
                    ...data.facebookOptions,
                    reels: e.target.checked,
                  },
                })
              }
            >
              <Text fontSize={'sm'}>Reels Content</Text>
            </Checkbox>

            <Text>Title (optional)</Text>
            <Input
              size={'sm'}
              // defaultValue={30000}
              onChange={(e) =>
                setData({
                  ...data,
                  facebookOptions: {
                    ...data.facebookOptions,
                    title: e.target.value,
                  },
                })
              }
            />
          </>
        ) : null}

        {postTypes === 'stories' ? (
          <>
            <Checkbox
              colorScheme="blue"
              onChange={(e) =>
                setData({
                  ...data,
                  facebookOptions: {
                    ...data.facebookOptions,
                    stories: e.target.checked,
                  },
                })
              }
            >
              <Text fontSize={'sm'}>Stories Content</Text>
            </Checkbox>
          </>
        ) : null}

        {/* <Stack>
                    <Text>Media Captions</Text>
                    <Stack>
                        <Input />
                    </Stack>
                </Stack> */}
      </Stack>
    </Stack>
  )
}

export default FacebookInput