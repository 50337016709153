import { Avatar, Badge, Box, Button, Center, Flex, HStack, IconButton, Image, Select, SimpleGrid, Spacer, Stack, Text, useColorMode } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { FaBox, FaEye, FaPlay, FaTiktok } from 'react-icons/fa';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

export const MediaCard = ({ dataHasil, handleLoadMore, handleOpenLink, usernameUrl }) => {
  const [showDetails, setShowDetails] = useState({});
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const toggleDetails = (index) => {
    setShowDetails({ ...showDetails, [index]: !showDetails[index] });
  };
  const handleNavigate = (x) => {
    if (x?.id) {
      navigate(`/social-media/strategist/saved/${x.id}`);
    }
  };

  //   console.log(dataHasil, 'ini data hasil nyaa ');

  return (
    <Box>
      <SimpleGrid
        columns={{ base: 3, md: 4, lg: 5 }}
        spacing={4}
        p={5}
        my={5}
        borderRadius={'md'}
        boxShadow='xl'>
        {dataHasil.map((x, i) => (
          <Stack
            p='3'
            key={i}
            borderRadius='md'
            shadow='base'
            onClick={() => handleNavigate(x)}
            border='1px'
            cursor='pointer'
            borderColor='gray.200'
            _hover={{ shadow: 'md', borderColor: 'gray.300' }}
            transition='all 0.2s ease-in-out'
            // Pastikan stack dalam orientasi kolom
          >
            {/* Bagian video */}
            <Box
              position='relative'
              display='flex'
              justifyContent='center'
              alignItems='center'
              height={['150px', '250px', '300px']} // Tinggi berbeda untuk ukuran layar berbeda
            >
              <video
                style={{ width: '100%', height: '100%', borderRadius: 'md' }}
                controls
                preload='auto'>
                <source
                  src={x?.video}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </Box>

            {/* Bagian deskripsi */}
            <Stack
              overflowY='auto'
              padding='10px'
              spacing='3'
              sx={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              <Text fontWeight='bold'>Video Description</Text>
              <Text>{x?.description}</Text>
            </Stack>
          </Stack>
        ))}
      </SimpleGrid>
      <Center my={5}>
        <Button
          colorScheme='blue'
          onClick={handleLoadMore}>
          Load More
        </Button>
      </Center>
    </Box>
  );
};

MediaCard.propTypes = {
  dataHasil: PropTypes.any.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleOpenLink: PropTypes.func.isRequired,
  usernameUrl: PropTypes.func.isRequired,
};
