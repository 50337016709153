/* eslint-disable react/react-in-jsx-scope */
import {
  AspectRatio,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import {
  addDocumentFirebase,
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  FcAudioFile,
  FcBinoculars,
  FcFile,
  FcImageFile,
  FcShare,
  FcVideoFile,
} from 'react-icons/fc';
import { HiOutlineFolderOpen } from 'react-icons/hi';
import { FiCopy, FiDownload } from 'react-icons/fi';
import DropboxUploaderAsset from '../../Components/DropBox/DropboxUploaderAsset';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import DynamicButton from '../Buttons/DynamicButton';
import LiveTime from '../Timer/LiveTime';
import { formatFrice } from '../../Utils/numberUtil';
    
function MediaTabDetail() {
  const param = useParams();
  const globalState = useUserStore();

  const [userDisplay, setUserDisplay] = useState([])
  const [dataDoc, setDataDoc] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure();

    
  const [typeActive, setTypeActive] = useState('audio');
  const [dataFile, setDataFile] = useState(null);
  const [modalUpload, setModalUpload] = useState(false);
  const [path, setPath] = useState('');
  const [dataNew, setDataNew] = useState({});
    
  const [modalView, setModalView] = useState(false);
  const [fileView, setFileView] = useState('');
  const [fileCategory, setFileCategory] = useState('');
    
  const fileInputRef = useRef();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [filePreview, setFilePreview] = useState(null);
  const [shareLink, setShareLink] = useState('');
    
    
  const [searchResult, setSearchResult] = useState('');
    
  const dataType = ['audio', 'image', 'video', 'file'];
    
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 6;
    
  const toast = useToast();
    
  const currentMillis = moment(new Date()).valueOf();

  const getFileSizeInMB = (file) => {
    const fileSizeInBytes = file.size;
    // Convert bytes to megabytes
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB;
  };

  const getVideoDimensions = async (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        resolve({
          width: video.videoWidth,
          height: video.videoHeight
        });
      };
      video.onerror = reject;
      video.src = URL.createObjectURL(file);
    });
  };
    
  const uploadFileToDropbox = async (file) => {
    const fileType = file.type.split('/')[0];
    const fileTypeFix =
          fileType === 'image'
            ? 'image'
            : fileType === 'video'
              ? 'video'
              : fileType === 'audio'
                ? 'audio'
                : 'file';
    
    // const accessToken = process.env.REACT_APP_DROPBOX;
    const accessToken = globalState?.accessToken;
    const url = 'https://content.dropboxapi.com/2/files/upload';
    
    const headers = {
      'Content-Type': 'application/octet-stream',
      Authorization: `Bearer ${accessToken}`,
      'Dropbox-API-Arg': JSON.stringify({
        path: `/${globalState.currentCompany}/social-media/${fileTypeFix}/${currentMillis}-${file?.name}`,
        mode: 'add',
        autorename: true,
        mute: false,
      }),
    };

    
    setPath(`/${globalState.currentCompany}/social-media/${fileTypeFix}/${file?.name}`);

    const fileSize = getFileSizeInMB(file); // Function to get file size

    let ratioWidth = 0
    let ratioHeight = 0
    if (fileTypeFix === 'image') {
      const img = document.createElement('img');
      img.src = URL.createObjectURL(file);
      await new Promise((resolve) => { img.onload = resolve; }); // Menunggu gambar selesai dimuat
      ratioWidth = img.naturalWidth;
      ratioHeight = img.naturalHeight;
    } else if (fileTypeFix === 'video') {
      const dimensions = await getVideoDimensions(file);
      ratioWidth = dimensions.width;
      ratioHeight = dimensions.height;
    }
    


    
    try {
      const response = await axios.post(url, file, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });
    
      if (response?.data?.path_lower) {
        createShareLink(response.data.path_lower, fileTypeFix, accessToken, fileSize, ratioWidth, ratioHeight);
      }
    } catch (error) {
      toast({
        title: 'oops!',
        description: error.message,
        isClosable: true,
        duration: 9000,
        status: 'error',
      });
    }
  };
    
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Tampilkan pratinjau file saat dipilih
      displayFilePreview(file);
    }
  };
    
  const handleSaveButtonClick = () => {
    const file = fileInputRef.current?.files[0];
    if (file) {
      // Upload file ke Dropbox ketika tombol "Save" diklik
      uploadFileToDropbox(file);
    }
  };
    
  const createShareLink = async (filePath, category, token, fileSize, ratioWidth, ratioHeight) => {
    // const accessToken = process.env.REACT_APP_DROPBOX;
    const accessToken = token;
    const url =
          'https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings';
    
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    
    const requestData = {
      path: filePath,
    };
    
    try {
      const response = await axios.post(url, requestData, {
        headers: headers,
      });
    
      const urlData = response?.data?.url;
    
      const dataFix = urlData.includes('.mov') || urlData.includes('.MOV');
    
      const urlRaw = dataFix ? urlData : `${urlData}&raw=1`;
    
      setShareLink(urlRaw);
    
      if (urlRaw) {
        const collectionName = 'media';
        const dataNewUpdate = {
          ...dataNew,
          path: filePath,
          link: urlRaw,
          type: 'socialmedia',
          category: category,
          projectId: globalState?.currentProject,
          companyId: globalState?.currentCompany,
          size: Math.round(fileSize || 0),
          filesId: param.id,
          ratioWidth: ratioWidth || 0,
          ratioHeight: ratioHeight || 0
        };
    
        try {
          const docID = await addDocumentFirebase(
            collectionName,
            dataNewUpdate,
            globalState.currentCompany
          );
          toast({
            title: 'Deoapp',
            description: `Success add File ${docID}.`,
            status: 'success',
            isClosable: true,
            duration: 9000,
          });
          fetchData();
        } catch (error) {
          toast({
            title: 'oops!',
            description: error.message,
            isClosable: true,
            duration: 9000,
            status: 'error',
          });
        }
      }
    } catch (error) {
      toast({
        title: 'oops!',
        description: error.message,
        isClosable: true,
        duration: 9000,
        status: 'error'
      });
    }
  };
    
  const displayFilePreview = (file) => {
    const fileUrl = URL.createObjectURL(file);
    const fileType = file.type.split('/')[0];
    
    switch (fileType) {
    case 'image':
      setFilePreview(
        <img src={fileUrl} alt="File Preview" style={{ width: '300px' }} />
      );
      break;
    case 'video':
      setFilePreview(
        <video src={fileUrl} controls style={{ width: '300px' }}>
                Your browser does not support the video tag.
        </video>
      );
      break;
    case 'audio':
      setFilePreview(
        <audio src={fileUrl} controls style={{ width: '300px' }}>
                Your browser does not support the audio element.
        </audio>
      );
      break;
    case 'application':
      // Jika tipe datanya adalah PDF, gunakan elemen iframe
      if (file.type === 'application/pdf') {
        setFilePreview(
          <iframe
            src={fileUrl}
            title="File Preview"
            width="300"
            height="200"
          ></iframe>
        );
      } else {
        setFilePreview(null); // Kosongkan pratinjau untuk tipe datanya yang tidak didukung
      }
      break;
    default:
      setFilePreview(null); // Kosongkan pratinjau untuk tipe datanya yang tidak didukung
      break;
    }
  };
    
  const handleDoneButton = () => {
    // Mengatur kembali state menjadi nilai awal
    setFilePreview(null);
    setUploadProgress(0);
    setShareLink('');
    setPath('');
    setDataNew({});
    // Menghapus file yang dipilih dengan mengecek bahwa fileInputRef.current tidak null
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
    
  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Deoapp',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };
    
  const fetchData = async () => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
      { field: 'filesId', operator: '==', value: param.id },
      { field: 'type', operator: '==', value: 'socialmedia' },
      { field: 'category', operator: '==', value: typeActive },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    
    try {
      const res = await getCollectionFirebase(
        'media',
        conditions,
        sortBy,
        limitValue
      );
      setDataFile(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
    
  const handleFilter = (x) => {
    setTypeActive(x);
  };

  const getDataFile =async () => {
    try {
      const result = await getSingleDocumentFirebase('files', param.id)
      setDataDoc(result)
      setUserDisplay(result.usersDisplay)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }
    
  useEffect(() => {
    getDataFile()
    fetchData();
    
    return () => {
      setDataFile(null);
    };
  }, [typeActive, startIndex, globalState?.currentCompany]);
    
  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage); // Tambahkan jumlah data per halaman saat tombol "Load More" diklik
  };
    
  const handleCloseModalUpload = () => {
    // Reset state saat modal ditutup
    setFilePreview(null);
    setUploadProgress(0);
    setShareLink('');
    setPath('');
    setDataNew({});
    setModalUpload(false);
  };
    
  const handleOpenModal = (file) => {
    setFileCategory(file.category);
    setFileView(file.link);
    setModalView(true);
  };
    
  const handleCloseModal = () => {
    setModalView(false);
    setFileCategory('');
    
    setFileView('');
  };

    
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        new Error(error.message, 'Failed to send Slack login message');
      });
  };

    
  const handleAddUser = (param, x) => {
    try {
      arrayUnionFirebase('files', param.id, 'users', [x.document.id]);
      arrayUnionFirebase('files', param.id, 'usersDisplay', [
        { ...x.document },
      ]);
      toast({
        title: 'Deoapp.com',
        description: 'success add users',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setSearchResult([]);
    }
  };
  
  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Heading size={'md'} textTransform="capitalize">
              Media 
        </Heading>
        <Spacer />
        <HStack flexDirection={['column', 'row', 'row']} spacing={3}>
          <Stack
            alignItems={'flex-end'}
            justifyContent="flex-end"
            spacing={1}
          >
            <HStack>
              <AvatarGroup size="sm" gap="1" max={4}>
                {userDisplay ? (
                  userDisplay?.map((x, i) => (
                    <Avatar key={i} name={x?.name} />
                  ))
                ) : (
                  <></>
                )}
              </AvatarGroup>
              <DynamicButton
                size="sm"
                action='create'
                variant={'solid'}
                onClick={onOpen}
              />
            </HStack>

            <HStack>
              <DynamicButton
                title={'Edit Folder'}
                size="sm"
                variant="solid"
                action={'update'}
                
              />
        
            </HStack>
          </Stack>

    
          <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
            <LiveTime color={'white'} />
          </Stack>
        </HStack>
      </HStack>
      <Stack p={[1, 1, 5]}>
        {dataType.length > 0 && (
          <SimpleGrid columns={[1, 2, 4]} gap={5}>
            {dataType.map((x, index) => {
              const dataView =
                    x === 'image' ? (
                      <FcImageFile size={50} />
                    ) : x === 'audio' ? (
                      <FcAudioFile size={50} />
                    ) : x === 'video' ? (
                      <FcVideoFile size={50} />
                    ) : (
                      <FcFile size={50} />
                    );
              return (
                <Stack
                  borderRadius={'md'}
                  borderWidth={2}
                  shadow={'md'}
                  borderColor={x === typeActive ? '#ffd600' : 'blackAlpha.300'}
                  key={index}
                  cursor="pointer"
                  onClick={() => handleFilter(x)}
                >
                  <Stack
                    alignItems={'center'}
                    h="150px"
                    justifyContent="center"
                  >
                    <Box>{dataView}</Box>
                    <Text textTransform={'capitalize'}>{x}</Text>
                  </Stack>
                </Stack>
              );
            })}
          </SimpleGrid>
        )}
      </Stack>
      <Divider py={2} borderWidth="2" borderColor={'blackAlpha.500'} />
    
      <Stack spacing={5} p={[1, 1, 5]}>
        <Stack>
          <Box textAlign={'end'}>
            <Button
              onClick={() => setModalUpload(true)}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
                  Add File
            </Button>
          </Box>
          {dataFile?.length > 0 && (
            <SimpleGrid columns={[1, 2, 4]} gap={4}>
              {dataFile
                ?.slice(0, startIndex + itemsPerPage)
                .map((y, index) => {
                  const dataView =
                            y.category === 'image' ? (
                              <FcImageFile size={50} />
                            ) : y.category === 'audio' ? (
                              <FcAudioFile size={50} />
                            ) : y.category === 'video' ? (
                              <FcVideoFile size={50} />
                            ) : (
                              <FcFile size={50} />
                            );
    
                  return (
                    <Stack
                      overflow={'hidden'}
                      key={index}
                      borderRadius="md"
                      p={3}
                      borderColor="blackAlpha.300"
                      shadow={'md'}
                      borderWidth={1}
                    >
                      <HStack alignItems={'flex-start'}>
                        <Stack>{dataView}</Stack>
                        <Stack w="100%">
                          <Text
                            fontSize={'sm'}
                            fontWeight={500}
                            textTransform="capitalize"
                          >
                            {y?.title}
                          </Text>
                          <Text
                            fontSize={'xx-small'}
                            noOfLines={1}
                            w="100px"
                          >
                                Size : {y?.size} mb
                          </Text>
                          <Text
                            fontSize={'xx-small'}
                            noOfLines={1}
                            w="100px"
                          >
                                Path : {y?.path}
                          </Text>
                          <Text fontSize={'xx-small'} >
                                Category : {y?.category}
                          </Text>
                          <Spacer />
                          <HStack
                            alignItems="center"
                            justifyContent={'center'}
                          >
                            <Button
                              w={'100%'}
                              size={'xs'}
                              onClick={() => handleCopy(y.link)}
                            >
                              <FiCopy />
                            </Button>
                            <Button
                              w={'100%'}
                              size={'xs'}
                              onClick={() => window.open(y.link, '_blank')}
                            >
                              <HiOutlineFolderOpen />
                            </Button>
                            <Button
                              w={'100%'}
                              size={'xs'}
                              onClick={() => handleOpenModal(y)}
                            >
                              <FiDownload />
                            </Button>
                          </HStack>
                        </Stack>
                      </HStack>
                    </Stack>
                  );
                })}
            </SimpleGrid>
          )}
        </Stack>
    
        <Stack alignItems={'center'} justifyContent="center">
          <Box>
            {dataFile?.length > startIndex && (
              <Button colorScheme="blue" onClick={handleLoadMore} size={'sm'}>
                  Load more
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
      {/* Modal Upload */}
      <DropboxUploaderAsset
        modalOpen={modalUpload}
        onClose={handleCloseModalUpload}
        param={param}
        fileInputRef={fileInputRef}
        handleFileInputChange={handleFileInputChange}
        handleSaveButtonClick={handleSaveButtonClick}
        handleCopy={handleCopy}
        handleDoneButton={handleDoneButton}
        filePreview={filePreview}
        uploadProgress={uploadProgress}
        shareLink={shareLink}
        dataNew={dataNew}
        setDataNew={setDataNew}
        setFilePreview={setFilePreview}
        setUploadProgress={setUploadProgress}
        setShareLink={setShareLink}
        setPath={setPath}
        path={path}
        globalState={globalState}
      />
    
      <Modal isOpen={modalView} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {fileCategory === 'image' ? (
              <Stack>
                <Image src={fileView} width="300" alt={'image dropbox'} />
              </Stack>
            ) : (
              <AspectRatio maxW="560px" ratio={4 / 3}>
                <iframe
                  src={fileView}
                  title="File Preview"
                  width="300"
                  height="200"
                ></iframe>
              </AspectRatio>
            )}
          </ModalBody>
    
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                Close
            </Button>
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Add Users
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack m="1">
              <Input
                type="text"
                placeholder="Search users"
                onChange={(e) => handleSearchUsers(e.target.value)}
              />
            </HStack>
            {searchResult?.length > 0 ? (
              searchResult?.map((x, index) => {
                if (dataDoc?.users.find((z) => z !== x.document.id)) {
                  return (
                    <HStack key={index} p="2" borderBottom="1px">
                      <Avatar
                        name={x?.document?.name}
                        src={x?.document.image ? x.document.image : ''}
                      />
                      <Box>
                        <Text>{x?.document?.name}</Text>
                        <Text>{x?.document?.email}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleAddUser(param, x);
                        }}
                      >
                        +
                      </Button>
                    </HStack>
                  );
                }
              })
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

    </Stack>
  );
}
    
export default MediaTabDetail;
    