/* eslint-disable react/react-in-jsx-scope */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
  Center,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import { collection, query, where, getCountFromServer } from '@firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { FcFolder } from 'react-icons/fc';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import CardAvatar from '../../Components/Card/CardAvatar';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import CardMyKanban from '../Card/CardMyKanban';
  
function MediaTab() {
  const globalState = useUserStore();
  const toast = useToast();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [dataFolder, setDataFolder] = useState([]);
  const [modal, setModal] = useState({ delete: false, user: false });
  const [data, setData] = useState({});
  const [editor, setEditor] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filterOwner, setFilterOwner] = useState(false);

  
  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    let conditions = [
      { field: 'type', operator: '==', value: 'media' },
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      { field: 'users', operator: 'array-contains', value: globalState.uid },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    if (filterOwner === true) {
      conditions = [
        { field: 'type', operator: '==', value: 'media' },
        {
          field: 'companyId',
          operator: '==',
          value: globalState.currentCompany,
        },
      ];
    }

    globalState.setIsLoading(true);

    try {
      globalState.setIsLoading(true);
      const res = await getCollectionFirebase(
        'files',
        conditions,
        sortBy,
        limitValue
      );
      setDataFolder(res);

      const collectionRef = collection(db, 'files');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      let countQuery = query(
        collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('projectId', '==', globalState.currentProject),
        where('type', '==', 'media'),
        where('users', 'array-contains', globalState.uid)
      );
      if (filterOwner === true) {
        countQuery = query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
          where('type', '==', 'media')
        );
      }

      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleOpenFilter = () => {
    if (globalState.roleCompany !== 'owner') {
      toast({
        title: 'Alert',
        description: 'You Dont have any Access',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setFilterOwner((prevFilterOwner) => !prevFilterOwner);
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleNavigate = (x) => {
    navigate(`view/${x.id}/${x.title}`);

  };

  useEffect(() => {
    getData();
  }, [globalState?.currentProject, filterOwner, currentPage]);

  const modalTeam = (team) => {
    // setSelectedFlowchart(x)
    setData(team);

    const detail = team?.usersDisplay?.map((x) => {
      return {
        name: x.name,
        email: x.email,
        id: x.id,
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };

  const createNewFolder = () => {
    if (
      globalState?.currentCompany === undefined ||
      globalState?.currentProject === undefined
    ) {
      toast({
        title: 'Deoapp',
        description: 'You must setup your company and project.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const data = {
      title: title,
      type: 'media',
      companyId: globalState?.currentCompany || globalState?.companies[0].id,
      projectId: globalState?.currentProject || globalState?.projects[0].id,
      lastUpdated: new Date(),
      users: [globalState?.uid],
      usersDisplay: [
        {
          id: auth?.currentUser?.uid,
          image: auth.currentUser?.photoURL,
          name: auth?.currentUser?.displayName,
          email: auth?.currentUser?.email,
        },
      ],
    };
    addDocumentFirebase('files', data, globalState.currentCompany).then((x) => {
      navigate(`view/${x}/${title}`);
    });
  };


  const handleNavigateMy = () => {
    navigate('view/recently/update');
  };

  
  return (
    <Stack  spacing={5}>
      <HStack gap={5} flexDirection={['column', 'row', 'row']}>
        <Heading size={'md'}>Folder</Heading>
        <Spacer />
        <HStack flexDirection={['column', 'row', 'row']}>
          <Stack>
            <Input
              shadow={'md'}
              // bgColor="white"
              placeholder={'Search'}
              size={'md'}
              borderRadius="md"
            //   onChange={(e) => handleSearch(e.target.value)}
            />
          </Stack>

          <DynamicButton
            title={'See All'}
            action={'read'}
            onClick={handleOpenFilter}
          />

          <DynamicButton
            action={'create'}
            title={'New Folder'}
            onClick={onOpen}
          />
        </HStack>
      </HStack>
  
      {dataFolder?.length > 0  ? (
        <SimpleGrid columns={[1, 2, 4]} gap={5}>
          <CardMyKanban
            Icon={FcFolder}
            title={'Recently Update'}
            globalState={globalState}
            onNavigate={() => handleNavigateMy()}
          />
          {dataFolder.map((x, index) => (
            <Stack key={index}>
              <CardAvatar
                data={x}
                title={x.title}
                globalState={globalState}
                onNavigate={() => handleNavigate(x)}
                userArray={x?.usersDisplay}
                timeCreated={x?.lastUpdated?.seconds}
                modalTeam={() => modalTeam(x)}
                owner={x?.users}
                // onDelete={() => console.log(x)}
                Icon={FcFolder}
              />
            </Stack>
          ))}
        </SimpleGrid>
      ) : (
        <AlertArrayNull titleData={'Assets'} action={'New Folder'} />
      )}
  
      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {currentPage < totalPages && (
            <Button
              colorScheme={'blue'}
              fontSize="sm"
              onClick={handleLoadMore}
            >
                Load More
            </Button>
          )}
        </Box>
      </Stack>
  
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type="text" onChange={(e) => setTitle(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={() => createNewFolder()}
              title={'Save'}
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'}>{data?.title}</Heading>
                  <Divider />
                </VStack>
              </Center>
              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => (
                  <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                    {x?.name === data?.usersDisplay[0]?.name ? (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          <Box p={4}>
                            <Text fontStyle={'italic'} fontSize={'xs'}>
                              Creator
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <Stack align={'flex-start'} spacing={0}>
                            <Text
                              fontWeight={500}
                              textTransform="capitalize"
                              fontSize={'sm'}
                            >
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </Stack>
                        </HStack>
                        <Stack>
                          {data?.users?.includes(globalState?.uid) && (
                            <Box p={4} w="15%">
                              <DynamicButton
                                action="delete"
                                size={'sm'}
                                // onClick={() => handleRemoveUser(x, data)}
                              />
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
  
export default MediaTab;
  