/* eslint-disable react/prop-types */
import { AspectRatio, Divider, Grid, Heading, HStack, Image, Spacer, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../Buttons/DynamicButton'

function InstagramPublishCard({data}) {

  const globalState = useUserStore();



  return (
    <Stack 
      borderRadius="lg"
      shadow="md"
      // bgColor={'white'}
      borderTopWidth={5}
      borderColor="blue.500"
      p={5}
    >
      <Grid templateColumns={{ base: '1fr', md: '4fr 1fr 0.5fr' }} gap={5}>
        <Stack  p={3} fontSize="sm">
          <Text>{data.post}</Text>
        </Stack>
        {data.mediaType === 'VIDEO' ? (
          <AspectRatio madataW="auto" ratio={1}>
            <iframe
              title='halo'
              src={data.mediaUrl}
              allowFullScreen
            />
          </AspectRatio>
        ) : (
          <AspectRatio madataW="auto" ratio={1}>
            <Image src={data.mediaUrl} />
          </AspectRatio>
        )}
        <Stack alignItems={'center'} justifyContent="center">
          <DynamicButton size={'sm'} variant={'solid'} action={'read'} onClick={() => window.open(data.postUrl, '_blank')} />
        </Stack>
      </Grid>
      <Divider />
      <Stack>
        <HStack spacing={5}>
          <Stack alignItems={'center'} justifyContent="center">
            <Text fontSize={'sm'} color="gray.600">Comments</Text>
            <Heading size="lg">{data.commentsCount}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center">
            <Text fontSize={'sm'} color="gray.600">Like</Text>
            <Heading size="lg">{data.likeCount}</Heading>
          </Stack>
          <Spacer />
          <Stack>
            <Text fontSize={'sm'}>Share via {globalState?.socialActive?.platform} on {moment(data.created).format('LLLL')}</Text>
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  )
}

export default InstagramPublishCard