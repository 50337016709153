function groupBy(items, iteratee) {
  return items.reduce((map, value) => {
    const key = iteratee(value);

    let array = map.get(key);
    if (!array) {
      array = [];
      map.set(key, array);
    }

    array.push(value);

    return map;
  }, new Map());
}

export default {
  groupBy,
};
