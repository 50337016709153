import React, { useState } from 'react';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import { videoCreator } from '../../../../Hooks/Mobx/CreatomateStore';

export function CreateButton() {
  const [isRendering, setIsRendering] = useState(false);
  const [render, setRender] = useState(null);
  const toast = useToast();

  const handleRender = async () => {
    setIsRendering(true);

    try {
      const render = await videoCreator.finishVideo();
      if (render.status === 'succeeded') {
        setRender(render);
      } else {
        toast({
          title: 'Rendering failed',
          description: `Error: ${render.errorMessage}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'An error occurred',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsRendering(false);
    }
  };

  if (isRendering) {
    return (
      <Button
        colorScheme='orange'
        isLoading
        loadingText='Rendering...'
        spinner={<Spinner color='white' />}>
        Rendering...
      </Button>
    );
  }

  if (render) {
    return (
      <Button
        colorScheme='green'
        onClick={() => {
          window.open(render.url, '_blank');
          setRender(null);
        }}>
        Download
      </Button>
    );
  }

  return (
    <Button
      colorScheme='blue'
      onClick={handleRender}>
      Create Video
    </Button>
  );
}
