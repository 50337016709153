import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, VStack, HStack, Text, IconButton, Tooltip } from '@chakra-ui/react';
import { videoCreator } from '../../../../Hooks/Mobx/CreatomateStore';
import { CreateButton } from './CreateButton';
import { FaTrash, FaPlay, FaPause, FaUndo, FaRedo, FaArrowUp, FaArrowDown, FaTextHeight, FaImage, FaVideo } from 'react-icons/fa';
import CompositionNavigation from './CompositionNavigation';

export const Stage = observer(() => {
  console.log(videoCreator?.preview?.getSource(), 'Video Source');
  console.log(videoCreator?.preview, 'Preview instance');

  return (
    <Box
      position='relative'
      flex='1'
      height='100%'
      bg='gray.800'>
      <Box
        ref={(element) => {
          if (element && element !== videoCreator.preview?.element) {
            videoCreator.initializeVideoPlayer(element);
          }
        }}
        width='100%'
        height='100%'
        // bg='red.500'
      />

      <VStack
        position='absolute'
        top='5px'
        left='5px'
        spacing={2}>
        <Tooltip label='Add Text Element'>
          <IconButton
            aria-label='Add text'
            icon={<FaTextHeight />}
            onClick={async () => {
              await videoCreator.createElement({ type: 'text', text: 'New text element' });
            }}
          />
        </Tooltip>
        <Tooltip label='Add Image Element'>
          <IconButton
            aria-label='Add image'
            icon={<FaImage />}
            onClick={async () => {
              await videoCreator.createElement({
                type: 'image',
                source: 'https://creatomate-static.s3.amazonaws.com/video-creator-js/gradienta-ix_kUDzCczo-unsplash.jpg',
              });
            }}
          />
        </Tooltip>
        <Tooltip label='Add Video Element'>
          <IconButton
            aria-label='Add video'
            icon={<FaVideo />}
            onClick={async () => {
              await videoCreator.createElement({
                type: 'video',
                source: 'https://creatomate-static.s3.amazonaws.com/video-creator-js/pexels-2025634.mp4',
                loop: true,
              });
            }}
          />
        </Tooltip>
      </VStack>

      <HStack
        position='absolute'
        top='5px'
        right='5px'
        spacing={4}
        alignItems='center'>
        {videoCreator.isLoading && <Text color='gray.400'>Loading...</Text>}
        <Tooltip label='Undo'>
          <IconButton
            aria-label='Undo'
            icon={<FaUndo />}
            isDisabled={!videoCreator.state?.undo}
            onClick={() => {
              videoCreator.preview?.undo();
            }}
          />
        </Tooltip>
        <Tooltip label='Redo'>
          <IconButton
            aria-label='Redo'
            icon={<FaRedo />}
            isDisabled={!videoCreator.state?.redo}
            onClick={() => {
              videoCreator.preview?.redo();
            }}
          />
        </Tooltip>
        <CreateButton />
      </HStack>

      <VStack
        position='absolute'
        bottom='5px'
        left='5px'
        spacing={2}>
        <Tooltip label='Play/Pause'>
          <IconButton
            aria-label='Play/Pause'
            icon={!videoCreator.isPlaying ? <FaPlay /> : <FaPause />}
            onClick={() => {
              if (!videoCreator.isPlaying) {
                videoCreator.preview?.play();
              } else {
                videoCreator.preview?.pause();
              }
            }}
          />
        </Tooltip>
      </VStack>

      <VStack
        position='absolute'
        bottom='5px'
        right='5px'
        spacing={2}>
        <Tooltip label='Delete Active Element'>
          <IconButton
            aria-label='Delete Element'
            icon={<FaTrash />}
            isDisabled={videoCreator.activeElementIds.length === 0}
            onClick={async () => {
              const activeElement = videoCreator.getActiveElement();
              if (activeElement) {
                await videoCreator.deleteElement(activeElement.source.id);
              }
            }}
          />
        </Tooltip>
        <Tooltip label='Move Track Up'>
          <IconButton
            aria-label='Move Track Up'
            icon={<FaArrowUp />}
            isDisabled={videoCreator.activeElementIds.length === 0}
            onClick={async () => {
              const activeElement = videoCreator.getActiveElement();
              if (activeElement) {
                await videoCreator.rearrangeTracks(activeElement.track, 'up');
              }
            }}
          />
        </Tooltip>
        <Tooltip label='Move Track Down'>
          <IconButton
            aria-label='Move Track Down'
            icon={<FaArrowDown />}
            isDisabled={videoCreator.activeElementIds.length === 0}
            onClick={async () => {
              const activeElement = videoCreator.getActiveElement();
              if (activeElement) {
                await videoCreator.rearrangeTracks(activeElement.track, 'down');
              }
            }}
          />
        </Tooltip>
      </VStack>

      <CompositionNavigation />
    </Box>
  );
});
