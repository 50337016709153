/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import { Button, Checkbox, Input, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

function InstagramInput({ data, setData, postTypes }) {
  const [collaboratorsActive, setCollaboratorsActive] = useState(false);
  const [newCollaborator, setNewCollaborator] = useState('');

  const handleAddCollaborator = () => {
    if (newCollaborator.trim() !== '') {
      setData({
        ...data,
        instagramOptions: {
          ...data.instagramOptions,
          collaborators: [...(data.instagramOptions?.collaborators || []), newCollaborator.trim()].slice(0, 3),
        },
      });
      setNewCollaborator('');
    }
  };

  const handleRemoveCollaborator = (index) => {
    const updatedCollaborators = [...data.instagramOptions?.collaborators];
    updatedCollaborators.splice(index, 1);
    setData({
      ...data,
      instagramOptions: {
        ...data.instagramOptions,
        collaborators: updatedCollaborators,
      },
    });
  };

  return (
    <Stack>
      <Text
        fontSize={'sm'}
        color='gray.500'
        fontWeight={'semibold'}>
        Details Post for Instagram
      </Text>

      <Stack>
        {postTypes === 'reels' ? (
          <>
            <Checkbox
              colorScheme='blue'
              onChange={(e) =>
                setData({
                  ...data,
                  instagramOptions: {
                    ...data.instagramOptions,
                    reels: e.target.checked,
                  },
                })
              }>
              <Text fontSize={'sm'}>Reels Content</Text>
            </Checkbox>

            <Checkbox
              colorScheme='blue'
              onChange={(e) =>
                setData({
                  ...data,
                  instagramOptions: {
                    ...data.instagramOptions,
                    shareReelsFeed: e.target.checked,
                  },
                })
              }>
              <Text fontSize={'sm'}>Share reels to feed</Text>
            </Checkbox>

            <Text>Thumbnail Offset {'miliseconds'}</Text>
            <Input
              size={'sm'}
              defaultValue={30000}
              onChange={(e) =>
                setData({
                  ...data,
                  instagramOptions: {
                    ...data.instagramOptions,
                    thumbNailOffset: e.target.value,
                  },
                })
              }
            />

            <Text>Cover URL</Text>
            <Input
              size={'sm'}
              placeholder='https://image'
              // defaultValue={30000}
              onChange={(e) =>
                setData({
                  ...data,
                  instagramOptions: {
                    ...data.instagramOptions,
                    coverURL: e.target.value,
                  },
                })
              }
            />
          </>
        ) : null}

        {postTypes === 'stories' ? (
          <>
            <Checkbox
              colorScheme='blue'
              onChange={(e) =>
                setData({
                  ...data,
                  instagramOptions: {
                    ...data.instagramOptions,
                    stories: e.target.checked,
                  },
                })
              }>
              <Text fontSize={'sm'}>Stories Content</Text>
            </Checkbox>
          </>
        ) : null}

        <Text
          fontSize={'sm'}
          color='gray.500'
          fontWeight={'semibold'}>
          Locations {'Must be start with @, example: @Jakarta'}
        </Text>
        <Input
          placeholder='Location'
          fontSize={'sm'}
          onChange={(e) =>
            setData({
              ...data,
              instagramOptions: {
                ...data?.instagramOptions,
                locationId: e.target.value,
              },
            })
          }
        />
        <Text
          fontSize={'sm'}
          color='gray.500'
          fontWeight={'semibold'}>
          Tag Users
        </Text>
        <Input
          placeholder='Super title for the Reel'
          fontSize={'sm'}
          onChange={(e) =>
            setData({
              ...data,
              instagramOptions: {
                ...data?.instagramOptions,
                userTags: [{ username: e.target.value, x: 0.5, y: 0.9 }],
              },
            })
          }
        />

        <Checkbox
          colorScheme='blue'
          onChange={(e) =>
            setData({
              ...data,
              instagramOptions: {
                ...data.instagramOptions,
                autoResize: e.target.checked,
              },
            })
          }>
          <Text fontSize={'sm'}>Auto Resize</Text>
        </Checkbox>
      </Stack>

      <Checkbox
        colorScheme='blue'
        isChecked={collaboratorsActive}
        onChange={(e) => setCollaboratorsActive(e.target.checked)}>
        <Text fontSize={'sm'}>Collaborators Active</Text>
      </Checkbox>

      {collaboratorsActive && (
        <Stack>
          <Input
            placeholder='Enter collaborator name'
            value={newCollaborator}
            onChange={(e) => setNewCollaborator(e.target.value)}
          />
          <Button onClick={handleAddCollaborator}>Add Collaborator</Button>
        </Stack>
      )}

      {/* Daftar nama kolaborator */}
      {data.instagramOptions?.collaborators && data.instagramOptions.collaborators.length > 0 && (
        <Stack>
          {data.instagramOptions.collaborators.map((collaborator, index) => (
            <Stack
              key={index}
              direction='row'
              align='center'>
              <Text>{collaborator}</Text>
              <Button
                size='sm'
                onClick={() => handleRemoveCollaborator(index)}>
                Remove
              </Button>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export default InstagramInput;
