import React, { useEffect, useState } from 'react';
import { Box, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { TiktokFYPCard } from '../SocialMedia/ResearchFYP/TiktokFYPCard';
import SearchBar from '../SocialMedia/ResearchFYP/SearchBarFYP';
import BackButtons from '../../Components/Buttons/BackButtons';
import { clientTypessense } from '../../Api/Typesense';
import BarSearch from './BarSearch';
import { SavedCard } from './SavedContentCard';

function ContentSavedPage() {
  const [data, setData] = useState();
  const itemsPerPage = 60;
  const [startIndex, setStartIndex] = useState(0);
  const [inputSearch, setInputSearch] = useState('');
  const [dataSearch, setDataSearch] = useState([]);
  const globalState = useUserStore();

  const usernameUrl = (link) => {
    const regex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@([^/]+)/i;
    const match = link.match(regex);

    if (match && match[3]) {
      return `https://www.tiktok.com/@${match[3]}`;
    } else {
      return null; // URL format doesn't match TikTok video URL
    }
  };

  console.log(dataSearch, 'ini data search luar');

  const getDataFyp = async () => {
    try {
      const conditions = [];
      const sortBy = { field: 'create_time', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;
      console.log('ini masuk');

      let res = [];
      if (inputSearch !== '') {
        console.log('masuk if');
        await Promise.all(
          dataSearch.map(async (id) => {
            try {
              const result = await getSingleDocumentFirebase('social-media-fyp', id);

              res.push(result);
            } catch (error) {
              console.log(error.message);
            }
          })
        );
      } else {
        console.log('masuk else');
        // Menggunakan startIndex untuk mengatur mulai dari mana data diambil
        res = await getCollectionFirebase('social-media-fyp', conditions, sortBy, limitValue, startIndex);
      }

      setData(res);
      console.log(res, 'ini data get');
    } catch (error) {
      throw new Error(error, 'An error occured');
    }
  };

  console.log(dataSearch, 'ini data search');

  const handleOpenLink = (value) => {
    window.open(value?.shareUrl, '_blank');
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  useEffect(() => {
    getDataFyp();
    return () => {};
  }, [startIndex]);

  useEffect(() => {
    getDataFyp();
  }, [dataSearch]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading
          ml={3}
          onClick={() => {
            throw new Error('tes error timothy');
          }}>
          Saved Video Content
        </Heading>
      </HStack>
      <Spacer />
      <Spacer />
      <Box
        position='fixed'
        top={{ base: '10%', md: '13%' }}
        left='52%'
        transform='translateX(-50%)'
        width={{ base: '50%', md: '70%', lg: '90%' }}
        maxWidth='85vw'
        zIndex='999'>
        <BarSearch />
      </Box>

      <Box marginTop='80px'>
        {data ? (
          <SavedCard
            dataHasil={data}
            handleOpenLink={handleOpenLink}
            handleLoadMore={handleLoadMore}
            usernameUrl={usernameUrl}
          />
        ) : (
          <Text>Loading...</Text>
        )}
      </Box>
    </Box>
  );
}

export default ContentSavedPage;
