/* eslint-disable react/prop-types */
import React, { useState } from 'react'; // Tambahkan import React di sini
import { AspectRatio, Avatar, AvatarBadge, Divider, HStack, Image, Spacer, Stack, Tag, Text, Button, InputRightElement, InputGroup, Textarea, useToast } from '@chakra-ui/react';
import moment from 'moment';
import { AiOutlineComment, AiOutlineHeart } from 'react-icons/ai';
import DynamicButton from '../Buttons/DynamicButton';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import { FaReply } from 'react-icons/fa';
import SearchBar from './SearchBarComment';

function CommentEngageFacebook({ postActive, platformData, profileKeyData }) {
  const [openReplies, setOpenReplies] = useState({});
  const [comment, setComment] = useState('');
  const toast = useToast();
  const [filteredComments, setFilteredComments] = useState(postActive?.commentsData?.youtube || []);
  const [commentInputs, setCommentInputs] = useState({}); // State untuk input balasan

  const globalState = useUserStore();

  const toggleReplies = (commentId) => {
    setOpenReplies((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  const handleReply = (commentId) => {
    setCommentInputs((prevState) => ({
      ...prevState,
      [commentId]: '', // Set nilai awal textarea reply menjadi string kosong
    }));
  };

  const handleChange = (e, commentId) => {
    const { value } = e.target;
    setCommentInputs((prevState) => ({
      ...prevState,
      [commentId]: value,
    }));
  };

  const handleClick = async () => {
    const data = {
      id: postActive.id,
      platforms: [platformData],
      comment: comment,
      searchPlatformId: true,
      profileKey: profileKeyData,
    };
    globalState.setIsLoading(true);
    try {
      await _axios.post('/ayrshare-postCommentSocial', data);

      toast({
        title: 'Deoapp',
        description: 'Success add comment',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'error add comment.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      return;
    } finally {
      globalState.setIsLoading(false);
      setComment('');
    }
  };

  const handleSendReply = async (commentId) => {
    const data = {
      commentId: commentId,
      platforms: [platformData],
      comment: commentInputs[commentId], // Mengambil balasan dari state
      searchPlatformId: true,
      profileKey: profileKeyData,
    };
    globalState.setIsLoading(true);
    try {
      await _axios.post('/ayrshare-replyComment', data);
      toast({
        title: 'Deoapp',
        description: 'Success add comment',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'error add comment.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
      setCommentInputs({});
    }
  };

  console.log(postActive, 'ini post active');

  return (
    <Stack>
      <HStack w={'500px'}>
        <Stack
          spacing={1}
          cursor='pointer'
          onClick={() => window.open(postActive.postUrl, '_blank')}>
          <Text
            fontWeight={500}
            fontSize='sm'
            color={'gray.700'}>
            Post Description
          </Text>
          <Text
            noOfLines={2}
            fontSize='sm'>
            {postActive?.post}
          </Text>
          <HStack>
            <Tag
              colorScheme={'red'}
              size='sm'>
              <HStack>
                <AiOutlineComment />
                <Text fontWeight={500}>{postActive?.commentsCount || 0}</Text>
              </HStack>
            </Tag>
            <Tag
              colorScheme={'red'}
              size='sm'>
              <HStack>
                <AiOutlineHeart />
                <Text fontWeight={500}>{postActive?.likeCount || 0}</Text>
              </HStack>
            </Tag>
            <Spacer />
            <Text
              fontSize={'xs'}
              fontWeight={500}>
              {moment(postActive?.created).fromNow()}
            </Text>
          </HStack>
        </Stack>
      </HStack>
      <Divider />
      <SearchBar
        comments={postActive?.commentsData?.facebook || []}
        setFilteredComments={setFilteredComments}
      />
      <Stack>
        {postActive?.commentsData && postActive?.commentsData?.facebook && (
          <Stack
            p={3}
            h={'500px'}
            overflowY='scroll'>
            <Text
              fontWeight={500}
              fontSize='sm'>
              Comments:
            </Text>
            {filteredComments.map((comment) => (
              <Stack
                key={comment?.commentId}
                p={3}
                borderRadius='md'
                shadow={'md'}
                marginBottom={3}>
                <HStack
                  spacing={3}
                  alignItems='flex-start'
                  justifyContent={'flex-start'}>
                  <Stack>
                    <Avatar
                      cursor={'pointer'}
                      size='sm'
                      src={comment?.from?.name}
                      name={comment?.from?.name}
                      alt={comment?.from?.name}>
                      <AvatarBadge
                        boxSize='1.2em'
                        bg='green.500'
                      />
                    </Avatar>
                  </Stack>
                  <Stack spacing={1}>
                    <Text
                      fontSize='sm'
                      fontWeight={500}>
                      {comment?.comment}
                    </Text>
                    <Text
                      cursor={'pointer'}
                      fontSize='xs'
                      color='gray.500'
                      onClick={() => window.open(`https://www.facebook.com/${comment?.from?.username}`, '_blank')}>
                      @{comment?.from?.username} • {moment(comment?.created).fromNow()}
                    </Text>
                  </Stack>
                  <Spacer />
                  <Stack
                    alignItems={'center'}
                    justifyContent='center'>
                    <FaReply
                      size={15}
                      cursor='pointer'
                      onClick={() => handleReply(comment?.commentId)}
                    />
                  </Stack>
                </HStack>
                {commentInputs[comment?.commentId] !== undefined && ( // Periksa apakah ada input untuk commentId saat ini
                  <Stack p={2}>
                    <InputGroup
                      size='sm'
                      bgColor={'white'}
                      color='black'>
                      <Textarea
                        pr='4.5rem'
                        placeholder='Reply...'
                        value={commentInputs[comment?.commentId]} // Set nilai textarea dengan value dari state
                        onChange={(e) => handleChange(e, comment?.commentId)}
                      />
                      <InputRightElement width='4.5rem'></InputRightElement>
                    </InputGroup>
                    <DynamicButton
                      action={'create'}
                      title='Reply'
                      size={'sm'}
                      variant='solid'
                      onClick={() => handleSendReply(comment?.commentId)}
                    />
                  </Stack>
                )}
                {openReplies[comment?.commentId] && comment?.replies && comment?.replies?.length > 0 && (
                  <Stack marginLeft={8}>
                    {comment?.replies?.map((reply) => (
                      <Stack
                        key={reply.id}
                        p={2}
                        borderRadius='md'
                        shadow={'sm'}
                        marginTop={2}>
                        <HStack
                          spacing={3}
                          alignItems='flex-start'
                          justifyContent={'flex-start'}>
                          <Avatar
                            cursor={'pointer'}
                            size='sm'
                            src={''}
                            name={''}
                            alt={''}>
                            <AvatarBadge
                              boxSize='1.2em'
                              bg='green.500'
                            />
                          </Avatar>
                          <Stack spacing={1}>
                            <Text
                              fontSize='sm'
                              fontWeight={500}>
                              {reply?.text}
                            </Text>
                            <Text
                              cursor={'pointer'}
                              fontSize='xs'
                              color='gray.500'>
                              @{comment?.from?.username} • {moment(reply?.timestamp).fromNow()}
                            </Text>
                          </Stack>
                        </HStack>
                      </Stack>
                    ))}
                  </Stack>
                )}
                {/* Show button to toggle replies */}
                {comment?.replies && comment?.replies?.length > 0 && (
                  <Button
                    size='xs'
                    variant='link'
                    onClick={() => toggleReplies(comment?.commentId)}>
                    {openReplies[comment?.commentId] ? 'Hide Replies' : 'View Replies'} {comment?.replies?.length}
                  </Button>
                )}
              </Stack>
            ))}
          </Stack>
        )}

        <Spacer />
        <Stack p={3}>
          <InputGroup
            size='sm'
            bgColor={'white'}
            color='black'>
            <Textarea
              pr='4.5rem'
              placeholder='Comments...'
              onChange={(e) => setComment(e.target.value)}
              value={comment} // Set nilai textarea dengan value dari state
            />
            <InputRightElement width='4.5rem'></InputRightElement>
          </InputGroup>
          <DynamicButton
            action={'create'}
            title='Send'
            variant='solid'
            onClick={handleClick}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CommentEngageFacebook;
