import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Box,
  useDisclosure,
  Heading,
  useToast,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import _ from 'lodash';
import { TbReload } from 'react-icons/tb';
import { Search2Icon } from '@chakra-ui/icons';
import useUserStore from '../../../Hooks/Zustand/Store';
import { clientTypessense } from '../../../Api/Typesense';
import { deleteDocumentFirebase, getCollectionFirebase } from '../../../Api/firebaseApi';
import _axios from '../../../Api/AxiosBarrier';
import BackButtons from '../../../Components/Buttons/BackButtons';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicTable from '../../../Components/Table/DynamicTable';
import UsersViewPage from './UsersViewPage';
import { useTranslation } from 'react-i18next';

const UsersPage = () => {
  const globalState = useUserStore();
  const cancelRef = useRef();
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState({});

  //state form
  const [data, setData] = useState();
  const [dataUser, setDataUser] = useState();
  const [employeeId, setEmployeeId] = useState(null);
  const [chunkUser, setChunkUser] = useState();
  const [loading, setLoading] = useState(false);

  const page = useRef(1);

  const toast = useToast();

  const tableHeader = ['name', 'email'];

  const handleTypesenseSearch = (q, kodok, pagination = page) => {
    globalState.setIsLoading(true);
    const users = globalState?.companies?.find((x) => x.id === globalState.currentCompany);
    page.current = pagination;

    const newArr = _.chunk(users?.users, 10);

    if (!newArr)
      return toast({
        title: 'Deoapp.com',
        description: 'no data user',
        duration: 3000,
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });

    const searchParameters = {
      q: q,
      query_by: 'name,email',
      per_page: 10,
      filter_by: `id: [${newArr[page.current - 1]?.join(',')}]`,
      sort_by: '_text_match:desc',
    };

    clientTypessense
      .collections('users')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => {
          return { ...y.document };
        });

        // Fetch role data for each user
        getCollectionFirebase(`companies/${globalState.currentCompany}/roles`)
          .then((rolesData) => {
            const mergedData = newData.map((user) => {
              const roles = rolesData?.find((role) => role?.users?.includes(user.id));
              user.role = roles ? roles?.title : '';
              user.roleId = roles ? roles?.id : '';
              return user;
            });

            setData(mergedData);
          })
          .catch((err) => {
            setData([]);
            toast({
              title: 'Deoapp.com',
              description: err.message,
              duration: 3000,
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          });

        globalState.setIsLoading(false);
      })

      .catch((err) => {
        setData([]);
        toast({
          title: 'Deoapp.com',
          description: err.message,
          duration: 3000,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
        globalState.setIsLoading(false);
      });

    return null;
  };

  const handleSingleTypesenseSearch = (q) => {
    if (q === '') return handleTypesenseSearch('', null, 1);

    const searchPromises = chunkUser?.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };
      return clientTypessense.collections('users').documents().search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const rolePromises = results?.flatMap((result) => {
          return result.hits.map((x) => {
            return getCollectionFirebase(`companies/${globalState.currentCompany}/roles`).then((resultRole) => {
              const matchRole = resultRole?.find((res) => res?.users?.includes(x?.document?.id));

              return {
                ...x?.document,
                role: matchRole?.title || matchRole?.name,
                roleId: matchRole?.id,
              };
            });
          });
        });

        return Promise.all(rolePromises);
      })
      .then((combinedResults) => {
        setData(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          duration: 3000,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const handleAdd = () => {
    setEmployeeId(null);
    setModal(true);
  };

  const deleteFromFirestore = async (collection, doc, field, value) => {
    const dataObj = {
      collection: collection,
      doc: doc,
      field: field,
      values: value,
    };

    await _axios.post('/company-removeFromArrayField', dataObj);
  };

  const deleteUserFromCompany = async () => {
    await deleteFromFirestore('companies', globalState?.currentCompany, 'owners', [selectedUser?.data?.id]);
    await deleteFromFirestore('companies', globalState?.currentCompany, 'users', [selectedUser?.data?.id]);
  };

  const deleteFromProject = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      {
        field: 'users',
        operator: 'array-contains',
        value: selectedUser?.data?.id,
      },
    ];

    const CompanyProject = await getCollectionFirebase('projects', conditions);

    const updateProjectPromises = CompanyProject.map(async (project) => {
      await deleteFromFirestore('projects', project?.id, 'owners', [selectedUser?.data?.id]);
      await deleteFromFirestore('projects', project?.id, 'users', [selectedUser?.data?.id]);

      const getSubcol = await getCollectionFirebase(`projects/${project?.Id}/users`);

      if (getSubcol && getSubcol.length > 0) {
        await deleteDocumentFirebase(`projects/${project?.id}/users`, selectedUser?.data?.id);
      }
    });

    await Promise.all(updateProjectPromises);
  };

  const handleDeleteUser = async () => {
    if (globalState.roleCompany !== 'owner') {
      return toast({
        title: t('toast.alert'),
        description: 'You don\'t have access to delete user',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(true);

    try {
      await deleteUserFromCompany();
      await deleteFromProject();

      setLoading(false);

      toast({
        title: 'Deoapp',
        status: 'success',
        description: 'Account deleted',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
    } finally {
      setLoading(false);
      onClose();
      getDataFirst();
    }
  };

  const getDataFirst = () => {
    const users = globalState?.companies?.find((x) => x.id === globalState.currentCompany);
    setDataUser(users?.users);
    setChunkUser(_.chunk(users?.users, 10));
    handleTypesenseSearch('', users?.users, 1);
  };

  useEffect(() => {
    getDataFirst();

    return () => {
      page.current = 1;
      setDataUser();
    };
  }, [globalState.currentCompany]);

  let tableData = '';
  tableData = data?.map((data, index) => {
    const name = data?.name || '';
    const email = data?.email || '';

    return {
      data,
      name: (
        <HStack key={index}>
          <Avatar
            size={'sm'}
            name={name}
          />
          <Stack spacing={1}>
            <Text
              fontWeight={500}
              textTransform={'capitalize'}>
              {name}
            </Text>
            {/* <Text fontSize={9}>{data?.id}</Text> */}
          </Stack>
        </HStack>
      ),
      email: email,
    };
  });

  const openModalEdit = (x) => {
    setEmployeeId(x?.data?.id);
    setModal(true);
  };

  const openModalDelete = (x) => {
    setSelectedUser(x);
    onOpen();
  };

  return (
    <Stack p={[0, 1, 5]}>
      <HStack pb={3}>
        <BackButtons />
        <Spacer />
        <Heading size={'lg'}>{t('Users')}</Heading>
      </HStack>

      <Stack
        p='5'
        borderRadius='md'
        shadow='base'>
        <HStack
          flexDirection={['column', 'column', 'row']}
          mt={3}
          p={2}>
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <Search2Icon color='gray.300' />
            </InputLeftElement>
            <Input
              // bg={'white'}
              maxW={[null, null, '2xs']}
              type='text'
              placeholder={t('searchUser')}
              onChange={(e) => handleSingleTypesenseSearch(e.target.value)}
              // onChange={(e) => handleTypesenseSearch(e.target.value)}
            />
          </InputGroup>

          <HStack>
            <DynamicButton
              action={'create'}
              onClick={handleAdd}
              title={t('user')}
            />
            <DynamicButton
              action={'custom'}
              icon={TbReload}
              variant='solid'
              title={t('configuration')}
              color='blue'
              onClick={() => window.location.reload()}
            />
          </HStack>
        </HStack>

        <Stack
          alignItems='flex-end'
          justifyContent='flex-end'
          px={3}>
          <Text
            fontSize={'xs'}
            color='red.600'>
            * {t('settingRole')}
          </Text>
        </Stack>

        <Box>
          <DynamicTable
            header={tableHeader}
            data={tableData}
            onDelete={openModalDelete}
            // onRead={openModalDeleteRole}
            onEdit={openModalEdit}
          />
        </Box>

        <HStack
          justifyContent={'center'}
          spacing={5}
          pb={5}>
          {page?.current > 1 ? (
            <Button
              colorScheme='blue'
              size={'sm'}
              onClick={() => handleTypesenseSearch('', null, page.current - 1)}>
              <HStack
                color='white'
                spacing={1}
                pr={3}>
                <MdNavigateBefore size={23} />
                <Text>{t('prev')}</Text>
              </HStack>
            </Button>
          ) : null}

          {page?.current < dataUser?.length / 10 ? (
            <Button
              colorScheme='blue'
              size={'sm'}
              onClick={() => handleTypesenseSearch('', null, page?.current + 1)}>
              <HStack
                color='white'
                spacing={1}
                pl={3}>
                <Text>{t('next')}</Text>
                <Box>
                  <MdNavigateNext size={23} />
                </Box>
              </HStack>
            </Button>
          ) : null}
        </HStack>
      </Stack>

      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        motionPreset='slideInBottom'
        size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{t('employeeForm')}</ModalHeader>
          <UsersViewPage
            uid={employeeId}
            setModal={setModal}
            getData={getDataFirst}
          />
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize='lg'
              fontWeight='bold'>
              {t('deleteUser')}
            </AlertDialogHeader>

            <AlertDialogBody>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('deleteUser2', {
                    name: `<strong>${selectedUser?.data?.name}</strong>`,
                  }),
                }}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                isLoading={loading}
                colorScheme='red'
                onClick={() => handleDeleteUser()}
                ml={3}>
                {t('delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};

export default UsersPage;
