import { Heading, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import { formatFrice } from '../../Utils/numberUtil';
import AnalyticsDataPost from '../Analytics/AnalyticsDataPost';
import useUserStore from '../../Hooks/Zustand/Store';
import _axios from '../../Api/AxiosBarrier';

function PostsTab() {
  const globalState = useUserStore();
  const [data, setData] = useState('');
  // const mediaTypeRef = useRef('');
  // const availableMediaTypesRef = useRef([]);
  const [totalLikesCount, setTotalLikesCount] = useState(0);
  const [totalReachCount, setTotalReachCount] = useState(0);
  const [totalCommentsCount, setTotalCommentsCount] = useState(0);
  const [totalVideoViewsCount, setTotalVideoViewsCount] = useState(0);
  const [totalEngagementCount, setTotalEngagementCount] = useState(0);
  const toast = useToast();

  const getDataPost = async () => {
    globalState.setIsLoading(true);
    const data = {
      profileKey: globalState?.socialKey,
      query: `/${globalState?.socialActive?.platform}`,
    };
    try {
      const res = await _axios.post('/ayrshare-getAllPosts', data);
      if (res?.data?.status === 'success') {
        setData(res?.data);
        console.log(res?.data, 'ini jumlah post');
        if (res.data?.posts.length > 0) {
          const dataPosting = res?.data?.posts;
          const updatedFilterData = await Promise.all(
            dataPosting.map(async (iterator) => {
              const res = await _axios.post('ayrshare-getAnalyticsPost', {
                profileKey: globalState?.socialKey,
                id: iterator?.id || iterator?.itemId,
                platforms: [globalState?.socialActive?.platform],
                searchPlatformId: true,
              });
              const obj = res.data;
              const { ...analytics } = obj;
              if (analytics?.code !== 186) {
                return { ...iterator, analytics };
              } else {
                return iterator;
              }
            })
          );
          // console.log(res,'ini res')
          console.log(updatedFilterData, 'ini data filter');

          const totalLikes = updatedFilterData?.reduce((total, post) => {
            if (post?.analytics?.instagram) {
              return total + (post?.analytics?.instagram?.analytics?.likeCount !== undefined ? post?.analytics?.instagram?.analytics?.likeCount : 0);
            } else if (post?.analytics?.tiktok) {
              return total + (post?.analytics?.tiktok?.analytics?.likes !== undefined ? post?.analytics?.tiktok?.analytics?.likes : 0);
            } else if (post?.analytics?.linkedin) {
              return total + (post?.analytics?.linkedin?.analytics?.likeCount !== undefined ? post?.analytics?.linkedin?.analytics?.likeCount : 0);
            } else if (post?.analytics?.youtube) {
              return total + (post?.analytics?.youtube?.analytics?.likes !== undefined ? post?.analytics?.youtube?.analytics?.likes : 0);
            } else {
              return total;
            }
          }, 0);
          setTotalLikesCount(totalLikes);

          const totalReach = updatedFilterData?.reduce((total, post) => {
            if (post?.analytics?.instagram) {
              return total + (post?.analytics?.instagram?.analytics?.reachCount !== undefined ? post?.analytics?.instagram?.analytics?.reachCount : 0);
            } else if (post?.analytics?.tiktok) {
              return total + (post?.analytics?.tiktok?.analytics?.reach !== undefined ? post?.analytics?.tiktok?.analytics?.reach : 0);
            } else if (post?.analytics?.linkedin) {
              return total + (post?.analytics?.linkedin?.analytics?.clickCount !== undefined ? post?.analytics?.linkedin?.analytics?.clickCount : 0);
            } else {
              return total;
            }
          }, 0);
          setTotalReachCount(totalReach);

          const totalComments = updatedFilterData?.reduce((total, post) => {
            if (post?.analytics?.instagram) {
              return total + (post?.analytics?.instagram?.analytics?.commentsCount !== undefined ? post?.analytics?.instagram?.analytics?.commentsCount : 0);
            } else if (post?.analytics?.tiktok) {
              return total + (post?.analytics?.tiktok?.analytics?.comments !== undefined ? post?.analytics?.tiktok?.analytics?.comments : 0);
            } else if (post?.analytics?.linkedin) {
              return total + (post?.analytics?.linkedin?.analytics?.commentCount !== undefined ? post?.analytics?.linkedin?.analytics?.commentCount : 0);
            } else if (post?.analytics?.youtube) {
              return total + (post?.analytics?.youtube?.analytics?.views !== undefined ? post?.analytics?.youtube?.analytics?.views : 0);
            } else {
              return total;
            }
          }, 0);
          setTotalCommentsCount(totalComments);

          const impressions = updatedFilterData?.reduce((total, post) => {
            if (post?.analytics?.instagram) {
              return total + (post?.analytics?.instagram?.analytics?.impressionsCount !== undefined ? post?.analytics?.instagram?.analytics?.impressionsCount : 0);
            } else if (post?.analytics?.tiktok) {
              return total + (post?.analytics?.tiktok?.analytics?.impressions !== undefined ? post?.analytics?.tiktok?.analytics?.impressions : 0);
            } else if (post?.analytics?.linkedin) {
              return total + (post?.analytics?.linkedin?.analytics?.impressionCount !== undefined ? post?.analytics?.linkedin?.analytics?.impressionCount : 0);
            } else if (post?.analytics?.youtube) {
              return total + (post?.analytics?.youtube?.analytics?.comments !== undefined ? post?.analytics?.youtube?.analytics?.comments : 0);
            } else {
              return total;
            }
          }, 0);
          setTotalVideoViewsCount(impressions);

          const engagement = updatedFilterData?.reduce((total, post) => {
            if (post?.analytics?.instagram) {
              return total + (post?.analytics?.instagram?.analytics?.engagementCount !== undefined ? post?.analytics?.instagram?.analytics?.engagementCount : 0);
            } else if (post?.analytics?.tiktok) {
              return total + (post?.analytics?.tiktok?.analytics?.engagement !== undefined ? post?.analytics?.tiktok?.analytics?.engagement : 0);
            } else if (post?.analytics?.linkedin) {
              return total + (post?.analytics?.linkedin?.analytics?.engagementCount !== undefined ? post?.analytics?.linkedin?.analytics?.engagementCount : 0);
            } else {
              return total;
            }
          }, 0);
          setTotalEngagementCount(engagement);
        }
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  // console.log(dataPost, 'ini data post tab');
  // console.log(data, 'ini adalah data');

  useEffect(() => {
    getDataPost();
  }, [globalState.socialKey, globalState?.socialActive?.platform]);

  return (
    <Stack>
      <Stack spacing={5}>
        <Stack>
          <Text
            fontSize={'lg'}
            fontWeight='bold'
            color={'gray.600'}>
            Post Summary
          </Text>
          <Stack
            borderRadius='lg'
            shadow='md'
            // bgColor={'white'}
            borderColor='blue.500'
            p={5}>
            <SimpleGrid
              columns={[1, 2, 3]}
              gap={5}>
              <Stack
                alignItems={'center'}
                justifyContent='flex-start'
                // bgColor={'white'}
                p={1}
                borderRadius='md'
                spacing={0}>
                <Text
                  fontSize={'md'}
                  fontWeight={500}
                  color='gray.600'>
                  Posts
                </Text>
                <Heading
                  size={'md'}
                  textTransform={'capitalize'}>
                  {data?.postsCount}
                </Heading>
              </Stack>
              <Stack
                alignItems={'center'}
                justifyContent='flex-start'
                // bgColor={'white'}
                p={1}
                borderRadius='md'
                spacing={0}>
                <Text
                  fontSize={'md'}
                  fontWeight={500}
                  color='gray.600'>
                  Impressions
                </Text>
                <Heading
                  size={'md'}
                  textTransform={'capitalize'}>
                  {totalVideoViewsCount}
                </Heading>
              </Stack>

              <Stack
                alignItems={'center'}
                justifyContent='flex-start'
                // bgColor={'white'}
                p={1}
                borderRadius='md'
                spacing={0}>
                <Text
                  fontSize={'md'}
                  fontWeight={500}
                  color='gray.600'>
                  Reach
                </Text>
                <Heading
                  size={'md'}
                  textTransform={'capitalize'}>
                  {totalReachCount}
                </Heading>
              </Stack>

              <Stack
                alignItems={'center'}
                justifyContent='flex-start'
                // bgColor={'white'}
                p={1}
                borderRadius='md'
                spacing={0}>
                <Text
                  fontSize={'md'}
                  fontWeight={500}
                  color='gray.600'>
                  Likes
                </Text>
                <Heading
                  size={'md'}
                  textTransform={'capitalize'}>
                  {totalLikesCount}
                </Heading>
              </Stack>

              <Stack
                alignItems={'center'}
                justifyContent='flex-start'
                // bgColor={'white'}
                p={1}
                borderRadius='md'
                spacing={0}>
                <Text
                  fontSize={'md'}
                  fontWeight={500}
                  color='gray.600'>
                  Comments
                </Text>
                <Heading
                  size={'md'}
                  textTransform={'capitalize'}>
                  {totalCommentsCount}
                </Heading>
              </Stack>

              <Stack
                alignItems={'center'}
                justifyContent='flex-start'
                // bgColor={'white'}
                p={1}
                borderRadius='md'
                spacing={0}>
                <Text
                  fontSize={'md'}
                  fontWeight={500}
                  color='gray.600'>
                  Enggagement
                </Text>
                <Heading
                  size={'md'}
                  textTransform={'capitalize'}>
                  {totalEngagementCount}
                </Heading>
              </Stack>
            </SimpleGrid>
          </Stack>
        </Stack>
        <Stack>
          <AnalyticsDataPost />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PostsTab;
