import React from 'react';
import ProjectsPage from '../Pages/Configurations/Projects/ProjectPage';
import ProjectsViewPage from '../Pages/Configurations/Projects/ProjectViewPage';
import DiscoveryPage from '../Pages/Discovery/DiscoveryPage';
import ContentSavedPage from '../Pages/Discovery/ContentSavedPage';

const DiscoveryRouter = [
  {
    path: '/discovery',
    element: <DiscoveryPage />,
  },
  {
    path: '/discovery/savedContent',
    element: <ContentSavedPage />,
  },
];

export default DiscoveryRouter;
