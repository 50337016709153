
export const fontData = [
  'Adamina', 'Aleo', 'Alice', 'Alike', 'Amiri', 'Arvo', 'Besley', 'Bona Nova', 'Crimson Pro', 'Fenix',
  'Advent Pro', 'Archivo', 'Average Sans', 'Cabin', 'Chivo', 'Lato', 'Montserrat', 'Poppins', 'Noto Sans', 'Oswald', 'Quicksand', 'Roboto',
  'Allison', 'Carattere', 'Great Vibes', 'Lovers Quarrel','Sacramento', 'Waterfall',
]

export const fontWeight = [
  100, 200, 300, 400, 500, 600, 700, 800, 900
]

export const textTransform = [
  'none', 'capitalize', 'uppercase', 'lowercase'
]