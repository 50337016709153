/* eslint-disable react/prop-types */
import { HStack, Image, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DynamicButton from '../Buttons/DynamicButton';
import CardFile from '../Card/CardFile';

import { FcAudioFile, FcFolder } from 'react-icons/fc';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import ProgressCircle from '../Progress/ProgressCircle';
import { addDocumentFirebase } from '../../Api/firebaseApi';
import { collection, limit, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../Config/firebase';
import UploadFileToDropbox from './UploadFileToDropbox';



function UploadDropboxCard({globalState, files, setFiles}) {

  const [dataMedia, setDataMedia] = useState([])
  const [progress, setProgress] = useState(0)

  const itemsPerPage = 6
    

  const toast = useToast()

  const onSelectFile = async (arg) => {
    try {
      const decryptResult = globalState.accessToken


      const parentPath = `/${globalState.currentCompany}/social-media`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        arg,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );


      if (dropboxLink) {
        const collectionName = 'media';

        const dataNewUpdate = {
          path: dropboxLink.path,
          link: dropboxLink.link,
          type: 'socialmedia',
          category: dropboxLink.type,
          projectId: globalState?.currentProject,
          companyId: globalState?.currentCompany,
          size: Math.round(dropboxLink.size || 0) ,
          filesId: 'recently',
          ratioWidth: dropboxLink.ratioWidth || 0,
          ratioHeight: dropboxLink.ratioHeight || 0,
          title: dropboxLink.name || ''
        };

        const docID = await addDocumentFirebase(
          collectionName,
          dataNewUpdate,
          globalState.currentCompany
        );
        toast({
          title: 'Deoapp',
          description: `Success add File ${docID}.`,
          status: 'success',
          isClosable: true,
          duration: 9000,
        });
    

      }



    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }

  }

  useEffect(() => {
    if (globalState?.currentCompany) {
      globalState.setIsLoading(true);
      const q = query(
        collection(db, 'media'),
        where('type', '==', 'socialmedia'),
        where('companyId', '==', globalState?.currentCompany),
        where('projectId', '==', globalState?.currentProject),
        limit(itemsPerPage),
        orderBy('lastUpdated', 'desc')
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const qData = [];
        querySnapshot.forEach((doc) => {
          qData.push({ id: doc.id, ...doc.data() });
        });
        setDataMedia(qData);
        globalState.setIsLoading(false);
      });
      return () => {
        unsubscribe();
        setDataMedia([]);
      };
    }
  }, [globalState?.currentCompany]);


  const handleAddFiles = (file) => {
    const existingIndex = files.findIndex((existingFile) => existingFile.id === file.id);
    if (existingIndex === -1) {
      setFiles((prevFiles) => [...prevFiles, file]);
    } else {
      setFiles((prevFiles) => prevFiles.filter((existingFile) => existingFile.id !== file.id));
    }
  }





  return (
    <Stack>
      <Text fontWeight={'bold'}>File: </Text>



      <CardFile onChange={onSelectFile} />

      {dataMedia?.length > 0 && (
        <SimpleGrid
          h={'250px'}
          overflowY="scroll"
          py={3}
          spacing={4}
          columns={[2]}
        >
          {dataMedia?.map((x, index) => {
            const dataView =
                          x.category === 'image' ? (
                            <Image
                              src={x.link}
                              objectFit="fill"
                              w={'80px'}
                              borderRadius={'md'}
                              shadow="md"
                            />
                          ) : x.category === 'audio' ? (
                            <FcAudioFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : x.category === 'video' ? (
                            <video
                              controls
                              width={'80px'}>
                              <source
                                src={x.link}
                                type={x.name}
                              />
                  Sorry, your browser doesnt support embedded videos.
                            </video>
                          ) : (
                            <FcFolder
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          );

            const isFileInList = files.some((file) => file.id === x.id); // Memeriksa apakah file ada di dalam files

            return (
              <Stack
                key={index}
                borderWidth={isFileInList ? 2 : 0.5}
                position="relative"
                alignItems="center"
                justifyContent={'center'}
                borderRadius="md"
                minH={'120px'}
                borderColor={isFileInList ? 'blue.600' : 'gray.200'}
              >
                <HStack
                  flexDirection={['column', 'row', 'row']}
                  position={'absolute'}
                  top={-2}
                  right={-2}
                  spacing={2}
                >
                  {isFileInList ? (

                    <DynamicButton
                      action={'delete'}
                      size="sm"
                      variant={'solid'}
                      onClick={() => handleAddFiles(x)}
                    />
                  ) : (
                    <DynamicButton
                      action={'create'}
                      size="sm"
                      variant={'solid'}
                      onClick={() => handleAddFiles(x)}
                    />
                  )}
                  
                  <DynamicButton
                    action={'read'}
                    size="sm"
                    variant={'solid'}
                    onClick={() => window.open(x.link, '_blank')}
                  />
                </HStack>
                <Stack overflow={'hidden'}>
                  {dataView && dataView}
                </Stack>
              </Stack>
            );
          })}
        </SimpleGrid>
      )}
      {dataMedia?.length > 2 && (
        <Stack alignItems={'center'} justifyContent="center">
          <BsFillArrowDownCircleFill />
        </Stack>
      )}

      {progress > 0 && progress < 100 ? (
        <Stack p={-10}>
          <ProgressCircle value={progress} />
        </Stack>
      ) : (
        <></>
      )}

    </Stack>
  )
}

export default UploadDropboxCard