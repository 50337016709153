function DeepFind(predicate, value) {
  if (typeof value === 'object' && value !== null) {
    if (predicate(value)) {
      return value;
    }

    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        const foundValue = DeepFind(predicate, value[key]);
        if (foundValue) {
          return foundValue;
        }
      }
    }
  }

  return undefined;
}

export default {
  DeepFind,
};
