/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Progress,
  Text,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
} from '@chakra-ui/react';
import React from 'react';

function DropboxUploaderAsset({
  modalOpen,
  onClose,
  fileInputRef,
  handleFileInputChange,
  handleSaveButtonClick,
  handleCopy,
  filePreview,
  uploadProgress,
  shareLink,
  setDataNew,
  setFilePreview,
  setUploadProgress,
  setShareLink,
  setPath,
  path,
}) {

  const handleAddNew = (e) => {
    const { value, name } = e.target;
    setDataNew((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleModalClose = () => {
    // Mengatur kembali state menjadi nilai awal saat modal ditutup
    setFilePreview(null);
    setUploadProgress(0);
    setShareLink('');
    setPath('');
    setDataNew({});
    onClose();
  };

  return (
    <Modal isOpen={modalOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent zIndex={10}>
        <ModalHeader>
          Upload File
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5} p={4}>
            {filePreview}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={{
                height: '300px',
                width: '300px',
                borderRadius: 'md',
                border: '2px dashed #cbd5e0',
                cursor: 'pointer',
                padding: '8',
                textAlign: 'center',
                display: filePreview !== null ? 'none' : 'flex',
              }}
            />
            <Stack>
              <Input
                name="title"
                onChange={handleAddNew}
                placeholder={'Nama File'}
              />
              <Text>
                {'Path'}: {path}
              </Text>
            </Stack>
            <Progress
              mt={2}
              value={uploadProgress}
              size="sm"
              colorScheme="blue"
            />
            <Box mt={2}>{uploadProgress}%</Box>
            {shareLink && (
              <Stack mt={4} alignItems="center" justifyContent={'center'}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Text fontSize={11} color="gray.500">
                    {shareLink ? shareLink : 'none'}
                  </Text>
                </Box>
                <HStack>
                  <Button
                    onClick={() => handleCopy(shareLink)}
                    size="sm"
                    variant="outline"
                    colorScheme={'blue'}
                  >
                    {'Copy'}
                  </Button>
                  <a href={shareLink} target="_blank" rel="noopener noreferrer">
                    <Button size="sm" variant="outline" colorScheme={'blue'}>
                      {'Open'}
                    </Button>
                  </a>
                </HStack>
              </Stack>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {uploadProgress === 0 && (
              <Button onClick={handleSaveButtonClick}> {'Save'}</Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DropboxUploaderAsset;
