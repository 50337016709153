import React from 'react';
import { observer } from 'mobx-react-lite';
// import { ElementState } from '@creatomate/preview';
import { videoCreator } from '../../../../Hooks/Mobx/CreatomateStore';
import { Flex, Text } from '@chakra-ui/react';

const CompositionNavigation = observer(() => {
  const { preview } = videoCreator;

  if (!preview) {
    return null;
  }

  if (videoCreator.activeCompositionId == null) {
    return null;
  }

  const compositionTrail = [];
  let currentComposition = preview.findElement((element) => element.source.id === videoCreator.activeCompositionId);

  while (currentComposition) {
    compositionTrail.unshift(currentComposition);
    currentComposition = preview.findElement((element) => {
      return !!element.elements?.some((elem) => elem.source.id === currentComposition.source.id);
    });
  }

  const breadcrumbs = [
    <Text
      key='main-item'
      cursor='pointer'
      onClick={() => {
        preview.setActiveComposition(null);
      }}>
      Main Composition
    </Text>,
  ];

  compositionTrail.forEach((composition) => {
    breadcrumbs.push(
      <Text
        key={`${composition.source.id}-separator`}
        mx={2}
        opacity={0.6}>
        /
      </Text>
    );
    breadcrumbs.push(
      <Text
        key={`${composition.source.id}-item`}
        cursor='pointer'
        onClick={() => {
          preview.setActiveComposition(composition.source.id);
        }}>
        {composition.source.name ?? 'Composition'}
      </Text>
    );
  });

  return (
    <Flex
      position='absolute'
      bottom='25px'
      left='50%'
      transform='translateX(-50%)'
      background='blue.400'
      padding='8px'
      borderRadius='md'>
      {breadcrumbs}
    </Flex>
  );
});

export default CompositionNavigation;
