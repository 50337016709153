import React from 'react'
import { FaYoutube, FaTwitter, FaFacebook, FaInstagram, FaTiktok, FaLinkedin, FaGoogle, FaPinterest } from 'react-icons/fa'

const PlatformArr = [
  {
    name: 'youtube',
    icon: <FaYoutube />
  },
  {
    name: 'twitter',
    icon: <FaTwitter />
  },
  {
    name: 'facebook',
    icon: <FaFacebook />
  },
  {
    name: 'instagram',
    icon: <FaInstagram />
  },
  {
    name: 'tiktok',
    icon: <FaTiktok />
  },
  {
    name: 'linkedin',
    icon: <FaLinkedin />
  },
  {
    name: 'google',
    icon: <FaGoogle />
  },
  {
    name: 'pinterest',
    icon: <FaPinterest />
  }
]

export function getPlatformIcon (platformName) {
  const filteredPlatform = PlatformArr.find(
    (platform) => platform.name === platformName
  )

  return filteredPlatform ? filteredPlatform.icon : null
}
