/* eslint-disable react/prop-types */
import React from 'react';
import { SimpleGrid, Stack, Text } from '@chakra-ui/react';

function YoutubeAnalyticsPost({ analyticsData }) {
  //   console.log(analyticsData, 'ini analytics data ytb');
  const { comments = 0, likes = 0, views = 0, averageViewPercentage = 0, channelTitle = '' } = analyticsData || {};

  const formattedAverageViewPercentage = averageViewPercentage.toFixed(2);

  const analyticsInfo = [
    { label: 'Likes', value: likes },
    { label: 'Comments', value: comments },
    { label: 'Video Views', value: views },
    { label: 'View Percentage (%)', value: `${formattedAverageViewPercentage} %` },
    { label: 'Username', value: `@${channelTitle}` },
  ];

  return (
    <SimpleGrid
      columns={[1, 2, 4]}
      gap={3}>
      {analyticsInfo?.map(({ label, value }, index) => (
        <Stack
          key={index}
          align='center'>
          <Text fontSize='xs'>{label}</Text>
          <Text fontWeight='bold'>{value}</Text>
        </Stack>
      ))}
    </SimpleGrid>
  );
}

export default YoutubeAnalyticsPost;
