export const getUserByUid = (dataUsers, uid) => {
  try {
    const res = dataUsers?.find((x) => x.id === uid)
    console.log(res,'ini respon get id')

    return res
  } catch (error) {
    throw new Error(error.message, 'Failed to send Slack login message')
  }
}
export const getUserByEmail = (dataUsers, email) => {
  try {
    const res = dataUsers?.find((x) => x.email === email)

    return res
  } catch (error) {
    throw new Error(error.message, 'Failed to send Slack login message')
  }
}
