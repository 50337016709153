import React, { useState } from 'react';
import { Box, Flex, Input, Button, Select, IconButton, useColorMode } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const BarSearch = () => {
  const { colorMode } = useColorMode();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [sortBy, setSortBy] = useState('Relevance');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    // Implement search functionality here
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
    // Implement filter functionality here
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
    // Implement sorting functionality here
  };

  return (
    <Flex
      alignItems='center'
      // mt={3}
      justifyContent='space-between'
      // position='absolute'
      backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}
      p={1}
      borderRadius='md'>
      <Box flex='1'>
        <Flex alignItems='center'>
          <Input
            placeholder='Search...'
            value={searchQuery}
            onChange={handleSearch}
            mr={2}
          />
          <IconButton
            aria-label='Search'
            icon={<SearchIcon />}
            onClick={() => console.log('Searching...')}
          />
        </Flex>
      </Box>
      <Box
        flex='1'
        mx={4}>
        <Select
          value={selectedFilter}
          onChange={handleFilterChange}
          placeholder='Filter'>
          <option value='All'>All</option>
          <option value='Option1'>Option 1</option>
          <option value='Option2'>Option 2</option>
          {/* Add more filter options as needed */}
        </Select>
      </Box>
      <Box flex='1'>
        <Select
          value={sortBy}
          onChange={handleSortByChange}
          placeholder='Sort By'>
          <option value='Relevance'>Relevance</option>
          <option value='Newest'>Newest</option>
          <option value='Oldest'>Oldest</option>
          {/* Add more sorting options as needed */}
        </Select>
      </Box>
      <Box>
        <Button colorScheme='blue'>Filter</Button>
      </Box>
    </Flex>
  );
};

export default BarSearch;
