import React from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

const ConfigurationPage = () => {
  return (
    <MainMenuComponent 
      menuName='Configuration' 
    />
  );
};

export default ConfigurationPage;
