import { Box, HStack, Select, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import InstagramAnalyticsCard from '../Card/InstagramAnalyticsCard';
import TiktokAnalyticsCard from '../Card/TiktokAnalyticsCard';
import LinkedinAnalyticsCard from './linkedinAnalyticsCard';
import YoutubeAnalyticsCard from '../Card/YoutubeAnalyticsCard';

function AnalyticsDataPost() {
  const globalState = useUserStore();
  const [dataPost, setDataPost] = useState();
  const toast = useToast();

  const mediaTypeRef = useRef('');
  const availableMediaTypesRef = useRef([]);

  const getDataPost = async () => {
    globalState?.setIsLoading(true);
    const data = {
      profileKey: globalState?.socialKey,
      query: `/${globalState?.socialActive?.platform}`,
    };
    try {
      const res = await _axios.post('/ayrshare-getAllPosts', data);
      // console.log(res, 'ini res');
      if (res.data.status === 'success') {
        console.log(res.data, 'ini data res');
        if (res.data.posts.length > 0) {
          let dataFix = [];
          const dataPosting = res.data.posts;
          const platformFilters = {
            instagram: (x) => x.mediaProductType === mediaTypeRef?.current,
            tiktok: (x) => x.mediaType === mediaTypeRef?.current,
            // Tambahkan platform lain dengan aturan filter yang sesuai jika diperlukan
            // misalnya: twitter: (x) => x.someProperty === someValue
          };
          const activePlatform = globalState?.socialActive?.platform.toLowerCase();

          const filterFunction = platformFilters[activePlatform];
          if (filterFunction) {
            dataFix = dataPosting.filter(filterFunction);
          } else {
            // Jika platform tidak memiliki filter khusus, tampilkan semua data
            dataFix = [...dataPosting];
          }

          const updatedFilterData = await Promise.all(
            dataFix.map(async (iterator) => {
              const res = await _axios.post('ayrshare-getAnalyticsPost', {
                profileKey: globalState?.socialKey,
                id: iterator?.id || iterator?.itemId,
                platforms: [globalState?.socialActive?.platform],
                searchPlatformId: true,
              });
              const obj = res.data;
              const { ...analytics } = obj;
              if (analytics.code !== 186) {
                return { ...iterator, analytics };
              } else {
                return iterator;
              }
            })
          );

          setDataPost(updatedFilterData);
        }
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    } finally {
      globalState?.setIsLoading(false);
    }
  };
  // console.log(dataPost, 'ini data post');

  const getDataAvailableFilter = () => {
    if (globalState?.socialActive?.platform === 'instagram') {
      availableMediaTypesRef.current = ['FEED', 'REELS'];
    } else if (globalState?.socialActive?.platform === 'tiktok') {
      availableMediaTypesRef.current = ['video'];
    } else if (globalState?.socialActive?.platform === 'linkedin') {
      availableMediaTypesRef.current = ['post'];
    }
  };

  useEffect(() => {
    if (availableMediaTypesRef?.current.length > 0) {
      mediaTypeRef.current = availableMediaTypesRef?.current[0]; // Mengat
    }

    getDataAvailableFilter();
    getDataPost(); // Panggil getDataPost setelah perubahan platform atau mediaType
  }, [globalState?.socialKey, globalState?.socialActive?.platform]);

  return (
    <Stack>
      <HStack>
        <Text
          fontSize={'lg'}
          fontWeight='bold'
          color={'gray.600'}>
          Posting {globalState?.socialActive?.platform} Insight
        </Text>
        <Spacer />
        <Box>
          <Select
            value={mediaTypeRef?.current}
            onChange={(e) => {
              mediaTypeRef.current = e?.target.value;
              getDataPost(); // Panggil getDataPost setiap kali mediaType berubah
            }}
            placeholder='Select option'>
            {availableMediaTypesRef?.current.map((type) => (
              <option
                key={type}
                value={type}>
                {type}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>

      {dataPost?.length > 0 && (
        <Stack>
          {dataPost.map((x, index) => {
            return (
              <Stack key={index}>
                {globalState?.socialActive?.platform === 'instagram' ? (
                  <InstagramAnalyticsCard data={x} />
                ) : globalState?.socialActive?.platform === 'tiktok' ? (
                  <TiktokAnalyticsCard data={x} />
                ) : globalState?.socialActive?.platform === 'youtube' ? (
                  <YoutubeAnalyticsCard data={x} />
                ) : (
                  <LinkedinAnalyticsCard data={x} />
                )}
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
}

export default AnalyticsDataPost;
