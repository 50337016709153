import { Badge, Box, Button, Center, Flex, HStack, Heading, Icon, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { NumberAcronym } from '../../../Utils/NumberUtils';
import { FaPlay, FaPlayCircle, FaRegHeart, FaTiktok } from 'react-icons/fa';
import moment from 'moment';
import PropTypes from 'prop-types';

export const TiktokFYPCard = ({ dataHasil, handleLoadMore, handleOpenLink, usernameUrl }) => {
  const [showDetails, setShowDetails] = useState({});

  const toggleDetails = (index) => {
    setShowDetails({ ...showDetails, [index]: !showDetails[index] });
  };

  return (
    <Box>
      <SimpleGrid
        columns={5}
        spacing={4}
        p={5}
        my={5}
        borderRadius={'md'}
        // bgGradient='linear(to-r, gray.200, gray.500)'
        boxShadow='xl'>
        {dataHasil.map((x, i) => (
          <Stack
            key={i}
            p='3'
            borderRadius={'md'}
            // bg={'white'}
            shadow={'base'}
            border={'1px'}
            borderColor={'gray.50'}>
            <Box
              pos={'relative'}
              h={300}
              // w={400}
              display={'flex'}>
              <Box
                pos={'relative'}
                h={300}
                w={'50%'}
                display={'flex'}
                justifyContent={'start'}>
                <Box pos='relative'>
                  <Image
                    _hover={{ opacity: 0.3 }}
                    transition='opacity 0.2s ease-in-out'
                    h={300}
                    w={200}
                    src={x?.video}
                    alt={'video'}
                    objectFit='cover'
                  />

                  <Box
                    pos='absolute'
                    top={0}
                    left={0}
                    zIndex={1}
                    p={2}
                    bg='rgba(0, 0, 0, 0.5)'
                    color='white'
                    borderRadius='0 8px'>
                    <Text>Play</Text>
                    <Heading fontSize='lg'>{NumberAcronym(x?.play_count)}</Heading>
                  </Box>
                  <Box
                    pos='absolute'
                    bottom={0}
                    right={0}
                    zIndex={1}
                    p={2}
                    bg='rgba(0, 0, 0, 0.5)'
                    color='white'
                    transition='background-color 0.3s ease'
                    _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                    <Flex alignItems='center'>
                      <Box mr={1}>
                        <FaRegHeart />
                      </Box>
                      <Heading
                        fontSize='xl'
                        _hover={{ transform: 'scale(1.1)' }}>
                        {NumberAcronym(x?.digg_count)}
                      </Heading>
                    </Flex>
                  </Box>

                  <Center
                    pos={'absolute'}
                    w={'full'}
                    top={0}
                    opacity={0}
                    h={300}
                    bg={'black'}
                    _hover={{ opacity: 0.8 }}
                    transition='opacity 0.2s ease-in-out'>
                    <Button
                      colorScheme={'blue'}
                      size={'sm'}
                      onClick={() => handleOpenLink(x)}>
                      <FaPlay />
                    </Button>
                  </Center>
                </Box>
              </Box>
              <Box
                pos={'relative'}
                h={300}
                w={'50%'}
                display={'flex'}
                justifyContent={'end'}>
                <SimpleGrid
                  columns={1}
                  pos={'relative'}
                  w={'full'}
                  justifyContent={'start'}
                  alignItems={'center'}>
                  <Box
                    align={'center'}
                    p={1}
                    border={'1px'}
                    borderColor={'gray.100'}
                    borderRadius={'md'}
                    mx={3}
                    mb={3}>
                    <Text>Comment</Text>
                    <Heading fontSize='lg'>{NumberAcronym(x?.comment_count)}</Heading>
                  </Box>
                  <Box
                    align={'center'}
                    p={1}
                    border={'1px'}
                    borderColor={'gray.100'}
                    borderRadius={'md'}
                    margin={3}>
                    <Text>Download</Text>
                    <Heading fontSize='lg'>{NumberAcronym(x?.download_count)}</Heading>
                  </Box>
                  <Box
                    align={'center'}
                    p={1}
                    border={'1px'}
                    borderColor={'gray.100'}
                    borderRadius={'md'}
                    margin={3}>
                    <Text>Share</Text>
                    <Heading fontSize='lg'>{NumberAcronym(x?.share_count)}</Heading>
                  </Box>
                  <Box
                    align={'center'}
                    p={1}
                    border={'1px'}
                    borderColor={'gray.100'}
                    borderRadius={'md'}
                    margin={3}>
                    <Text>WA Share</Text>
                    <Heading fontSize='lg'>{NumberAcronym(x?.whatsapp_share_count)}</Heading>
                  </Box>
                </SimpleGrid>
              </Box>
            </Box>
            <Stack
              h={150}
              overflowY='auto'
              sx={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              <Text fontWeight='bold'>Hastags</Text>
              {/* <Flex
                overflowX='auto'
                minH='40px'
                overflowY='hidden'
                sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
                {x.hashtags && x.hashtags.length > 0 ? (
                  x.hashtags.map((z, index) => (
                    <Badge
                      key={index}
                      colorScheme='blue'
                      m='1'
                      textColor='black'
                      borderRadius={'md'}
                      p='2'
                      bg='blue.200'
                      boxShadow='md'
                      _hover={{ bg: 'blue.300' }}
                      cursor='pointer'
                      transition='background-color 0.3s ease-in-out'>
                      {z}
                    </Badge>
                  ))
                ) : (
                  <Text color='gray.500'>No Hashtags</Text>
                )}
              </Flex> */}
              <Text fontWeight='bold'>Video Description</Text>
              <Text>{x?.desc}</Text>
              <Text fontWeight='bold'>Video Music</Text>
              <Text>{x?.music}</Text>
            </Stack>

            <HStack
              mt={3}
              justifyContent={'space-between'}>
              <HStack spacing={1}>
                <FaTiktok />
                <a
                  href={usernameUrl(x?.shareUrl)}
                  target='_blank'
                  rel='noreferrer'>
                  <Text
                    fontWeight={500}
                    fontSize={13}>
                    {x?.unique_id}
                  </Text>
                </a>
              </HStack>
              <Text fontSize={12}>Created: {moment.unix(x?.create_time).fromNow()}</Text>
            </HStack>

            {/* <Center my={2}>
              <Button
                colorScheme='gray'
                onClick={() => toggleDetails(i)}>
                {showDetails[i] ? 'Hide Details' : 'Show Details'}
              </Button>
            </Center> */}
          </Stack>
        ))}
      </SimpleGrid>

      <Center my={5}>
        <Button
          colorScheme='blue'
          onClick={handleLoadMore}>
          Load More
        </Button>
      </Center>
    </Box>
  );
};

TiktokFYPCard.propTypes = {
  dataHasil: PropTypes.any.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleOpenLink: PropTypes.func.isRequired,
  usernameUrl: PropTypes.func.isRequired,
};
