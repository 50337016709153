import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TbReload } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from '../../../Api/firebaseApi';
import BackButtons from '../../../Components/Buttons/BackButtons';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import ImageComponent from '../../../Components/Image/ImageComponent';
import useUserStore from '../../../Hooks/Zustand/Store';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';

function ProjectsPage() {
  const globalState = useUserStore();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [modalNew, setModalNew] = useState(false);
  const [filterOwner, setFilterOwner] = useState(false);
  const toast = useToast();
  const itemsPerPage = 8;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    let conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'users',
        operator: 'array-contains',
        value: globalState.uid,
      },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    if (filterOwner === true) {
      conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState.currentCompany,
        },
      ];
    }

    try {
      globalState.setIsLoading(true);

      const res = await getCollectionFirebase(
        'projects',
        conditions,
        sortBy,
        limitValue
      );
      setData(res);

      const collectionRef = collection(db, 'projects');
      let countQuery = query(
        collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('users', 'array-contains', globalState.uid)
      );
      if (filterOwner === true) {
        countQuery = query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany)
        );
      }
      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleOpenFilter = () => {
    if (globalState.roleCompany !== 'owner') {
      toast({
        title: 'Alert!',
        description: 'You dont have access to filter this button / only owner.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setFilterOwner((prevFilterOwner) => !prevFilterOwner);
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    getData();

  }, [globalState?.currentCompany, currentPage, filterOwner]);

  const handleNewProject = () => {
    setModalNew(true);
  };

  const createNewProject = () => {
    const data = {
      name: title,
      companyId: globalState.currentCompany,
      lastUpdated: new Date(),
      owner: [globalState.uid],
      users: [globalState.uid],
    };
    addDocumentFirebase('projects', data, globalState.currentCompany).then(
      (x) => {
        navigate(`/configuration/projects/${x}`);
      }
    );
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>Projects</Heading>
        <Spacer />
        <DynamicButton
          title={'See all'}
          action={'read'}
          onClick={handleOpenFilter}
        />
        <DynamicButton
          action={'create'}
          title="Project"
          onClick={handleNewProject}
          variant={'solid'}
        />
        <DynamicButton
          action={'custom'}
          icon={TbReload}
          variant="solid"
          title="Configuration"
          color="blue"
          onClick={() => window.location.reload()}
        />
      </HStack>

      {data?.length > 0 ? (
        <SimpleGrid columns={{ base: 1, lg: 4 }} p="2" spacing={4} my={5}>
          {data.map((x, i) => (
            <Stack
              key={i}
              p="5"
              shadow="base"
              minH="100px"
              // bg={'white'}
              borderRadius={'md'}
              onClick={() => navigate(`${x.id}`)}
              cursor="pointer"
            >
              <ImageComponent image={x.image} name={x.name} />
              <Spacer />

              <Stack>
                <Text fontWeight={'bold'}>{x.name}</Text>
                <Text fontSize="3xs">ID: {x.id}</Text>
              </Stack>

              <SimpleGrid columns={{ base: 2, lg: 3 }} textAlign="center">
                <Box shadow="base" borderRadius="md" m="1" p="1">
                  <Text fontSize="xs">Manager</Text>
                  <Text fontWeight={500}>
                    {x?.managers?.length ? x.managers.length : 0}
                  </Text>
                </Box>
                <Box shadow="base" borderRadius="md" m="1" p="1">
                  <Text fontSize="xs">Users</Text>
                  <Text fontWeight={500}>
                    {x?.users?.length ? x.users.length : 0}
                  </Text>
                </Box>
                <Box shadow="base" borderRadius="md" m="1" p="1">
                  <Text fontSize="xs">Modules</Text>
                  <Text fontWeight={500}>
                    {x?.modules?.length ? x.modules.length : 0}
                  </Text>
                </Box>
              </SimpleGrid>
              <Flex overflowY="auto">
                {x.modules?.map((z, index) => (
                  <Badge
                    key={index}
                    colorScheme="green"
                    m="1"
                    borderRadius={'md'}
                  >
                    {z}
                  </Badge>
                ))}
              </Flex>
              <Text fontSize="2xs" textAlign={'end'}>
                Created: {moment(x?.createdAt?.seconds * 1000).format('LLL')}
              </Text>
            </Stack>
          ))}
        </SimpleGrid>
      ) : (
        <Center my={5}>
          <Stack align={'center'}>
            <Text fontWeight={500} color={'gray.500'}>
              No Project Data
            </Text>
            <Text fontWeight={500} color={'gray.500'}>
              Please Create a New Project
            </Text>
          </Stack>
        </Center>
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </Box>
      </Stack>

      <Modal isOpen={modalNew} onClose={() => setModalNew(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type="text" onChange={(e) => setTitle(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={() => createNewProject()}
              title="Save"
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ProjectsPage;
