/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Checkbox, Box, Stack, Text, Input, Button, Select } from '@chakra-ui/react';

function YoutubeInput({ data, setData, postTypes }) {
  const [tagsActive, setTagsActive] = useState(false);
  const [newTags, setNewTags] = useState('');

  const handleAddTags = () => {
    if (newTags.trim() !== '') {
      setData((prevData) => {
        const updatedTags = [...(prevData.youTubeOptions?.tags || []), newTags.trim()].slice(0, 3);
        return {
          ...prevData,
          youTubeOptions: {
            ...prevData.youTubeOptions,
            tags: updatedTags,
          },
        };
      });
      setNewTags(''); // Resetting newTags after adding them
    }
  };

  const handleRemoveTags = (index) => {
    setData((prevData) => {
      const updatedTags = [...(prevData.youTubeOptions?.tags || [])];
      updatedTags.splice(index, 1);
      return {
        ...prevData,
        youTubeOptions: {
          ...prevData.youTubeOptions,
          tags: updatedTags,
        },
      };
    });
  };

  return (
    <Stack>
      <Text
        fontSize={'sm'}
        color='gray.500'
        fontWeight={'semibold'}>
        Details Post for Youtube
      </Text>
      <Stack px={2}>
        <Text>Title</Text>
        <Input
          size={'sm'}
          placeholder='Add Your Tittle Video'
          // defaultValue={30000}
          onChange={(e) =>
            setData({
              ...data,
              youTubeOptions: {
                ...data.youTubeOptions,
                title: e.target.value,
              },
            })
          }
        />
        <Text>Visibility</Text>
        <Select
          size={'sm'}
          placeholder='Select Visibility'
          onChange={(e) =>
            setData({
              ...data,
              youTubeOptions: {
                ...data.youTubeOptions,
                visibility: e.target.value,
              },
            })
          }>
          <option value='public'>Public</option>
          <option value='unlisted'>Unlisted</option>
          <option value='private'>Private</option>
        </Select>
        <Text>Thumbnail</Text>
        <Input
          size={'sm'}
          placeholder='https://image'
          // defaultValue={30000}
          onChange={(e) =>
            setData({
              ...data,
              youTubeOptions: {
                ...data.youTubeOptions,
                thumbNail: e.target.value,
              },
            })
          }
        />
        <Text>Tags</Text>
        <Select
          colorScheme='blue'
          value={tagsActive ? 'active' : 'inactive'}
          onChange={(e) => setTagsActive(e.target.value === 'active')}>
          <option value='active'>using Tags</option>
          <option value='inactive'>No Tags</option>
        </Select>

        {tagsActive && (
          <Stack>
            <Input
              placeholder='Enter Tags'
              value={newTags}
              onChange={(e) => setNewTags(e.target.value)}
            />
            <Button onClick={handleAddTags}>Add Tags</Button>
          </Stack>
        )}

        {/* Daftar nama kolaborator */}
        {data.youTubeOptions?.tags && data.youTubeOptions.tags.length > 0 && (
          <Stack>
            {data.youTubeOptions.tags.map((tags, index) => (
              <Stack
                key={index}
                direction='row'
                align='center'>
                <Text>{tags}</Text>
                <Button
                  size='sm'
                  onClick={() => handleRemoveTags(index)}>
                  Remove
                </Button>
              </Stack>
            ))}
          </Stack>
        )}
        <Checkbox
          colorScheme='blue'
          onChange={(e) =>
            setData({
              ...data,
              youTubeOptions: {
                ...data.youTubeOptions,
                MadeForKids: e.target.checked,
              },
            })
          }>
          <Text fontSize={'md'}>Made For Kids</Text>
        </Checkbox>
        <Checkbox
          colorScheme='blue'
          onChange={(e) =>
            setData({
              ...data,
              youTubeOptions: {
                ...data.youTubeOptions,
                shorts: e.target.checked,
              },
            })
          }>
          <Text fontSize={'md'}>Short Video</Text>
          <Text
            fontSize='sm'
            color='red'>
            Video Maximum 60 second & 30 mb
          </Text>
        </Checkbox>
        <Checkbox
          colorScheme='blue'
          onChange={(e) =>
            setData({
              ...data,
              youTubeOptions: {
                ...data.youTubeOptions,
                notifySubscribers: e.target.checked,
              },
            })
          }>
          <Text fontSize={'md'}>notify Subscriber</Text>
        </Checkbox>
      </Stack>
    </Stack>
  );
}

export default YoutubeInput;
