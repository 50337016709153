/* eslint-disable react/prop-types */
import { AspectRatio, Divider, Grid, Heading, HStack, Image, Spacer, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../Buttons/DynamicButton'

function TiktokPublishCard({ data }) {
  const globalState = useUserStore();


  return (
    <Stack
      borderRadius="lg"
      shadow="md"
      // bgColor={'white'}
      borderTopWidth={5}
      borderColor="blue.500"
      p={5}
    >
      <Grid templateColumns={{ base: '1fr', md: '4fr 1fr 0.5fr' }} gap={5}>
        <Stack  p={3} fontSize="sm">
          <Stack>
            <Text fontWeight={500}>Name</Text>
            <Text cursor={'pointer'} onClick={() => window.open(data?.url, '_blank')}>{data?.name}</Text>
          </Stack>
          <Stack>
            <Text fontWeight={500}>Caption</Text>
            <Text> {data?.caption}</Text>
          </Stack>
          <Stack>
            <Text fontWeight={500}>Music</Text>
            <Text cursor={'pointer'} onClick={() => window.open(data?.musicUrl, '_blank')}>{data?.musicTitle}</Text>
          </Stack>

        </Stack>
        <AspectRatio maxW="auto" ratio={1}>
          <Image src={data?.thumbnailUrl} />
        </AspectRatio>
        <Stack alignItems={'center'} justifyContent="center">
          <DynamicButton size={'sm'} variant={'solid'} action={'read'} onClick={() => window.open(data?.shareUrl, '_blank')} />
        </Stack>
      </Grid>
      <Divider />
      <Stack>
        <HStack spacing={5}>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Comments</Text>
            <Heading size="md">{data?.comments}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Like</Text>
            <Heading size="md">{data?.likes}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">View</Text>
            <Heading size="md">{data?.videoViews}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Share</Text>
            <Heading size="md">{data?.shares}</Heading>
          </Stack>
          <Spacer />
          <Stack>
            <Text fontSize={'xs'} color="gray.600">Share via {globalState?.socialActive?.platform} on {moment(data?.created).format('LLLL')}</Text>
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  )
}

export default TiktokPublishCard