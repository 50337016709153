import axios from 'axios'

export const downloadFile = async (fileUrl) => {
  try {
    const response = await axios.get(fileUrl, { responseType: 'blob' })

    const blob = new Blob([response.data], { type: response.headers['content-type'] })

    const urlObject = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = urlObject
    a.download = 'video.mp4'
    a.click()

    window.URL.revokeObjectURL(urlObject)

    return 'download success'
  } catch (error) {
    throw new Error(error)
  }
}
