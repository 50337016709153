/* eslint-disable react/prop-types */
import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';

function InstagramAnalyticsPost({ analyticsData }) {
  // console.log(analyticsData, 'ini analytics data');
  const { commentsCount, engagementCount, impressionsCount, username, mediaProductType, igReelsVideoViewTotalTimeCount, likeCount, reachCount, igReelsAvgWatchTimeCount } = analyticsData;

  const engagementRate = impressionsCount !== 0 && !isNaN(engagementCount) ? (((engagementCount || 0) / impressionsCount) * 100).toFixed(2) : 0;

  let completionRate = 0;
  if (mediaProductType === 'REELS' && igReelsVideoViewTotalTimeCount !== 0 && !isNaN(reachCount)) {
    completionRate = (((igReelsAvgWatchTimeCount || 0) / igReelsVideoViewTotalTimeCount) * 100).toFixed(2);
  }

  const analyticsInfo = [
    { label: 'Likes', value: likeCount },
    { label: 'Comments', value: commentsCount },
    { label: 'Post Type', value: mediaProductType },
    { label: 'username', value: `@${username}` },
  ];

  // Add Reach, Engagement Count, and Completion Rate if mediaProductType is 'reels'
  if (mediaProductType === 'REELS') {
    analyticsInfo.push({ label: 'Reach', value: reachCount }, { label: 'Engagement Count', value: engagementCount || 0 }, { label: 'Completion Rate (%)', value: completionRate || 0 });
  }

  return (
    <SimpleGrid
      columns={[1, 2, 4]}
      gap={3}>
      {analyticsInfo?.map(({ label, value }, index) => (
        <Stack
          key={index}
          align='center'>
          <Text fontSize={'xs'}>{label}</Text>
          <Text fontWeight={'bold'}>{value}</Text>
        </Stack>
      ))}
    </SimpleGrid>
  );
}

export default InstagramAnalyticsPost;
