import axios from 'axios';
import moment from 'moment';

const MAX_CHUNK_SIZE = 150 * 1024 * 1024; // 150MB (maksimum ukuran setiap chunk)

const UploadFileToDropbox = async (file, parentPath, token, setProgress) => {
  const currentMillis = moment(new Date()).valueOf();

  const fileType = file.type.split('/')[0]; // Mengambil bagian depan sebelum tanda "/"

  const fileTypeFix =
    fileType === 'image'
      ? 'image'
      : fileType === 'video'
        ? 'video'
        : fileType === 'audio'
          ? 'audio'
          : 'file';

  const accessToken = token;
  const uploadUrl = 'https://content.dropboxapi.com/2/files/upload_session/start';
  const appendUrl = 'https://content.dropboxapi.com/2/files/upload_session/append_v2';
  const finishUrl = 'https://content.dropboxapi.com/2/files/upload_session/finish';
  let sessionId = null;
  let cursor = 0; // Untuk melacak posisi saat ini dalam file
  

  try {
    const startResponse = await axios.post(uploadUrl, null, {
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${accessToken}`,
        'Dropbox-API-Arg': JSON.stringify({
          close: false
        }),
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(progress)
      },
    });

    sessionId = startResponse.data.session_id;


    // Loop through chunks and upload
    while (cursor < file.size) {
      const chunkSize = Math.min(MAX_CHUNK_SIZE, file.size - cursor);
      const chunk = file.slice(cursor, cursor + chunkSize);
      await axios.post(appendUrl, chunk, {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${accessToken}`,
          'Dropbox-API-Arg': JSON.stringify({
            cursor: {
              offset: cursor,
              session_id: sessionId
            },
            close: cursor + chunkSize >= file.size // Close session if this is the last chunk
          }),
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(progress) // Anda dapat menampilkan progres ini jika diperlukan
        },
      });
      cursor += chunkSize;
      const progress = Math.round((cursor * 100) / file.size);
      setProgress(progress);
    }

    // Finish upload session
    const finishResponse = await axios.post(finishUrl, null, {
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${accessToken}`,
        'Dropbox-API-Arg': JSON.stringify({
          cursor: {
            offset: cursor,
            session_id: sessionId
          },
          commit: {
            path: `${parentPath}/${fileTypeFix}/${currentMillis}-${file.name}`,
            mode: 'add',
            autorename: true,
            mute: false
          }
        }),
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(progress)
      },
    });
    const response = finishResponse.data;

    if (response?.path_lower) {
      const fileSize = getFileSizeInMB(file);
      let ratioWidth = 0;
      let ratioHeight = 0;


      if (fileTypeFix === 'image') {
        const img = document.createElement('img');
        img.src = URL.createObjectURL(file);
        await new Promise((resolve) => {
          img.onload = resolve;
        });
        ratioWidth = img.naturalWidth;
        ratioHeight = img.naturalHeight;
      } else if (fileTypeFix === 'video') {
        const dimensions = await getVideoDimensions(file);
        ratioWidth = dimensions.width;
        ratioHeight = dimensions.height;
      }

      const dropboxLink = await createShareLink(
        response.path_lower,
        fileTypeFix,
        accessToken,
        fileSize,
        `${currentMillis}-${file.name}`,
        ratioWidth,
        ratioHeight
      );
      return dropboxLink;
    }
  } catch (error) {
    return error;
  }
};

const getFileSizeInMB = (file) => {
  const fileSizeInBytes = file.size;
  // Convert bytes to megabytes
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  return fileSizeInMB;
};

const createShareLink = async (filePath, typeFile, token, fileSize, name, ratioWidth, ratioHeight) => {
  const accessToken = token;

  const url = 'https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings';

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const requestData = {
    path: filePath,
  };

  try {
    const response = await axios.post(url, requestData, {
      headers: headers,
    });

    const urlData = response?.data?.url;
    const dataFix = urlData.includes('.mov') || urlData.includes('.MOV');

    const urlRaw = dataFix ? urlData : `${urlData}&raw=1`;
    return { link: urlRaw, type: typeFile, size: Math.round(fileSize || 0), name, path: filePath, ratioWidth, ratioHeight };
  } catch (error) {
    return null;
  }
};

const getVideoDimensions = async (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight
      });
    };
    video.onerror = reject;
    video.src = URL.createObjectURL(file);
  });
};

export default UploadFileToDropbox;