import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import Layout from '../Layouts';
import ConfigurationRouter from './ConfigurationRouter';
import MarketingRouter from './MarketingRouter';
import SocialmediaRouter from './SocialMediaRouter';
import SettingRouter from './SettingRouter';
import DiscoveryRouter from './DiscoveryRouter';

function MainRouter() {
  const location = useLocation();
  const navigate = useNavigate();

  const allRouter = [...AuthenticationMainRouter, ...HomeRouter, ...SettingRouter, ...ConfigurationRouter, ...DiscoveryRouter, ...MarketingRouter, ...SocialmediaRouter];

  useEffect(() => {
    const path = location.pathname;

    if (path === '/billings') {
      return navigate('/');
    }

    return () => {
      // Cleanup, if needed
    };
  }, [location.pathname]);

  return (
    <Routes>
      {allRouter.map((item, index) => {
        return (
          <Route
            key={index}
            path={item.path}
            element={<Layout>{item.element}</Layout>}
          />
        );
      })}
    </Routes>
  );
}

export default MainRouter;
