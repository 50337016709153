/* eslint-disable react/prop-types */
import { AspectRatio, Divider, Grid, Image, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import TiktokAnalyticsPost from '../Analytics/TiktokAnalyticsPost';
import YoutubeAnalyticsPost from '../Analytics/YoutubeAnalyticsPost';

function YoutubeAnalyticsCard({ data }) {
  const globalState = useUserStore();
  // console.log(data, 'ini data analytics');

  return (
    <Stack
      borderRadius='lg'
      shadow='md'
      // bgColor={'white'}
      borderTopWidth={5}
      borderColor='blue.500'
      p={5}>
      <Grid
        templateColumns={{ base: '1fr', md: '4fr 1fr' }}
        gap={5}>
        <Stack
          p={3}
          fontSize='sm'>
          <Stack>
            <Text fontWeight={600}>Title</Text>
            <Text
              cursor={'pointer'}
              onClick={() => window.open(data?.postUrl, '_blank')}>
              {data?.title}
            </Text>
          </Stack>
          <Stack>
            <Text fontWeight={600}>Description Video</Text>
            <Text> {data?.post}</Text>
          </Stack>
          <Stack>
            <Text fontWeight={600}>Video Status</Text>
            <Text cursor={'pointer'}>{data?.privacyStatus}</Text>
          </Stack>
        </Stack>
        <AspectRatio
          maxW='auto'
          ratio={1}>
          <Image
            src={data?.thumbnailUrl}
            onClick={() => window.open(data?.shareUrl, '_blank')}
            cursor='pointer'
          />
        </AspectRatio>
      </Grid>
      <Divider />
      <Stack>
        <Text
          fontSize={'xs'}
          color='gray.600'>
          Share via {globalState?.socialActive?.platform} on {moment(data?.created).format('LLLL')}
        </Text>
      </Stack>

      <Stack spacing={5}>
        {Object.entries(data?.analytics).map(([key, value]) => (
          <Stack key={key}>
            <Text
              fontSize={'sm'}
              fontWeight='bold'
              textTransform={'capitalize'}>
              {key}
            </Text>
            <Divider />
            <YoutubeAnalyticsPost analyticsData={value?.analytics} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default YoutubeAnalyticsCard;
