import { Center,Heading,HStack,Image,Spacer,Stack,Text,useToast } from '@chakra-ui/react'
import moment from 'moment';
import React,{ useEffect,useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import { getPlatformIcon } from '../../Utils/PlatformIcon';

function HistoryTab() {

  const globalState = useUserStore();
  const toast = useToast()
  const lastRecords = 0
  const lastDays = 0
  const [historyList,setHistoryList] = useState([])



  const getHistory = async () => {
    if (globalState.socialKey) {
      globalState.setIsLoading(true)
      try {
        const res = await _axios.post('ayrshare-history',{
          lastRecords: lastRecords,
          lastDays: lastDays,
          profileKey: globalState.socialKey
        });
        if (res.data.status === 'error') {
          const splitErrorMessage = res?.data?.message?.split('Please see the docs on how to retrieve additional history. https://docs.ayrshare.com/rest-api/endpoints/history#list-history-of-sent-and-scheduled-posts')
          toast({
            title: 'Deoapp.com',
            description: splitErrorMessage[0],
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
        setHistoryList(res.data);
      } catch (error) {
        console.log(error,'ini error');
      } finally {
        globalState.setIsLoading(false)

      }

    } else {
      return toast({
        title: 'Deoapp.com',
        description: 'Please set up your social account',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleComment = (x) => {
    console.log(x)
  }

  const handleAnalytics = (x) => {
    console.log(x)
  }

  const handleDeleteModal = (x) => {
    console.log(x)
  }

  const handleErrorMessage = (message) => {
    toast({
      title: 'Deoapp.com',
      description: message,
      status: 'error',
    });
  };


  useEffect(() => {
    getHistory()

    return () => {
    }
  },[globalState.socialKey])


  return (
    <Stack>
      <HStack>
        <Text fontSize={'xl'} fontWeight="bold" color={'gray.600'}>
                    History post
        </Text>
        <Text fontSize={'md'} color="gray.500">
                    ( {historyList.length} most recent )
        </Text>
      </HStack>
      <Stack>
        {historyList.length > 0 ? (
          <>
            {historyList.map((x,index) => {
              return (
                <Stack
                  borderRadius="lg"
                  key={index}
                  shadow="md"
                  borderTopWidth={5}
                  borderColor="blue.500"
                  p={5}
                >
                  <HStack>
                    <Stack spacing={5}>
                      <Stack>
                        <Text fontSize={'xs'}>
                          {moment(x.created).format('LLLL')}
                        </Text>
                      </Stack>
                      <Stack>
                        <Text fontSize={'xs'} color="gray.600">
                          {x.post}
                        </Text>
                      </Stack>
                      <Stack>
                        <HStack gap={2}>
                          {x.mediaUrls.length > 0 &&
                                                        x.mediaUrls.map((y,index) => {
                                                          return (
                                                            <Stack key={index}>
                                                              {y.includes('.MOV') || y.includes('.mp4') || y.includes('.mov')? (
                                                                <video controls width="220" height="140">
                                                                  <source src={y} />
                                                                            Sorry, your browser doesnt support
                                                                            embedded videos.
                                                                </video>
                                                              ) : (
                                                                <Image
                                                                  borderRadius={'lg'}
                                                                  w={'150px'}
                                                                  shadow={'md'}
                                                                  src={y}
                                                                  alt={y}
                                                                />
                                                              )}
                                                            </Stack>
                                                          );
                                                        })}
                        </HStack>
                      </Stack>
                      {/* 
                                            <HStack spacing={5}>
                                                <Stack
                                                    cursor={"pointer"}
                                                    onClick={() => handleComment(x)}
                                                >
                                                    <AiOutlineComment size={20} />
                                                </Stack>
                                                <Stack
                                                    cursor={"pointer"}
                                                    onClick={() => handleAnalytics(x)}
                                                >
                                                    <TbPresentationAnalytics size={20} />
                                                </Stack>
                                                <Stack
                                                    cursor={"pointer"}
                                                    onClick={() => handleDeleteModal(x)}
                                                >
                                                    <BsTrash size={18} />
                                                </Stack>
                                            </HStack> */}
                    </Stack>

                    <Spacer />
                    <Stack
                      alignItems={'flex-end'}
                      spacing={3}
                      justifyContent="flex-end"
                    >
                      <Stack>
                        <Text color={'gray.500'} fontSize="sm">
                          {x.errors && x.postIds
                            ? 'Response Active'
                            : 'Response Inactive'}
                        </Text>
                      </Stack>

                      <HStack>
                        <HStack spacing={2}>
                          {x.postIds &&
                                                        x.postIds.map((z,index) => {

                                                          const resIcon = getPlatformIcon(z.platform);

                                                          return (
                                                            <a
                                                              href={z.postUrl}
                                                              key={index}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                            >
                                                              <Stack
                                                                color={'green'}
                                                                key={z.id}
                                                                cursor="pointer"
                                                                onClick={() => console.log(z.postUrl)}
                                                              >
                                                                {resIcon}
                                                              </Stack>
                                                            </a>
                                                          );
                                                        })}
                        </HStack>

                        <HStack spacing={2}>
                          {x.errors &&
                                                        x.errors.map((z,index) => {

                                                          const resIcon = getPlatformIcon(z.platform);

                                                          return (
                                                            <Stack
                                                              key={index}
                                                              color="red"
                                                              cursor="pointer"
                                                              onClick={() =>
                                                                handleErrorMessage(z.message)
                                                              }
                                                            >
                                                              {resIcon}
                                                            </Stack>
                                                          );
                                                        })}
                        </HStack>

                        {x.errors && x.postIds === undefined && (
                          <HStack spacing={2}>
                            {x.platforms &&
                                                            x.platforms.map((z,index) => {

                                                              const resIcon = getPlatformIcon(z.platform);

                                                              return (
                                                                <Stack
                                                                  key={index}
                                                                  cursor="pointer"
                                                                  color={'gray'}
                                                                  onClick={() => console.log(z.message)}
                                                                >
                                                                  {resIcon}
                                                                </Stack>
                                                              );
                                                            })}
                          </HStack>
                        )}
                      </HStack>
                    </Stack>
                  </HStack>
                </Stack>
              );
            })}
          </>
        ) : (
          <Center h={300}>
            <Heading size={'md'} color="gray.600" alignSelf={'center'}>No History</Heading>
          </Center>
        )}
      </Stack>
    </Stack>
  )
}

export default HistoryTab