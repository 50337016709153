import React from 'react';
import SocialMediaCreateionViewPage from '../Pages/Marketing/social-media/creation/SocialMediaCreateionViewPage';
import SocialMediaCreationPage from '../Pages/Marketing/social-media/creation/SocialMediaCreationPage';
import SocialMediaCreationVideoCreatePage from '../Pages/Marketing/social-media/creation/SocialMediaCreationVideoCreatePage';
import SocialMediaPage from '../Pages/Marketing/social-media/SocialMediaPage';
import SocialMediaCreationEditPage from '../Pages/Marketing/social-media/creation/SocialMediaCreationVideoEditPage';
import SocialMediaResearchTiktokPage from '../Pages/SocialMedia/ResearchFYP/SocialMediaResearchTiktokPage';
import SocialMediaResearchPage from '../Pages/SocialMedia/ResearchFYP/SocialMediaResearchPage';
import SocialMediaStrategistMedia from '../Pages/SocialMedia/StretegistMedia/Strategist-mediaPage';
import MediaEdit from '../Pages/SocialMedia/StretegistMedia/MediaEdit';
import VideoCreator from '../Pages/SocialMedia/CreatomateEdit/VideoCreator';

const MarketingRouter = [
  {
    path: '/social-media',
    element: <SocialMediaPage />,
  },
  {
    path: '/social-media/content',
    element: <SocialMediaCreationPage />,
  },
  {
    path: '/social-media/content/:contentId',
    element: <SocialMediaCreateionViewPage />,
  },
  {
    path: '/social-media/content/:contentId/edit/:fileId/:templateId',
    element: <SocialMediaCreationVideoCreatePage />,
  },
  {
    path: '/social-media/content/:contentId/editing/:fileId/:templateId',
    element: <SocialMediaCreationEditPage />,
  },
  {
    path: 'social-media/fyp',
    element: <SocialMediaResearchPage />,
  },
  {
    path: 'social-media/fyp/tiktok',
    element: <SocialMediaResearchTiktokPage />,
  },
  {
    path: 'social-media/strategist',
    element: <SocialMediaResearchPage />,
  },
  {
    path: 'social-media/strategist/saved',
    element: <SocialMediaStrategistMedia />,
  },
  // {
  //   path: 'social-media/strategist/saved/:id',
  //   element: <MediaView />,
  // },
  {
    path: 'social-media/strategist/saved/:id',
    element: <MediaEdit />,
  },
  {
    path: 'social-media/strategist/saved/testing',
    element: <VideoCreator />,
  },
];

export default MarketingRouter;
