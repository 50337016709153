import { AspectRatio, Box, Grid, HStack, Image, SimpleGrid, Spacer, Stack, Tag, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiOutlineComment } from 'react-icons/ai';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import CommentEngage from '../Comments/CommentEngage';
import CommentEngageFacebook from '../Comments/CommentEngageFacebook';

const FacebookTab = () => {
  const globalState = useUserStore();

  const [dataPost, setDataPost] = useState([]);
  const [postActive, setPostActive] = useState('');

  const getDataPost = async () => {
    globalState.setIsLoading(true);
    const data = {
      profileKey: globalState.socialKey,
      query: `/${globalState?.socialActive?.platform}`,
    };
    try {
      const res = await _axios.post('/ayrshare-getAllPosts', data);
      if (res.data.status === 'success') {
        setDataPost(res.data.posts);
      }
    } catch (error) {
      console.log(error, 'ini error');
    } finally {
      globalState.setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataPost();
  }, [globalState.socialKey, globalState?.socialActive?.platform]);

  useEffect(() => {
    if (dataPost.length > 0) {
      setPostActive(dataPost[0]);

      handleComment(dataPost[0]);
    }
  }, [dataPost]);

  const handleComment = async (x) => {
    globalState.setIsLoading(true);
    const updateData = x;

    const data = {
      profileKey: globalState.socialKey,
      query: `${x.id}?searchPlatformId=true&platform=${globalState?.socialActive?.platform}`,
    };

    try {
      const res = await _axios.post('ayrshare-getAllComments', data);

      updateData.commentsData = res.data;
    } catch (error) {
      console.log(error, 'ini error');
    } finally {
      setPostActive(updateData);
      globalState.setIsLoading(false);
    }
  };
  const height = window.innerHeight;

  // console.log(dataPost, 'ini data post');

  return (
    <Stack>
      <Grid
        templateColumns={{ base: '1fr', md: '2fr 3fr' }}
        gap={5}>
        <Stack
          h={height / 1.2}
          overflowY='auto'
          shadow='md'
          borderRadius={'md'}
          p={3}>
          <HStack>
            <Text
              fontSize={'lg'}
              fontWeight='bold'
              color={'gray.600'}>
              Posting {globalState?.socialActive?.platform}
            </Text>
            <Spacer />
          </HStack>
          {dataPost.length > 0 && (
            <SimpleGrid
              columns={[1, 2, 3]}
              gap={2}>
              {dataPost.map((x, index) => {
                return (
                  <Stack key={index}>
                    {x.mediaType === 'VIDEO' ? (
                      <Stack
                        position={'relative'}
                        borderWidth={3}
                        borderColor={postActive?.id === x?.id ? 'blue.300' : 'transparent'}>
                        <AspectRatio
                          maxW='auto'
                          ratio={1}>
                          <iframe
                            title='halo'
                            src={x.mediaUrl}
                            allowFullScreen
                          />
                        </AspectRatio>
                        <Stack
                          position={'absolute'}
                          bottom={1}
                          left={1}>
                          <Tag
                            cursor='pointer'
                            colorScheme={'blue'}
                            size='sm'
                            onClick={() => handleComment(x)}>
                            <HStack>
                              <AiOutlineComment />
                              <Text>{x?.commentsCount}</Text>
                            </HStack>
                          </Tag>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack
                        position={'relative'}
                        borderWidth={3}
                        borderColor={postActive?.id === x?.id ? 'blue.300' : 'transparent'}>
                        <AspectRatio
                          maxW='auto'
                          ratio={1}>
                          <Image src={x?.mediaUrl} />
                        </AspectRatio>

                        <Stack
                          position={'absolute'}
                          bottom={1}
                          left={1}>
                          <Tag
                            cursor='pointer'
                            colorScheme={'blue'}
                            size='md'
                            onClick={() => handleComment(x)}>
                            <HStack>
                              <AiOutlineComment />
                              <Text>{x?.commentsCount}</Text>
                            </HStack>
                          </Tag>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                );
              })}
            </SimpleGrid>
          )}
        </Stack>
        <Stack
          h={height / 1.2}
          overflowY='auto'
          shadow='md'
          borderRadius={'md'}
          p={3}>
          {postActive !== '' && (
            <CommentEngageFacebook
              postActive={postActive}
              platformData={globalState?.socialActive?.platform}
              profileKeyData={globalState.socialKey}
            />
          )}
        </Stack>
      </Grid>
    </Stack>
  );
};

export default FacebookTab;
