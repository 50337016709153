import { FaTiktok } from 'react-icons/fa';
import { FcComboChart, FcReadingEbook, FcSelfie, FcOldTimeCamera, FcAbout, FcSlrBackSide, FcCollaboration, FcBriefcase, FcManager, FcSearch, FcAddressBook, FcShare, FcAddDatabase } from 'react-icons/fc';

export const data = [
  {
    name: 'Content',
    icon: FcSlrBackSide,
    link: '/social-media/content',
    description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  },
  {
    name: 'Social Media',
    icon: FcReadingEbook,
    link: '/social-media',
    description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Publish',
        icon: FcOldTimeCamera,
        link: '/social-media/publish',
      },

      {
        name: 'Engagement',
        icon: FcAbout,
        link: '/social-media/engagement',
      },
      {
        name: 'Analytics',
        icon: FcComboChart,
        link: '/social-media/analytics',
      },
      {
        name: 'Account',
        icon: FcSelfie,
        link: '/social-media/account',
      },
    ],
  },
  {
    name: 'strategist',
    icon: FcSearch,
    link: '/social-media/strategist',
    description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Media',
        icon: FcOldTimeCamera,
        link: '/social-media/strategist/saved',
      },
      {
        name: 'Animation',
        icon: FcOldTimeCamera,
        link: '/social-media/animations/tiktok',
      },
    ],
  },
  // {
  //   name: 'Discovery',
  //   icon: FcAddressBook,
  //   link: '/discovery',
  //   description: 'All in One Social media Discover.',
  //   submenu: [
  //     {
  //       name: 'Content Saved',
  //       icon: FcAddDatabase,
  //       link: '/discovery/savedContent',
  //     },
  //   ],
  // },
  {
    name: 'Configuration',
    icon: FcCollaboration,
    link: '/configuration',
    description: 'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Projects',
        icon: FcBriefcase,
        link: '/configuration/projects',
      },

      {
        name: 'Users',
        icon: FcManager,
        link: '/configuration/users',
        description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },
];
