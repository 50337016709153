/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { Preview } from '@creatomate/preview';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Progress,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import ReactSelect from 'react-select';
import { HexColorPicker } from 'react-colorful';
import BackButtons from '../../../Components/Buttons/BackButtons';
import { useParams } from 'react-router-dom';
import useUserStore from '../../../Hooks/Zustand/Store';
import { fontData, fontWeight, textTransform } from '../../Marketing/social-media/creation/TextProperties';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';

function SocialMediaCreationVideoCreatePage() {
  const [isLoading, setIsLoading] = useState(true);
  // const [color, setColor] = useState('');
  const [edit, setEditor] = useState();
  const [mediaType, setMediaType] = useState('');
  const modalTextProps = useDisclosure();
  const modalUploadImage = useDisclosure();
  const [progress, setProgress] = useState(0);
  const [editText, setEditText] = useState({});
  const [selectedElement, setSelectedElement] = useState();
  // const [contentData, setContentData] = useState({});
  const [fileData, setFileData] = useState({});
  const param = useParams();
  const [isRendering, setIsRendering] = useState(false);
  const [sourceData, setSourceData] = useState({});
  const [renderStatus, setRenderStatus] = useState('');
  const globalState = useUserStore();
  const toast = useToast();
  const [selectedImageFileName, setSelectedImageFileName] = useState('');
  const options = fontData?.map((x, i) => ({
    label: x,
    value: i,
  }));

  const handleButtonClick = (value) => {
    modalUploadImage.onOpen(); // Trigger click on the hidden file input
    setSelectedElement(value);
  };

  const getDataVideo = async () => {
    try {
      await getSingleDocumentFirebase(`social_media_contents/${param?.contentId}/content`, `${param?.fileId}`);

      // setContentData(res);
    } catch (error) {
      throw new Error(error, 'An Error Occured');
    }
  };

  const getDataFile = async () => {
    try {
      const res = await getSingleDocumentFirebase('files', param?.contentId);

      setFileData(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleRenderVideo = async (preview) => {
    // console.log('ini pref', preview.current?.getSource());
    if (fileData?.limit === 0) {
      toast({
        status: 'error',
        title: 'Your project has reach maximum limit render',
        duration: 5000,
      });
    } else {
      setIsRendering(true);
      const source = preview?.current?.getSource();
      setSourceData(source);

      const url = 'https://api.creatomate.com/v1/renders';
      const data = {
        source: source,
      };
      const headers = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CREATOMATE}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const res = await axios.post(url, data, headers);

        if (res?.data) {
          const urlRender = `https://api.creatomate.com/v1/renders/${res?.data[0]?.id}`;

          const resRender = await axios.get(urlRender, headers);

          // console.log(resRender);

          // console.log(headers, 'headers');

          setRenderStatus(resRender);
        }

        toast({
          status: 'warning',
          title: 'Video on render',
          description: 'Rendering, please wait',
          duration: 5000,
        });
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: 'Failed Render.',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }
  };

  // console.log(previewRef.current);

  const handleOpenModalProps = (type, value) => {
    modalTextProps.onOpen();
    setSelectedElement(value);
  };

  const previewRef = useRef();
  console.log(previewRef,'ini preview ref')

  const setUpPreview = (htmlElement) => {
    // Clean up an older instance of the preview SDK
    if (previewRef.current) {
      previewRef.current.dispose();
      previewRef.current = undefined;
    }

    const preview = new Preview(htmlElement, 'player', 'public-cmksuaokozwo6ch2wim8veqg');

    preview.onReady = async () => {
      const templateId = '9cbb8761-1d19-4c53-aea7-a73a16a866de'; //ini template;

      await preview.loadTemplate(templateId);
      const arrData = [];
      console.log(arrData,'ini arr data')
      function getText(el) {
        el.map((x) => {
          if (x.elements) getText(x.elements);
          if (x.source.text) {
            arrData.push({
              type: 'text',
              id: x.source.id,
              name: x.source.name,
              background_color: x.source.background_color,
              fill_color: x.source.fill_color,
            });
          }
          if (x.source.type === 'image') {
            arrData.push({
              type: 'image',
              id: x.source.id,
              name: x.source.name,
            });
          }
          return arrData;
        });
        return arrData;
      }

      const elData = getText(preview.state.elements);
      setEditor(elData);
      // console.log(elData)

      // setQuery(flatArr)
      await preview.setTime(2);
      const element = preview.findElement((elements) => elements);
      if (element) {
        toast({
          title: 'Deoapp.com',
          description: 'Element entry.',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      setIsLoading(false);
    };
    previewRef.current = preview;
  };

  const handleCheckVideo = async () => {
    const url = `https://api.creatomate.com/v1/renders/${renderStatus?.data?.id}`;
    // console.log(url, 'urlmas');
    // console.log(renderStatus, 'statuusss');
    const headers = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CREATOMATE}`, //ini sementara ke akun gratis median
        'Content-Type': 'application/json',
      },
    };

    try {
      const resRender = await axios.get(url, headers);
      if (resRender?.data?.status === 'succeeded') {
        setRenderStatus(resRender?.data);
        toast({
          status: 'success',
          title: 'Render success',
          duration: 3000,
        });
        setIsRendering(false);
      } else {
        toast({
          status: 'warning',
          title: 'Render still on progress',
          duration: 3000,
        });
      }
    } catch (error) {
      throw new Error(error, 'An Error occured');
    } finally {
      getDataFile();
    }
  };

  const handleView = () => {
    window.open(renderStatus?.url, '_blank');
  };

  const handleSaveVideo = async () => {
    // setIsLoading(true);

    const fileUrl = renderStatus?.url;

    try {
      const fetchUrl = await fetch(fileUrl)
        .then((result) => result?.blob())
        .then((blob) => {
          const file = new File([blob], `${fileData?.title}.mp4`, { type: blob.type });
          return file;
        });

      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/marketing_contents`;
      const dropboxLink = await UploadFileToDropboxV3(fetchUrl, parentPath, decryptResult, setProgress, progress);
      // const previewRefJson = JSON.stringify(sourceData);
      await updateDocumentFirebase('social_media_contents', param?.fileId, {
        source: sourceData,
        videoUrl: dropboxLink?.link,
      });
      toast({
        status: 'success',
        title: 'Berhasil menyimpan video',
        duration: 3000,
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const playVideo = async () => {
    await previewRef.current?.play();
  };

  const pauseVideo = async () => {
    await previewRef.current?.pause();
  };

  const applyTextValue = async (x, e) => {
    setEditText({ ...editText, [x]: e });
    try {
      await previewRef.current?.setModifications({ ...editText, [x]: e });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'fail enter text.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  const setTextColor = async (id, selection, style) => {
    try {
      if (mediaType === 'text' || selectedElement?.type === 'text') {
        setEditText({ ...editText, [`${selectedElement.id}.${selection}`]: style });
        await previewRef.current?.setModifications({ ...editText, [`${selectedElement.id}.${selection}`]: style });
      } else {
        setEditText({ ...editText, [`${id}.${selection}`]: style });
        await previewRef.current?.setModifications({ ...editText, [`${id}.${selection}`]: style });
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'error',
        duration: 3000,
      });
    } finally {
      setMediaType('');
    }
    // await previewRef.current.setModifications(modifications);
  };

  const setImageUpload = async (id, selection, style) => {
    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/marketing_contents`;
      // globalState.setIsLoading(true)
      const dropboxLink = await UploadFileToDropboxV3(style[0], parentPath, decryptResult, setProgress, progress);

      if (dropboxLink) {
        setEditText({ ...editText, [`${id}.${selection}`]: dropboxLink.link });
        await previewRef.current?.setModifications({ ...editText, [`${id}.${selection}`]: dropboxLink.link });

        setSelectedImageFileName((prevState) => ({
          ...prevState,
          [id]: style[0].name,
        }));
      }
    } catch (error) {
      console.log(error, 'ini error cratomate');
      toast({
        status: 'error',
        title: error.message,
        duration: 3000,
      });
    } finally {
      globalState?.setIsLoading(false);
      modalUploadImage?.onClose();
    }
  };
  useEffect(() => {
    getDataVideo();
    getDataFile();

    return () => {
      // setContentData({});
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSingleDocumentFirebase('social-media-fyp', param.id);
        setData(response);
      } catch (error) {
        console.error(error.message, error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let intervalId;
    if (isRendering) {
      const timeoutId = setTimeout(() => {
        document.getElementById('checkVideoButton').click();
        intervalId = setInterval(() => {
          document.getElementById('checkVideoButton').click();
        }, 5000);
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
      };
    }
  }, [isRendering]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Video</Heading>
        <Spacer />
        <Stack spacing={0}>
          <Text
            fontSize={12}
            fontWeight={500}
            align={'center'}>
            Remaining Render Limit
          </Text>
          <HStack>
            <Progress
              h={2}
              bgColor={'gray.400'}
              w={200}
              value={((30 - fileData?.limit) / 30) * 100}
            />
            <Text
              fontSize={12}
              fontWeight={500}>{`${30 - fileData?.limit}/30`}</Text>
          </HStack>
        </Stack>
      </HStack>

      <Flex
        p={5}
        borderRadius={'md'}
        // bg={'gray.100'}
        my={1}
        height='90vh'
        gap={3}
        direction={'row'}
        alignItems={'flex-start'}
        justifyContent='space-between'>
        <Box
          shadow='base'
          width='sm'
          justifyContent='end'>
          <Box
            p={3}
            h={'72vh'}
            overflowY={'auto'}
            sx={{
              '&::-webkit-scrollbar': {
                w: '2',
                h: '3',
              },
              '&::-webkit-scrollbar-track': {
                w: '6',
                h: '5',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10',
                bg: 'gray.200',
              },
            }}>
            {edit ? (
              edit.map((x, i) => (
                <HStack
                  key={i}
                  my={2}>
                  <FormControl>
                    <FormLabel>
                      <FormLabel>
                        {x.type === 'text' ? '' : x.type === 'image' ? 'Upload Picture' : x.type}
                        {x.name === 'NAMA'
                          ? 'Your Business'
                          : x.name === 'RP'
                            ? 'Price Tag'
                            : x.name === 'MENU'
                              ? 'Your Menu'
                              : x.name === 'Isi'
                                ? 'Description'
                                : x.name === 'Text1'
                                  ? // eslint-disable-next-line indent
                            'Input Text'
                                  : x.name === 'Text2'
                                    ? 'Input Text'
                                    : x.name}
                      </FormLabel>
                    </FormLabel>
                    <HStack>
                      {x.type !== 'image' ? (
                        <Input
                          type={'text'}
                          placeholder={x.type !== 'text' ? x.source : x.type}
                          maxW='md'
                          onChange={async (e) => {
                            if (x?.type === 'text') {
                              await applyTextValue(x.id, e.target.value);
                            } else {
                              setTextColor(x?.id, 'source', e.target.value);
                            }
                          }}
                        />
                      ) : (
                        <HStack>
                          <Text width={150}>{selectedImageFileName[x.id]}</Text>
                          <Button
                            onClick={() => handleButtonClick(x)}
                            bg='blue.100'
                            size={'md'}
                            width='1oopx'>
                            {x.name === 'FOTOGAMBAR' ? 'Upload Product Picture' : 'Upload Image'}
                          </Button>
                        </HStack>
                      )}
                      {x.type === 'text' && (
                        <Button
                          onClick={() => handleOpenModalProps('image', x)}
                          bg='green.100'
                          size={'md'}
                          width='1oopx'>
                          Edit Text
                        </Button>
                      )}
                    </HStack>
                  </FormControl>
                </HStack>
              ))
            ) : (
              <Flex
                alignItems={'center'}
                justifyContent={'center'}>
                <Center>
                  <Spinner color='blue' />
                </Center>
              </Flex>
            )}
          </Box>

          <HStack
            align={'center'}
            my={5}>
            {renderStatus === '' || renderStatus?.status === 'planned' || renderStatus?.status === 'rendering' ? (
              <Box align={'center'}>
                <Button
                  isDisabled={isRendering ? true : false}
                  colorScheme={'green'}
                  size={'md'}
                  onClick={() => handleRenderVideo(previewRef)}>
                  {isRendering ? 'Render process...' : 'Render Video'}
                </Button>
              </Box>
            ) : (
              <>
                {renderStatus?.status === 'succeeded' && (
                  <Button
                    isLoading={isLoading}
                    colorScheme={'green'}
                    size={'sm'}
                    onClick={() => handleSaveVideo()}>
                    Save Video
                  </Button>
                )}
              </>
            )}

            {isRendering ? (
              <>
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />

                <Button
                  id='checkVideoButton'
                  colorScheme={'gray'}
                  size={'sm'}
                  onClick={() => handleCheckVideo()}>
                  Processing...
                </Button>
              </>
            ) : null}
            {renderStatus?.status === 'succeeded' ? (
              <Button
                colorScheme={'green'}
                size={'sm'}
                onClick={() => handleView()}>
                View Video
              </Button>
            ) : (
              <></>
            )}
          </HStack>
        </Box>

        <Stack
          position={'sticky'}
          overflowY='auto'
          zIndex={100}
          top={10}
          w={{ base: '400px', md: '800px' }}
          h={'auto'}>
          {isLoading && 'Loading...'}

          <Stack
            ref={(htmlElement) => {
              if (htmlElement && htmlElement !== previewRef.current?.element) {
                setUpPreview(htmlElement);
              }
            }}
            width='full'
            height='90vh'>
            z
            <div />
            <Button onClick={() => playVideo()}>Play</Button>
            <Button onClick={() => pauseVideo()}>Stop</Button>
          </Stack>
        </Stack>
      </Flex>

      <Modal
        isOpen={modalTextProps?.isOpen}
        onClose={modalTextProps?.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Text Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Stack>
                <FormLabel>Fill Color</FormLabel>
                <Box align={'center'}>
                  <HexColorPicker
                    // color={color}
                    onChange={(e) => setTextColor('', 'fill_color', e)}
                  />
                </Box>
              </Stack>

              <HStack
                my={3}
                spacing={2}>
                <Stack
                  spacing={0}
                  w={200}>
                  <FormLabel>Font Family</FormLabel>
                  <ReactSelect
                    name='fonts'
                    options={options}
                    className='basic-multi-select'
                    classNamePrefix='Select User'
                    onChange={(e) => setTextColor('', 'font_family', e.label)}
                  />
                </Stack>

                <Stack
                  spacing={0}
                  w={200}>
                  <FormLabel>Font Size</FormLabel>
                  <NumberInput
                    precision={1}
                    step={0.1}
                    min={0.1}
                    max={20}
                    onChange={(e) => setTextColor('', 'font_size', `${e} vmin`)}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Stack>
              </HStack>

              <HStack my={3}>
                <Stack
                  spacing={0}
                  w={200}>
                  <FormLabel>Font Weight</FormLabel>
                  <Select onChange={(e) => setTextColor('', 'font_weight', e.target.value)}>
                    {fontWeight?.map((x, i) => (
                      <option
                        key={i}
                        value={x}>
                        {x}
                      </option>
                    ))}
                  </Select>
                </Stack>

                <Stack
                  spacing={0}
                  w={200}>
                  <FormLabel>Font Style</FormLabel>
                  <Select onChange={(e) => setTextColor('', 'font_style', e.target.value)}>
                    <option value={'italic'}>Italic</option>
                  </Select>
                </Stack>

                <Stack
                  spacing={0}
                  w={200}>
                  <FormLabel>Letter Spacing</FormLabel>
                  <NumberInput
                    precision={1}
                    step={1}
                    min={0}
                    max={100}
                    onChange={(e) => setTextColor('', 'letter_spacing', `${e}%`)}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Stack>
              </HStack>

              <HStack>
                <Stack
                  spacing={0}
                  w={200}>
                  <FormLabel>Opacity</FormLabel>
                  <NumberInput
                    step={1}
                    min={0}
                    max={100}
                    onChange={(e) => setTextColor('', 'opacity', `${e}%`)}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Stack>
                <Stack spacing={0}>
                  <FormLabel>Text Transform</FormLabel>
                  <Select onChange={(e) => setTextColor('', 'text_transform', e.target.value)}>
                    {textTransform?.map((x, i) => (
                      <option
                        value={x}
                        key={i}>
                        {x}
                      </option>
                    ))}
                  </Select>
                </Stack>
              </HStack>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              size='sm'
              onClick={modalTextProps.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalUploadImage?.isOpen}
        onClose={modalUploadImage?.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                type='file'
                onChange={(e) => setImageUpload(selectedElement?.id, 'source', e.target.files)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              size={'sm'}
              onClick={modalUploadImage?.onClose}
              colorScheme='red'>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default SocialMediaCreationVideoCreatePage;
