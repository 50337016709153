import {
  AspectRatio,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AvatarGroup,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  useDisclosure,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButtons from '../../../../Components/Buttons/BackButtons';
import { addDocumentFirebase, arrayUnionFirebase, deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase } from '../../../../Api/firebaseApi';
import useUserStore from '../../../../Hooks/Zustand/Store';
import { getUserByUid } from '../../../../Utils/getUser';
import { FcPlus } from 'react-icons/fc';
import AlertDialogV2 from '../../../../Components/AlertDialog/AlertDialogV2';
import Slider from 'react-slick';
import { downloadFile } from '../../../../Utils/downloadFile';
import { clientTypessense } from '../../../../Api/Typesense';
import { AddIcon } from '@chakra-ui/icons';

function SocialMediaCreateionViewPage() {
  const param = useParams();
  const globalState = useUserStore();
  const [title, setTitle] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedVideo, setSelectedVideo] = useState();
  const [openVideo, setOpenVideo] = useState();
  const navigate = useNavigate();
  const modalAddUser = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataUser, setDataUser] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  // const [contents, setContents] = useState([]);
  const [data, setData] = useState([]);
  const modalDelete = useDisclosure();
  const modalEditing = useDisclosure();
  const [bannerImage, setBannerImage] = useState([]);
  const [templateData, setTemplateData] = useState([]);

  //state baru
  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [isAddingTeam, setIsAddingTeam] = useState(false);

  // const [projectActive, setProjectActive] = useState('');

  // const [modalProjectUser, setModalProjectUser] = useState(false);
  const [visibleTemplates, setVisibleTemplates] = useState(8);

  const handleLoadMore = () => {
    setVisibleTemplates((prevCount) => prevCount + 8);
  };

  const settings = {
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  //add user new

  const handleAddTeamProject = async () => {

    try {
      setIsAddingTeam(true);

      const promises = selectedUserProjectIds.map(async (selectedUser) => {
        const userData = getUserByUid(globalState?.users, selectedUser?.id);

        try {
          await Promise.all([
            arrayUnionFirebase('files', param?.contentId, 'user', [selectedUser?.id]),
            arrayUnionFirebase('files', param?.contentId, 'userDisplay', [
              {
                name: userData?.name,
                image: userData?.image,
                email: userData?.email,
                id: userData?.id,
              },
            ]),
          ]);
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            description: 'Error adding user to the project',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });

      await Promise.all(promises);

      const mapIdsUser = selectedUserProjectIds.map((selectedUser) => selectedUser?.id);
      const collectionNameProject = 'files';
      const docNameProject = `${param.contentId}`;
      const field = 'user';
      const values = mapIdsUser;
      try {
        await arrayUnionFirebase(collectionNameProject, docNameProject, field, values);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: 'Error updating project with user data',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        setIsAddingTeam(false);
      }

      toast({
        status: 'success',
        title: 'Deoapp Business',
        description: 'Success adding team to the project',
        duration: 1000,
      });

      // setModalProjectUser(false);
      setSelectedUserProjectIds([]);
      // setProjectActive('');
      setSearchResult([]);
      getDataUser();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'An error occurred during team addition' ,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsAddingTeam(false);
    }
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find((x) => x.id === globalState.currentCompany);
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense.collections('users').documents().search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          description: error.message ,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };

  const getContentCollections = async () => {
    const conditions = [{ field: 'fileId', operator: '==', value: param?.contentId }];

    try {
      const res = await getCollectionFirebase('social_media_contents', conditions);

      setData(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message ,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getTemplate = async () => {
    const conditions = [{ field: 'type', operator: '==', value: 'video' }];
    try {
      const res = await getCollectionFirebase('templates', conditions);
      setTemplateData(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message ,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      throw new Error(error);
    }
  };

  // edit video

  const HandleOpenVideo = async () => {
    setIsLoading(true);
    try {
      const res = await getSingleDocumentFirebase('social_media_contents', openVideo?.id);

      if (res) {
        navigate(`editing/${openVideo.id}/${res.templateId}`);
      } else {
        toast({
          title: 'Video not found!',
          duration: 3000,
          status: 'error',
        });
      }
    } catch (error) {
      toast({
        title: `An error occurred: ${error}`,
        duration: 3000,
        status: 'error',
      });
      throw error;
    } finally {
      setIsLoading(false);
      modalEditing.onClose();
    }
  };

  const getDataUser = async () => {
    const res = await getSingleDocumentFirebase('files', param?.contentId);

    // console.log(res);
    // setContents(res);

    if (res) {
      const userPromises = res?.user?.map(async (x) => {
        const y = await getUserByUid(globalState?.users, x);
        return y;
      });

      const userData = await Promise.all(userPromises);

      setDataUser(userData);
    }
  };

  const handleCreateVideo = async () => {
    setIsLoading(true);
    try {
      const res = await addDocumentFirebase(
        'social_media_contents',
        {
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          templateId: selectedTemplate?.templateId,
          type: 'video',
          title: title,
          fileId: param?.contentId,
        },
        globalState?.currentCompany
      );

      navigate(`edit/${res}/${selectedTemplate?.templateId}`);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message ,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
    // create new video ID in firebase (videoId)
    // with companyId, projectsId, contentId:param.contentId,templateId:templateId}
    // once finished navigate to the editing page
  };

  const handleOpenAlert = (value) => {
    setSelectedVideo(value);
    modalDelete.onOpen();
  };
  const handleVideoAlert = (value) => {
    setOpenVideo(value);
    modalEditing.onOpen();
  };

  const handleDeleteVideo = async () => {
    setIsLoading(true);
    try {  
      await deleteDocumentFirebase('social_media_contents', selectedVideo?.id);

      toast({
        title: 'Video deleted!',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: `An error occured: ${error}`,
        duration: 3000,
        status: 'error',
      });
      throw error;
    } finally {
      getContentCollections();
      setIsLoading(false);
      modalDelete.onClose();
    }
  };

  const handleDownload = async (value) => {
    globalState?.setIsLoading(true);
    try {
      // const downloadResult = 
      await downloadFile(value?.videoUrl);
      toast({
        status: 'success',
        title: 'File downloaded',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message ,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      throw new Error(error);
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const getBannerData = async () => {
    const urlPath = window.location.pathname;
    const urlResult = urlPath.substring(1);

    const conditions = [
      { field: 'companyId', operator: '==', value: '0BZDboI1UP5SoXFV1Z4n' },
      { field: 'isActive', operator: '==', value: 'active' },
      { field: 'path', operator: '==', value: `${urlResult}/` },
    ];

    try {
      const res = await getCollectionFirebase('banner', conditions);

      setBannerImage(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message ,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    getDataUser();
    getContentCollections();
    getBannerData();
    getTemplate();

    return () => {
      setData([]);
    };
  }, []);

  return (
    <Box>
      {/* <Image
        src="https://t3.ftcdn.net/jpg/03/26/26/46/360_F_326264640_Rx6nxYBAE7RVrAFhsrXuk0c53XwzF5RX.jpg"
        alt="banner"
      /> */}

      <Slider {...settings}>
        {bannerImage?.map((x) => (
          <a
            key={x?.id}
            href={x?.image[0]?.link}
            target='_blank' rel="noreferrer">
            <Image
              src={x?.image[0]?.url}
              alt='banner image'
            />
          </a>
        ))}
      </Slider>

      <HStack my={5}>
        <BackButtons />
        <Heading>Video {param.format}</Heading>
        <Spacer />

        {dataUser?.length > 0 ? (
          <AvatarGroup
            size='sm'
            max={2}>
            {dataUser?.map((user) => (
              <Avatar
                key={user?.id}
                name={user?.name}
                src={user?.image || 'no-image'}
              />
            ))}
          </AvatarGroup>
        ) : (
          <Text>No User</Text>
        )}

        <Button
          size={'sm'}
          colorScheme='green'
          onClick={modalAddUser?.onOpen}>
          <FcPlus />
        </Button>
      </HStack>

      <SimpleGrid
        columns='4'
        spacing={3}
        borderRadius={'md'}
        p={3}>
        {data.map((x, i) => (
          <Box
            key={i}
            shadow='base'
            p='2'
            m='2'
            borderRadius='md'>
            <AspectRatio
              maxW='560px'
              ratio={1}>
              <iframe
                title='naruto'
                src={x?.videoUrl}
                allowFullScreen
              />
            </AspectRatio>

            {/* <Link to={`edit/${x?.id}/${x?.templateId}`}> */}
            <Stack
              p='2'
              align={'center'}
              spacing={3}>
              <Heading fontSize='md'>{x.title}</Heading>
              <HStack>
                <Button
                  colorScheme='green'
                  size={'xs'}
                  // onClick={() => window.open(x?.videoUrl, '_blank')}>
                  onClick={() => handleVideoAlert(x)}>
                  View
                </Button>
                <Button
                  id={x?.id}
                  colorScheme='blue'
                  size={'xs'}
                  onClick={() => handleDownload(x)}>
                  Download
                </Button>
                <Button
                  colorScheme='red'
                  size={'xs'}
                  onClick={() => handleOpenAlert(x)}>
                  Delete
                </Button>
              </HStack>
            </Stack>
            {/* </Link> */}
          </Box>
        ))}
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          shadow='base'
          p='2'
          m='2'
          borderRadius='md'>
          <Heading
            color={'gray.500'}
            cursor='pointer'
            onClick={onOpen}
            fontSize='xxx-large'
            textAlign='center'
            justifySelf='center'>
            +
          </Heading>
        </Flex>
      </SimpleGrid>

      <AlertDialogV2
        isLoading={isLoading}
        isOpen={modalDelete.isOpen}
        onClose={modalDelete.onClose}
        onAction={handleDeleteVideo}
        title={'Delete this video'}
      />
      <AlertDialogV2
        isLoading={isLoading}
        isOpen={modalEditing.isOpen}
        onClose={modalEditing.onClose}
        onAction={HandleOpenVideo}
        title={'Edit This Video?'}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='xl'>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Video 1:1 Templates</ModalHeader> */}
          <ModalHeader>Create Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Video Title</FormLabel>
              <Input
                onChange={(e) => setTitle(e.target.value)}
                placeholder={'Enter title'}
              />
            </FormControl>
            {/* <Lorem count={2} /> */}
            <Stack>
              <Text
                fontWeight={500}
                mt={5}>
                Templates
              </Text>
              <Box
                p={2}
                h={400}
                overflowY={'auto'}>
                {/* <SimpleGrid
                  columns='4'
                  spacing={4}>
                  {templateData.map((x,i) => (
                    <Box
                      align={'center'}
                      key={i}
                      border={selectedTemplate?.templateId === x.templateId ? '1px' : 0}
                      borderColor={'blue.400'}
                    // minHeight="sm"
                    >
                      <iframe
                        src={x?.link}
                        width={100}
                      />

                      <CreatomateVideoComponent key={i} templateId={x?.templateId} />
                      <Center my={3}>
                        <Button
                          size={'sm'}
                          colorScheme='green'
                          onClick={() => setSelectedTemplate(x)}>
                          {selectedTemplate?.templateId === x ? 'Selected' : 'Select'}
                        </Button>
                      </Center>
                    </Box>
                  ))}
                </SimpleGrid> */}
                <SimpleGrid
                  columns='4'
                  spacing={4}>
                  {templateData && Array.isArray(templateData) ? (
                    templateData
                      .filter((item) => !('publish' in item) || item.publish === true)
                      .slice(0, visibleTemplates)
                      .map((x, i) => (
                        <Box
                          align={'center'}
                          key={i}
                          border={selectedTemplate?.templateId === x.templateId ? '1px' : 0}
                          borderColor={'blue.400'}>
                          <iframe
                            src={x?.link}
                            width={100}
                          />

                          <Center my={3}>
                            <Button
                              size={'sm'}
                              colorScheme='green'
                              onClick={() => setSelectedTemplate(x)}>
                              {selectedTemplate?.templateId === x ? 'Selected' : 'Select'}
                            </Button>
                          </Center>
                        </Box>
                      ))
                  ) : (
                    <Text>No templates available</Text>
                  )}
                </SimpleGrid>

                {visibleTemplates < templateData.length && (
                  <Center mt={4}>
                    <Button
                      size={'md'}
                      colorScheme='blue'
                      onClick={handleLoadMore}>
                      Load More
                    </Button>
                  </Center>
                )}
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              colorScheme={'green'}
              size={'sm'}
              onClick={handleCreateVideo}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalAddUser?.isOpen}
        onClose={modalAddUser?.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose User</ModalHeader>
          <ModalCloseButton />
          {/* <ModalBody>
            <Text>User in the company</Text>
            <Box
              h={300}
              overflowY={'auto'}>
              {globalState?.users?.map((x) => {
                return (
                  <HStack my={5}>
                    <Avatar
                      size={'md'}
                      name={x?.name}
                      src={x?.image}
                    />
                    <Stack spacing={0}>
                      <Text
                        fontWeight={500}
                        textTransform={'capitalize'}>
                        {x?.name}
                      </Text>
                      <Text
                        fontWeight={500}
                        fontSize={12}>
                        {x?.id}
                      </Text>
                    </Stack>
                    <Spacer />
                    <Button
                      isLoading={isAdding}
                      isDisabled={contents?.user?.includes(x.id) ? true : false}
                      colorScheme={contents?.user?.includes(x.id) ? 'blue' : 'green'}
                      size={'sm'}
                      onClick={() => handleAddUser(x)}>
                      Add
                    </Button>
                  </HStack>
                );
              })}
            </Box>
          </ModalBody> */}
          <ModalBody>
            <Stack
              spacing={1}
              py={3}>
              <HStack m='1'>
                <Input
                  type='text'
                  placeholder='Search users'
                  onChange={(e) => handleSearchUsers(e.target.value)}
                />
              </HStack>

              <Stack>
                <Stack
                  h={300}
                  overflowY={'auto'}
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '3',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}>
                  {searchResult.length > 0 ? (
                    searchResult.map((x, index) => {
                      return (
                        <HStack
                          key={index}
                          p='2'
                          borderBottom='1px'>
                          <Avatar
                            name={x.document.name}
                            src={x.document.image ? x.document.image : ''}
                          />
                          <Box>
                            <Text>{x.document.name}</Text>
                            <Text>{x.document.email}</Text>
                          </Box>
                          <Spacer />
                          <Button
                            colorScheme='green'
                            onClick={() => handleUserProjectClick(x.document)}>
                            +
                          </Button>
                        </HStack>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
          {/* <ModalFooter>
            <Button
              colorScheme={'red'}
              size={'sm'}
              onClick={modalAddUser.onClose}>
              Close
            </Button>
          </ModalFooter> */}
          <ModalFooter>
            <Flex gap={5}>
              <AvatarGroup
                size='sm'
                gap='1'
                max={4}>
                {selectedUserProjectIds.length > 0 &&
                  selectedUserProjectIds.map((x, i) => (
                    <Avatar
                      key={i}
                      name={x?.name}
                    />
                  ))}
              </AvatarGroup>
              <Spacer />
              <Button
                isLoading={isAddingTeam}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme='green'
                onClick={() => handleAddTeamProject()}>
                Add Team
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default SocialMediaCreateionViewPage;
