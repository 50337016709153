/* eslint-disable react/prop-types */
import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';

function LinkedinAnalyticsPost({ analyticsData }) {
  const { commentCount, engagement, impressionCount, likeCount,clickCount,shareCount } = analyticsData;

  const engagementRate = impressionCount !== 0 && !isNaN(engagement) ? (((engagement || 0) / impressionCount) * 100).toFixed(2) : 0;

  const analyticsInfo = [
    { label: 'Likes', value: likeCount },
    { label: 'Comments', value: commentCount },
    { label: 'Impressions', value: impressionCount },
    // { label: 'Reach', value: reachCount },
    { label: 'Engagement Rate (%)', value: engagementRate },
    { label: 'Interested Viewer', value: clickCount },
    // { label: 'Completion Rate (%)', value: completionRate },
    { label: 'Share', value: shareCount },
    // { label: 'username', value: `@${username}` },
  ];

  return (
    <SimpleGrid
      columns={[1, 2, 4]}
      gap={3}>
      {analyticsInfo.map(({ label, value }, index) => (
        <Stack
          key={index}
          align='center'>
          <Text fontSize={'xs'}>{label}</Text>
          <Text fontWeight={'bold'}>{value}</Text>
        </Stack>
      ))}
    </SimpleGrid>
  );
}

export default LinkedinAnalyticsPost;
