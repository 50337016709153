import { Heading, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import AnalyticsDataStories from '../Analytics/AnalyticsDataStories';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';

function StoriesTab() {
  const globalState = useUserStore();
  // const [data, setData] = useState('');
  // const [dataPost, setDataPost] = useState();
  // const mediaTypeRef = useRef('');
  // const [totalStories, setTotalStories] = useState(0);
  // const [totalReachCount, setTotalReachCount] = useState(0);
  // const [totalCommentsCount, setTotalCommentsCount] = useState(0);
  // const [totalVideoViewsCount, setTotalVideoViewsCount] = useState(0);
  // const toast = useToast('');

  // const getDataPost = async () => {
  //   globalState?.setIsLoading(true);
  //   const data = {
  //     profileKey: globalState?.socialKey,
  //     query: `/${globalState?.socialActive?.platform}`,
  //   };
  //   try {
  //     const res = await _axios.post('/ayrshare-getAllPosts', data);
  //     if (res.data.status === 'success') {
  //       // console.log(res.data, 'ini res data');
  //       if (res.data.posts.length > 0) {
  //         let dataFix = [];
  //         const dataPosting = res.data.posts;
  //         const platformFilters = {
  //           instagram: (x) => x?.mediaProductType === mediaTypeRef?.current && x?.mediaProductType === 'STORY',
  //           tiktok: (x) => x?.mediaType === mediaTypeRef?.current,
  //           linkedin: (x) => x?.mediaType === mediaTypeRef?.current,
  //           twitter: (x) => x?.mediaProductType === mediaTypeRef?.current && x?.mediaProductType === 'STORY',
  //           youtube: (x) => x?.mediaProductType === mediaTypeRef?.current && x?.mediaProductType === 'STORY',
  //         };

  //         const activePlatform = globalState?.socialActive?.platform.toLowerCase();

  //         const filterFunction = platformFilters[activePlatform];
  //         if (filterFunction) {
  //           dataFix = dataPosting.filter(filterFunction);
  //         } else {
  //           dataFix = [...dataPosting];
  //         }

  //         const updatedFilterData = await Promise.all(
  //           dataFix?.map(async (iterator) => {
  //             const res = await _axios.post('ayrshare-getAnalyticsPost', {
  //               profileKey: globalState?.socialKey,
  //               id: iterator?.id || iterator?.itemId,
  //               platforms: [globalState?.socialActive?.platform],
  //               searchPlatformId: true,
  //             });
  //             const obj = res.data;
  //             const { ...analytics } = obj;
  //             if (analytics?.code !== 186) {
  //               return { ...iterator, analytics };
  //             } else {
  //               return iterator;
  //             }
  //           })
  //         );
  //         // setDataPost(updatedFilterData);
  //         // console.log(updatedFilterData, 'ini filter data');

  //         const totalStories = updatedFilterData.length;
  //         setTotalStories(totalStories);

  //         const totalReach = updatedFilterData.reduce((total, post) => {
  //           if (post?.analytics?.instagram) {
  //             return total + (post?.analytics?.instagram?.analytics?.reachCount !== undefined ? post?.analytics?.instagram?.analytics?.reachCount : 0);
  //           } else if (post?.analytics?.tiktok) {
  //             return total + (post?.analytics?.tiktok?.analytics?.reach !== undefined ? post?.analytics?.tiktok?.analytics?.reach : 0);
  //           } else {
  //             return total;
  //           }
  //         }, 0);
  //         setTotalReachCount(totalReach);

  //         const totalComments = updatedFilterData.reduce((total, post) => {
  //           if (post?.analytics?.instagram) {
  //             return total + (post?.analytics?.instagram?.analytics?.commentsCount !== undefined ? post?.analytics?.instagram?.analytics?.commentsCount : 0);
  //           } else if (post?.analytics?.tiktok) {
  //             return total + (post?.analytics?.tiktok?.analytics?.comments !== undefined ? post?.analytics?.tiktok?.analytics?.comments : 0);
  //           } else {
  //             return total;
  //           }
  //         }, 0);
  //         setTotalCommentsCount(totalComments);

  //         const impressions = updatedFilterData.reduce((total, post) => {
  //           if (post?.analytics?.instagram) {
  //             return total + (post?.analytics?.instagram?.analytics?.impressionsCount !== undefined ? post?.analytics?.instagram?.analytics?.impressionsCount : 0);
  //           } else if (post?.analytics?.tiktok) {
  //             return total + (post?.analytics?.tiktok?.analytics?.impressions !== undefined ? post?.analytics?.tiktok?.analytics?.impressions : 0);
  //           } else {
  //             return total;
  //           }
  //         }, 0);
  //         setTotalVideoViewsCount(impressions);
  //       }
  //     }
  //   } catch (error) {
  //     toast({
  //       title: 'Deoapp.com',
  //       description: error.message,
  //       status: 'error',
  //       position: 'top-right',
  //       isClosable: true,
  //     });
  //   } finally {
  //     globalState?.setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getDataPost();
  // }, [globalState?.socialKey, globalState?.socialActive?.platform]);

  return (
    <Stack spacing={5}>
      {/* <Stack>
        <Text
          fontSize={'lg'}
          fontWeight='bold'
          color={'gray.600'}>
          Stories Summary
        </Text>
        <Stack
          borderRadius='lg'
          shadow='md'
          // bgColor={'white'}
          borderColor='blue.500'
          p={5}>
          <SimpleGrid
            columns={[1, 2, 3]}
            gap={5}>
            <Stack
              alignItems={'center'}
              justifyContent='flex-start'
              // bgColor={'white'}
              p={1}
              borderRadius='md'
              spacing={0}>
              <Text
                fontSize={'md'}
                fontWeight={500}
                color='gray.600'>
                Stories
              </Text>
              <Heading
                size={'md'}
                textTransform={'capitalize'}>
                {totalStories}
              </Heading>
            </Stack>
            <Stack
              alignItems={'center'}
              justifyContent='flex-start'
              // bgColor={'white'}
              p={1}
              borderRadius='md'
              spacing={0}>
              <Text
                fontSize={'md'}
                fontWeight={500}
                color='gray.600'>
                Impressions
              </Text>
              <Heading
                size={'md'}
                textTransform={'capitalize'}>
                {totalVideoViewsCount}
              </Heading>
            </Stack>

            <Stack
              alignItems={'center'}
              justifyContent='flex-start'
              // bgColor={'white'}
              p={1}
              borderRadius='md'
              spacing={0}>
              <Text
                fontSize={'md'}
                fontWeight={500}
                color='gray.600'>
                Reach
              </Text>
              <Heading
                size={'md'}
                textTransform={'capitalize'}>
                {totalReachCount}
              </Heading>
            </Stack>

            <Stack
              alignItems={'center'}
              justifyContent='flex-start'
              // bgColor={'white'}
              p={1}
              borderRadius='md'
              spacing={0}>
              <Text
                fontSize={'md'}
                fontWeight={500}
                color='gray.600'>
                Replies
              </Text>
              <Heading
                size={'md'}
                textTransform={'capitalize'}>
                {totalCommentsCount}
              </Heading>
            </Stack>

            <Stack
              alignItems={'center'}
              justifyContent='flex-start'
              // bgColor={'white'}
              p={1}
              borderRadius='md'
              spacing={0}>
              <Text
                fontSize={'md'}
                fontWeight={500}
                color='gray.600'>
                Exist
              </Text>
              <Heading
                size={'md'}
                textTransform={'capitalize'}>
                {totalStories}
              </Heading>
            </Stack>
          </SimpleGrid>
        </Stack>
      </Stack> */}
      <Stack>
        <Stack>{globalState?.socialActive?.platform === 'instagram' ? <AnalyticsDataStories /> : globalState?.socialActive?.platform === 'tiktok' ? <AnalyticsDataStories /> : null}</Stack>
      </Stack>
    </Stack>
  );
}

export default StoriesTab;
